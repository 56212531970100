import React from 'react'
import { twMerge } from 'tailwind-merge'
import { BaseProps } from './base'

export const Header = ({ children, className, ...rest }: BaseProps) => {
    return React.cloneElement(<header />, {
        children: children,
        className: twMerge('text-xl font-semibold', className),
        ...rest,
    })
}
