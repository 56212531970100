export const Field = ({ children, className = undefined, ...rest }) => {
    return (
        <div
            className={[
                'bg-black/30',
                'flex flex-row items-center justify-center overflow-auto rounded-lg',
                'text-center p-2',
                className,
            ]
                .filter(Boolean)
                .join(' ')}
            {...rest}
        >
            {children}
        </div>
    )
}
