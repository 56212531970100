import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { SignupBadRequest, SignupCredentials } from '../types'

export const useSignup = () => {
    const queryClient = useQueryClient()
    const csrfToken = useCrsfToken()

    return useMutation<unknown, SignupBadRequest, unknown>({
        mutationFn: async ({ username, email, password1, password2 }: SignupCredentials) => {
            const form = new FormData()
            form.append('username', username)
            form.append('email', email)
            form.append('password1', password1)
            form.append('password2', password2)
            form.append('lang', navigator.language)
            form.append('time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone)

            const resp = await fetch('/api/account/signup', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrfToken,
                },
                body: form,
            })
            if (resp.status >= 500) {
                try {
                    return await resp.json()
                } catch (e) {
                    throw { __all__: [{ message: 'Internal server error', code: 'server_error' }] }
                }
            }
            if (resp.status === 400) {
                throw await resp.json()
            }
        },
        onSuccess: (data, variables: SignupCredentials) => {
            /* @ts-ignore */
            window.gtag('event', 'sign_up', {
                event_category: 'account',
                event_label: variables.username,
                user_id: variables.username,
            })
            return queryClient.invalidateQueries(['viewer'])
        },
    })
}
