import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Icons } from '~/features/stake/assets/icons'
import { useLootBoxes } from '../hooks/useLootboxes'
import { WeaponCard } from './WeaponCard'

type LootboxContentProps = {
    lootbox: LootboxType
    content: WeaponType[]
    onClose: () => void
}

export const LootboxContent = ({ lootbox, content, onClose }: LootboxContentProps) => {
    const [show, setShow] = useState([false, false, false])
    const [revealed, setRevealed] = useState([false, false, false])

    const allRevealed = revealed.every((w) => w)

    const open = (index) => {
        setRevealed((prev) => prev.map((r, i) => (i === index ? true : r)))
    }

    const openAll = () => {
        for (let i = 0; i < show.length; i++) {
            setTimeout(
                () =>
                    setRevealed((prev) => {
                        const newShow = [...prev]
                        newShow[i] = true
                        return newShow
                    }),
                500 * i
            )
        }
    }

    useEffect(() => {
        if (lootbox.state === 'PROCESSING') {
            for (let i = 0; i < show.length; i++) {
                setTimeout(
                    () =>
                        setShow((prev) => {
                            const newShow = [...prev]
                            newShow[i] = true
                            return newShow
                        }),
                    500 * i
                )
            }
        }
    }, [lootbox.state])

    return (
        <div className='absolute inset-0 overflow-y-auto bg-black/90 bg-cover h-full transition-opacity duration-500 overflow-hidden flex flex-col items-center gap-8 py-8'>
            <header className='text-3xl font-black'>{lootbox.name}</header>

            {allRevealed ? (
                <>
                    <button onClick={onClose} className='border border-red-500 py-2 px-8 hover:border-white'>
                        CLOSE
                    </button>
                    <button onClick={onClose} className='fixed top-10 right-10'>
                        <FontAwesomeIcon icon={faClose} className='font-bold text-xl hover:scale-110' />
                    </button>
                </>
            ) : (
                <button onClick={openAll} className='border border-green-500 py-2 px-8 hover:border-white'>
                    REVEAL ALL
                </button>
            )}

            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 items-center'>
                {content.map((w, i) => (
                    <div
                        key={i}
                        className={twMerge(
                            'transition-opacity duration-500 opacity-0 pointer-events-none',
                            show[i] && 'opacity-100 pointer-events-auto'
                        )}
                    >
                        <WeaponCard weapon={w} revealed={revealed[i]} onOpen={() => open(i)} />
                    </div>
                ))}
            </div>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 place-content-center gap-2 px-8 py-8 max-w-7xl'>
                <div>
                    <LootboxCount />
                </div>

                {allRevealed && (
                    <Link
                        to='/renting'
                        className='w-max bg-br1-gradient p-2 px-8 text-xl font-bold self-center justify-self-end hover:outline'
                    >
                        RENT MY WEAPONS
                    </Link>
                )}
            </div>
        </div>
    )
}

const LootboxCount = () => {
    const { lootboxes, isFetched } = useLootBoxes()

    if (!isFetched || lootboxes.length - 1 <= 0) return null

    return (
        <div className='flex flex-row items-center gap-2 font-bold mx-auto md:mx-0 text-sm md:text-xl'>
            <Icons.Lootbox className='h-16 w-16' />
            <span>{lootboxes.length - 1}</span>
            <span>{`LOOT BOX${lootboxes.length - 1 !== 1 ? 'ES' : ''}`} AVAILABLE</span>
        </div>
    )
}
