import { Route } from 'react-router-dom'
import { useSettings } from '~/features/settings'
import { WithdrawRequests } from './WithdrawRequests'

export const BankRoutes = () => {
    const { data: settings } = useSettings()
    const routes = []

    if (settings.WITHDRAWAL_ENABLED) {
        routes.push(<Route path='withdrawals' element={<WithdrawRequests />} />)
    }
    return routes
}
