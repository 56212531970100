import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'

export const ConnectionsOneCount = () => {
    const [connections, setConnections] = useState<Record<string, [number, number]>>({})

    const fields = Object.fromEntries(
        Object.entries({
            only_discord: {
                wallet_connected__in: 'false',
                discord_connected__in: 'true',
                twitter_connected__in: 'false',
            },
            only_twitter: {
                wallet_connected__in: 'false',
                discord_connected__in: 'false',
                twitter_connected__in: 'true',
            },
            'only_wallet(s)_without_operatives': {
                wallet_connected__in: 'true',
                discord_connected__in: 'false',
                twitter_connected__in: 'false',
                is_holder__in: 'false',
            },
            'only_wallet(s)_with_operatives': {
                wallet_connected__in: 'true',
                discord_connected__in: 'false',
                twitter_connected__in: 'false',
                is_holder__in: 'true',
            },
        }).map(([name, params]) => [
            name,
            <NavLink
                to={`/team-tools/accounts/users?${new URLSearchParams(params as Record<string, string>)}`}
                className='hover:outline'
            />,
        ])
    )

    const getData = () => {
        jsonrpc('statistic.connections.one_count').then((res) => {
            setConnections(res.result || {})
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Box className='w-full mx-auto'>
            <Header>1 Connection</Header>
            <small className='text-white/50'>
                The number in brackets limits the selection to beta applicants and beta testers.
            </small>
            <section className='grid grid-cols-2 w-full'>
                {Object.entries(fields).map(([name, as]) => {
                    const [all, beta] = connections[name] || []
                    return (
                        <Box key={name} className='items-stretch text-center'>
                            <Stat.Gray as={as} loading={connections[name] === undefined}>
                                <StatLabel className='first-letter:uppercase text-amber-500'>
                                    {name.replaceAll('_', ' ')}
                                </StatLabel>
                                <StatNumber>
                                    {all} ({beta})
                                </StatNumber>
                            </Stat.Gray>
                        </Box>
                    )
                })}
            </section>
        </Box>
    )
}
