import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Auth } from '~/entities'
import { PasswordReset } from '~/features/auth'
import { useAuth } from '../components/Auth'
import { Page } from '../components/Page'

export const PasswordResetPage = () => {
    const [success, setSuccess] = useState<boolean>(false)
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { t } = useTranslation()

    useEffect(() => {
        if (auth?.is_auth) navigate('/account')
    }, [auth])

    return (
        <Page title='Signup' header='Signup'>
            <div className='my-10'>
                {success ? (
                    <Auth.ContainerSuccess>{t('auth:password_reset_success_message')}.</Auth.ContainerSuccess>
                ) : (
                    <PasswordReset.Form onReset={() => setSuccess(true)} />
                )}
            </div>
        </Page>
    )
}
