import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { ActivationBadRequest } from '../types'

export const useActivate = () => {
    const queryClient = useQueryClient()
    const csrfToken = useCrsfToken()

    return useMutation<unknown, ActivationBadRequest, unknown>({
        mutationFn: async ({ code }) => {
            const form = new FormData()
            form.append('code', code)

            const resp = await fetch('/api/account/activate', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrfToken,
                },
                body: form,
            })
            if (resp.status >= 500) {
                try {
                    return await resp.json()
                } catch (e) {
                    throw { __all__: [{ message: 'Internal server error', code: 'server_error' }] }
                }
            }
            if (resp.status === 400) {
                throw await resp.json()
            }
        },
        onSuccess: () => {
            return Promise.all([queryClient.invalidateQueries(['viewer']), queryClient.invalidateQueries(['balance'])])
        },
    })
}
