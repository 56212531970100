import { useQuery } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'
import { JsonRpcError } from '~/types'
import { WithdrawRequestSummary } from '../types'

export const useWithdrawUserRequestsSummary = () => {
    const { data: user } = useUser()
    return useQuery<WithdrawRequestSummary, JsonRpcError>({
        queryKey: ['withdraw_request.user_summary'],
        queryFn: async () => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'bank.withdraw_request.user_summary',
                    params: {},
                }),
            })
            const data = await res.json()
            if (data.error) {
                throw data
            } else {
                return data.result
            }
        },
        retry: false,
        keepPreviousData: true,
        enabled: user.is_auth,
        staleTime: 1000 * 60,
    })
}
