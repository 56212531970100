import { useMutation, useQueryClient } from '@tanstack/react-query'

type Response = {
    uuid: string
    tx: string
    cluster_url: string
}

export const useMakeTransaction = () => {
    const queryClient = useQueryClient()

    return useMutation<Response, unknown, unknown>({
        mutationFn: async ({ wallet, amount, deposit_type }) => {
            const resp = await fetch('/api/bank/deposit/solana_usdc/tx', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    wallet,
                    amount,
                    deposit_type,
                }),
            })
            return await resp.json()
        },
        retry: false,
        onSuccess: () => {
            queryClient.invalidateQueries(['deposit.solana_usdc.list'])
        },
    })
}
