import { Icons } from '~/features/stake/assets/icons'
import { useLootBoxes } from '../hooks/useLootboxes'
import { Lootbox } from './Lootbox'
import { LootboxNotFound } from './LootboxNotFound'

export const LootboxList = () => {
    const { lootboxes, isFetched } = useLootBoxes()

    if (!isFetched) return null

    if (lootboxes.length === 0) return <LootboxNotFound />

    return (
        <div className='flex flex-col'>
            <LootboxCount />
            <div className='flex flex-row flex-wrap justify-center md:justify-start gap-4 p-12 pt-4'>
                {lootboxes.map((lootbox) => (
                    <div key={lootbox.mint} className=''>
                        <Lootbox key={lootbox.id} lootbox={lootbox} />
                    </div>
                ))}
            </div>
        </div>
    )
}

const LootboxCount = () => {
    const { lootboxes, isFetched } = useLootBoxes()

    if (!isFetched || lootboxes.length === 0) return null

    return (
        <div className='flex flex-row items-center gap-2 font-bold px-12 mx-auto md:mx-0 pt-4 text-xl'>
            <Icons.Lootbox className='h-16 w-16' />
            <span>{lootboxes.length}</span>
            <span>{`LOOT BOX${lootboxes.length !== 1 ? 'ES' : ''}`}</span>
        </div>
    )
}
