import { useMutation, useQueryClient } from '@tanstack/react-query'
import { JsonRpcError } from '~/types'
import { WithdrawRequestListResponse } from '../types'

export const useWithdrawDelete = () => {
    const queryClient = useQueryClient()

    return useMutation<boolean, JsonRpcError, unknown>({
        mutationFn: async ({ uuid }) => {
            const resp = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'bank.withdraw_request.delete',
                    params: {
                        uuid,
                    },
                }),
            })
            const data = await resp.json()

            if (data.error) {
                throw data
            } else {
                return data.result
            }
        },
        onSuccess: (data, { uuid }) => {
            queryClient.invalidateQueries(['balance'])
            queryClient.invalidateQueries(['withdraw_request.user_summary'])
            queryClient.setQueriesData(
                { queryKey: ['withdraw_user_requests'] },
                (prev: WithdrawRequestListResponse) => {
                    return {
                        ...prev,
                        data: prev.data?.filter((item) => item.uuid !== uuid),
                    }
                }
            )
        },
        retry: false,
    })
}
