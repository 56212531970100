import Spinner from './Spinner'

export default (props) => {
    const { className, children, animate, indicator, ...rest } = props

    return (
        <button
            className={[
                'button-gradient rounded transition-transform hover:scale-[1.02]',
                animate && 'button-gradient-animate',
                className,
            ]
                .filter(Boolean)
                .join(' ')}
            {...rest}
        >
            <div>{children}</div>
            {indicator && <Spinner className='absolute right-[0.5em] top-[50%] -mt-[0.5em] max-h-[1em]' />}
        </button>
    )
}
