import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { useInput } from '~/hooks/useInput'
import { MinLengthValidator, RequiredValidator, SameValueValidator } from '~/utils/validators'
import { usePasswordSet } from '../api'

export const PasswordSetForm = ({ uid, token }) => {
    const { mutate, error, isLoading, isSuccess, reset } = usePasswordSet()

    const new_password1 = useInput('', { validators: [RequiredValidator(), MinLengthValidator(8)] })
    const new_password2 = useInput('', {
        validators: [
            RequiredValidator(),
            MinLengthValidator(8),
            SameValueValidator(new_password1.value, 'Passwords do not match'),
        ],
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        reset()
        const valid = [new_password1, new_password2].map((f) => f.validate()).every((v) => v)
        if (!valid) return
        mutate(
            { uid: uid, token: token, new_password1: new_password1.value, new_password2: new_password2.value },
            {
                onError: (e) => {
                    new_password1.setErrors(e?.new_password1?.map((v) => v?.message))
                    new_password2.setErrors(e?.new_password2?.map((v) => v?.message))
                },
            }
        )
    }

    const allError = useMemo(
        () => error?.token?.map((v) => v?.message)?.[0] || error?.__all__?.map((v) => v?.message)?.[0],
        [error]
    )

    return (
        <form noValidate className='flex flex-col gap-1 w-full' onSubmit={handleSubmit}>
            <input
                value={new_password1.value}
                onChange={new_password1.onChange}
                type='password'
                name='new-password1'
                placeholder='PASSWORD'
                className='login-form'
                autoComplete='new-password'
            />
            {new_password1.error && <label className='login-form error'>{new_password1.error}</label>}

            <input
                value={new_password2.value}
                onChange={new_password2.onChange}
                type='password'
                name='new-password2'
                placeholder='CONFIRM PASSWORD'
                className='login-form'
                autoComplete='new-password'
            />
            {new_password2.error && <label className='login-form error'>{new_password2.error}</label>}

            {allError && <label className='login-form error'>{allError}</label>}

            <button
                disabled={isLoading}
                className='flex flex-row gap-2 items-center justify-center p-4 mt-8 w-1/2 mx-auto hover:outline'
                style={{ background: 'linear-gradient(142deg,#03CEFF 0%,#C91FFF 100%)' }}
            >
                <span>CHANGE</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </form>
    )
}
