import { useEffect, useState } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'

const ActiveCount = ({ lifetime }: { lifetime?: string }) => {
    const [accounts, setAccounts] = useState<Record<string, [number]>>({})

    const periods = ['24_hours', '7_days', '30_days']

    const getData = () => {
        jsonrpc('statistic.accounts.active_count', { periods, lifetime }).then((res) => {
            setAccounts(res.result)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='grid grid-cols-3 gap-2 w-full'>
            {periods.map((period) => (
                <Stat.Gray key={period}>
                    <StatLabel className='text-amber-500 capitalize'>{period.replace('_', ' ')}</StatLabel>
                    <StatNumber>{accounts[period]}</StatNumber>
                </Stat.Gray>
            ))}
        </div>
    )
}

export const AccountsActiveCount = () => {
    return (
        <Box className='gap-2 w-full text-center'>
            <Header>Active Site Users</Header>
            <small className='text-white/50'>How many registered users have visited the site?</small>
            <ActiveCount />
            <small className='text-white/50'>
                Below the selection is limited to active users whose account is older than{' '}
                <span className='text-white'>7 days</span>.
            </small>
            <ActiveCount lifetime='7_days' />
            <small className='text-white/50'>
                Below the selection is limited to active users whose account is older than{' '}
                <span className='text-white'>30 days</span>.
            </small>
            <ActiveCount lifetime='30_days' />
        </Box>
    )
}
