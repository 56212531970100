import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useKillRace } from '../api'
import { Countdown } from './Countdown'

const stopwatch_icon = new URL('../assets/stopwatch_icon.svg', import.meta.url).href

const ASSET_URL = '/media/assets/features/career'
const defaultBanner = {
    daily: 'killrace_24_bg.png',
    'two-days': 'killrace_48_bg.png',
    monthly: 'killrace_monthly_bg.png',
}

export const KillRaceBanner = ({ type, banner, title, enabled }) => {
    const { data: race_info, isFetched } = useKillRace({ type })
    if (!isFetched) return <div className='min-h-[12rem] h-full bg-white/5 animate-pulse'></div>
    const url = type
    const bannerImage = race_info?.banner || defaultBanner[type]
    return (
        <NavLink
            to={url}
            className={twMerge(
                'min-h-[12rem] h-full relative flex flex-col items-center justify-between gap-y-10 select-none',
                'p-4 font-black max-w-[704px] w-full mx-auto bg-no-repeat bg-center bg-cover',
                enabled && race_info?.period_active && 'hover:outline',
                (!enabled || !race_info?.period_active) && 'pointer-events-none'
            )}
            style={{
                backgroundImage: `url(${ASSET_URL}/${bannerImage})`,
            }}
        >
            {(!enabled || !race_info?.period_active) && (
                <div className='absolute top-0 left-0 h-full w-full bg-black/80 flex flex-col items-center justify-center text-white/5 text-3xl'>
                    NOT STARTED
                </div>
            )}
            <div className='flex flex-col items-end self-end'>
                <h2 className='text-2xl'>{title} KILL RACE</h2>
                {enabled && race_info?.period_active && race_info?.remaining_seconds && (
                    <div className='flex flex-row items-center gap-x-2'>
                        <img src={stopwatch_icon} />
                        <span>
                            <Countdown seconds={race_info?.remaining_seconds} />
                        </span>
                    </div>
                )}
                {race_info?.next_begin && (
                    <div className='flex flex-row items-center gap-x-2'>
                        <img src={stopwatch_icon} />
                        <span>
                            <Countdown seconds={race_info?.next_begin_in_seconds} />
                        </span>
                    </div>
                )}
            </div>

            {((enabled && race_info?.prize && race_info?.period_active) ||
                (race_info?.next_begin && race_info?.prize > 0)) && (
                <div className='flex flex-row items-center justify-center gap-8 animate-fade-in self-end'>
                    <h3 className='text-lg md:text-xl whitespace-nowrap'>PRIZE POOL</h3>
                    <h1 className='text-3xl md:text-5xl text-[#00FF90] font-black whitespace-nowrap'>
                        $ {race_info?.prize.toFixed(2)}
                    </h1>
                </div>
            )}
        </NavLink>
    )
}
