import { useWithdrawUserRequests } from '../api/useWithdrawUserRequests'
import { WithdrawRequest } from './WithdrawRequest'

export const WithdrawUserRequestList = () => {
    const { data: requests } = useWithdrawUserRequests({ page: 1 })

    return (
        <div className='flex flex-col gap-4 text-xs'>
            {requests?.data.map((withdrawRequest) => (
                <WithdrawRequest key={withdrawRequest.uuid} withdrawRequest={withdrawRequest}>
                    <div className='flex flex-col font-bold tracking-wide'>
                        <small className='text-white/30'>{new Date(withdrawRequest.created_at).toLocaleString()}</small>
                        <div className='flex flex-col border border-white/10'>
                            <WithdrawRequest.DetailsButton>
                                <div className='flex flex-row justify-between bg-white/5 hover:bg-white/10 p-1'>
                                    <WithdrawRequest.Status />
                                    <WithdrawRequest.Amount />
                                </div>
                            </WithdrawRequest.DetailsButton>
                            <WithdrawRequest.DetailsContainer>
                                <small>
                                    <WithdrawRequest.Details />
                                    <WithdrawRequest.DeleteButton />
                                </small>
                            </WithdrawRequest.DetailsContainer>
                        </div>
                    </div>
                </WithdrawRequest>
            ))}
        </div>
    )
}
