import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useResendActivationEmail } from '../api'

export const ResendActivationEmailForm = () => {
    const { t } = useTranslation()
    const { mutate, isLoading, isSuccess, error: resendError } = useResendActivationEmail()

    const error = useMemo(() => {
        return (
            resendError?.email_cooldown?.map((v) => v?.message)?.[0] ||
            resendError?.__all__?.map((v) => v?.message)?.[0]
        )
    }, [resendError])

    const handleSubmit = (e) => {
        e.preventDefault()
        mutate({})
    }

    return (
        <form className='flex flex-col items-center gap-2 w-full' onSubmit={handleSubmit}>
            <button
                disabled={isLoading}
                className='flex flex-row gap-2 items-center justify-center p-4 mt-8 w-full md:w-2/5 mx-auto hover:outline'
                style={{ background: 'linear-gradient(149deg, #000 0%, #3D3D3D 100%)' }}
            >
                <span>{t('auth.resend_code')}</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
            {error && <label className='login-form error'>{error}</label>}
            {isSuccess && <label className='login-form success'>{t('auth.activation_email_sent')}</label>}
        </form>
    )
}
