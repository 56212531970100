import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheckCircle,
    faCheckToSlot,
    faEye,
    faMoneyBills,
    faSearch,
    faSpinner,
    faStopwatch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UseQueryResult } from '@tanstack/react-query'
import { createContext, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { JsonRpcError } from '~/types'
import { useWithdrawRequestsSummary, useWithdrawUserRequestsSummary } from '../api'
import { useWithdrawRequests } from '../api/useWithdrawRequests'
import { useWithdrawUserRequests } from '../api/useWithdrawUserRequests'
import {
    WithdrawRequestListResponse,
    WithdrawRequestSummary,
    WithdrawRequest as WithdrawRequestType,
    WithdrawRequestsParams,
} from '../types'
import { WithdrawRequest } from './WithdrawRequest'

type WithdrawRequestListContextType = {
    page: number
    pages: number
    isFetching: boolean
    data: WithdrawRequestType[]
    setPage: React.Dispatch<React.SetStateAction<number>>
    nextPage: () => void
    prevPage: () => void
    filters: WithdrawRequestsParams['filters']
    setFilters: React.Dispatch<React.SetStateAction<WithdrawRequestsParams['filters']>>
}

export const WithdrawRequestListContext = createContext<WithdrawRequestListContextType>({
    page: 1,
    pages: undefined,
    isFetching: false,
    setPage: undefined,
    data: undefined,
    nextPage: undefined,
    prevPage: undefined,
    filters: {},
    setFilters: undefined,
})

type WithdrawRequestListProviderProps = {
    apiHook: ({ page, filters }) => UseQueryResult<WithdrawRequestListResponse, JsonRpcError>
    children: React.ReactNode
}

const Navigation = () => {
    const { page, pages, isFetching, nextPage, prevPage } = useContext(WithdrawRequestListContext)
    return (
        pages > 0 && (
            <div className='flex flex-row justify-between items-center gap-2 select-none'>
                <FontAwesomeIcon icon={faSpinner} spin className={twMerge(isFetching ? 'block' : 'hidden')} />
                <button
                    onClick={prevPage}
                    disabled={page == 1}
                    className='border rounded p-1 border-white/10 enabled:hover:border-white/50 disabled:opacity-30'
                >
                    <small>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </small>
                </button>
                <span>
                    {page} / {pages}
                </span>
                <button
                    onClick={nextPage}
                    disabled={page == pages}
                    className='border rounded p-1 border-white/10 enabled:hover:border-white/50 disabled:opacity-30'
                >
                    <small>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </small>
                </button>
            </div>
        )
    )
}

const WithdrawRequestList = ({ apiHook, children }: WithdrawRequestListProviderProps) => {
    const [page, setPage] = useState(1)

    const [searchParams, setSearchParams] = useSearchParams()
    const status = searchParams.get('status')
    const username = searchParams.get('username')
    const [filters, setFilters] = useState<WithdrawRequestsParams['filters']>({ status: status ?? 'all', username })

    useEffect(() => {
        if (filters?.status != status || filters?.username != username)
            setSearchParams(Object.fromEntries(Object.entries(filters).filter(([_, value]) => value && value != 'all')))
        setPage(1)
    }, [filters])

    useEffect(() => {
        const status = searchParams.get('status')
        const username = searchParams.get('username')
        setFilters({ status: status ?? 'all', username })
    }, [searchParams])

    const { data, isFetching } = apiHook({ page, filters })
    const hasNextPage = data?.pages > page
    const hasPrevPage = page > 1
    const nextPage = () => hasNextPage && setPage((page) => page + 1)
    const prevPage = () => hasPrevPage && setPage((page) => page - 1)
    return (
        <WithdrawRequestListContext.Provider
            value={{
                data: data?.data ?? [],
                page: page,
                setPage,
                pages: data?.pages,
                isFetching,
                nextPage,
                prevPage,
                filters,
                setFilters,
            }}
        >
            <div className='flex flex-col gap-1'>{children}</div>
        </WithdrawRequestListContext.Provider>
    )
}

const WithdrawRequestsSummary = ({ apiHook }) => {
    const { filters, setFilters } = useContext(WithdrawRequestListContext)
    const { data } = apiHook({ filters })

    return (
        data && (
            <div className='flex flex-row items-center justify-center gap-4 text-xs'>
                {Object.entries(data).map(([key, value]: [keyof WithdrawRequestSummary, any]) => (
                    <button
                        key={key}
                        title={key}
                        onClick={() => setFilters((prev) => ({ ...prev, status: key }))}
                        className={twMerge(
                            'flex flex-row items-center gap-1 p-1',
                            'border border-white/10 rounded hover:border-white/50',
                            key == filters?.status && 'border-white',
                            {
                                all: 'text-white',
                                created: 'text-white',
                                submitted: 'text-white',
                                approved: 'text-blue-500',
                                processing: 'text-yellow-500',
                                finalized: 'text-green-500',
                                completed: 'text-green-500',
                            }[key]
                        )}
                    >
                        <FontAwesomeIcon
                            icon={
                                {
                                    all: faMoneyBills,
                                    created: faEye,
                                    submitted: faEye,
                                    approved: faCheckToSlot,
                                    processing: faStopwatch,
                                    finalized: faCheckCircle,
                                    completed: faCheckCircle,
                                }[key]
                            }
                        />
                        <div>{value}</div>
                    </button>
                ))}
            </div>
        )
    )
}

const WithdrawRequestListAdminTemplate = () => {
    const { data } = useContext(WithdrawRequestListContext)
    return (
        <div className='flex flex-col text-xs'>
            {data?.length > 0 ? (
                data?.map((withdrawRequest) => (
                    <WithdrawRequest key={withdrawRequest.uuid} withdrawRequest={withdrawRequest}>
                        <WithdrawRequest.AdminTemplate />
                    </WithdrawRequest>
                ))
            ) : (
                <small className='text-white/50 text-center font-bold uppercase py-4'>No withdraw requests found</small>
            )}
        </div>
    )
}

const WithdrawRequestListUserTemplate = () => {
    const { data } = useContext(WithdrawRequestListContext)
    return (
        <div className='flex flex-col gap-4 text-xs'>
            {data?.length > 0 ? (
                data?.map((withdrawRequest) => (
                    <WithdrawRequest key={withdrawRequest.uuid} withdrawRequest={withdrawRequest}>
                        <WithdrawRequest.UserTemplate />
                    </WithdrawRequest>
                ))
            ) : (
                <small className='text-white/50 text-center font-bold uppercase py-4'>No withdraw requests found</small>
            )}
        </div>
    )
}

const WithdrawUsernameFilter = () => {
    const { filters, setFilters } = useContext(WithdrawRequestListContext)
    const [username, setUsername] = useState(filters?.username ?? '')

    const submit = (e) => {
        e.preventDefault()
        setFilters({ ...filters, username: username || null })
    }

    useEffect(() => setUsername(filters?.username ?? ''), [filters])

    return (
        <form className='flex flex-row gap-2' onSubmit={submit}>
            <input
                type='text'
                placeholder='Username'
                className='input-team-tools my-2'
                value={username ?? ''}
                onChange={(e) => setUsername(e.target.value)}
            />
            <button className='btn-team-tools'>
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </form>
    )
}

WithdrawRequestList.AdminTemplate = () => {
    return (
        <div className='flex flex-col'>
            <WithdrawRequestList apiHook={useWithdrawRequests}>
                <WithdrawRequestsSummary apiHook={useWithdrawRequestsSummary} />
                <WithdrawUsernameFilter />
                <small className='flex flex-row self-end'>
                    <Navigation />
                </small>
                <WithdrawRequestListAdminTemplate />
                <small className='flex flex-row self-end my-4'>
                    <Navigation />
                </small>
            </WithdrawRequestList>
        </div>
    )
}

WithdrawRequestList.UserTemplate = () => (
    <WithdrawRequestList apiHook={useWithdrawUserRequests}>
        <WithdrawRequestsSummary apiHook={useWithdrawUserRequestsSummary} />
        <small className='flex flex-row self-end'>
            <Navigation />
        </small>
        <WithdrawRequestListUserTemplate />
        <small className='flex flex-row self-end my-4'>
            <Navigation />
        </small>
    </WithdrawRequestList>
)

export { WithdrawRequestList }
