import { createContext, useEffect, useState } from 'react'
import { useUserLootboxes } from '../api/useUserLootboxes'

export const LootboxContext = createContext<LootboxContextType>(undefined)

export const LootboxProvider = ({ children }) => {
    const { data, isFetched } = useUserLootboxes()
    const [lootboxes, setLootboxes] = useState<LootboxType[]>([])
    const [currentOpening, setCurrentOpening] = useState<LootboxType>(undefined)
    const [videoSrc, setVideoSrc] = useState<string>(undefined)
    const [videoPlaying, setVideoPlaying] = useState(false)

    useEffect(() => {
        const fetchVideo = async () => {
            const reposne = await fetch('/media/video/lootbox_open.mp4')
            const blob = await reposne.blob()
            setVideoSrc(URL.createObjectURL(blob))
        }
        fetchVideo()
    }, [])

    useEffect(() => {
        if (data) setLootboxes(data.map((l) => ({ ...l, state: 'LOCKED' })))
    }, [data])

    return (
        <LootboxContext.Provider
            value={{
                lootboxes,
                isFetched,
                setLootboxes,
                currentOpening,
                setCurrentOpening,
                videoSrc,
                videoPlaying,
                setVideoPlaying,
            }}
        >
            {children}
        </LootboxContext.Provider>
    )
}
