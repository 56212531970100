import { Page } from '~/components/Page'
import { RewardInfo } from '../components/RewardInfo'
import { StakeDescription } from '../components/StakeDescription'
import { StakeExample } from '../components/StakeExample'
import { StakeList } from '../components/StakeList'
import { StakeListProvider } from '../providers/StakeListProvider'
import { UserStakeProvider } from '../providers/UserStakeProvider'

export const StakeRoute = () => {
    return (
        <Page title='Renting'>
            <UserStakeProvider>
                <Stake />
            </UserStakeProvider>
        </Page>
    )
}

const Stake = () => {
    return (
        <div className='py-12 flex flex-col gap-12 p-8'>
            <StakeListProvider>
                <div className='flex flex-col lg:flex-row items-start justify-between gap-8'>
                    <div className='flex flex-col gap-8'>
                        <StakeDescription />
                        <RewardInfo />
                    </div>
                    <StakeExample />
                </div>
                <StakeList />
            </StakeListProvider>
        </div>
    )
}
