import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Wallet } from '~/features/auth/types'
import { useBalance, useWithdrawCreate, useWithdrawStrategy } from '../api'
import { useWithdrawUserRequests } from '../api/useWithdrawUserRequests'
import { useCurrency } from '../hooks'
import { Br1TokenCurrency } from '../types'
import { CurrencyInput } from './CurrencyInput'
import { SelectCurrency } from './SelectCurrency'
import { UserWalletSelect } from './UserWalletSelect'
import { WithdrawRequestList } from './WithdrawRequestList'
const hexagonExplanation = new URL('../assets/hexagon-explanation.png', import.meta.url).toString()

const Highlight = ({ children, duration = 2000 }) => {
    const [show, setShow] = useState<boolean>(true)
    const [data, setData] = useState<any>(children)
    useEffect(() => {
        setTimeout(() => setShow(false), 100)
        setTimeout(() => setData(<>&nbsp;</>), duration)
    }, [])
    return (
        <div
            className={twMerge('animate transition-colors', show && 'bg-white/10')}
            style={{ transitionDuration: `${duration}ms` }}
        >
            {data}
        </div>
    )
}

const WithdrawForm = () => {
    const { currency } = useCurrency()
    const { data: usdc } = useBalance()
    const withdrawStrategy = useWithdrawStrategy(currency)
    const [wallet, setWallet] = useState<Wallet>()
    const [amount, setAmount] = useState<number>(0)
    const [targetAmount, setTargetAmount] = useState<number>(0)
    const [validationError, setValidationError] = useState<string>()
    const { mutate, error, isLoading, isSuccess, data, reset } = useWithdrawCreate()
    const { data: withdrawRequests } = useWithdrawUserRequests({ page: 1 })

    const calculateTargetAmount = (value) => Math.round(value * (1 - withdrawStrategy.fee) * 100) / 100
    const calculateTokenAmount = (value) => Math.round(value * (1 / (1 - withdrawStrategy.fee)) * 100) / 100

    const tokenChangeHandler = (e) => {
        const value = e.target.value
        setAmount(value)
        setTargetAmount(calculateTargetAmount(value))
    }

    const targetChangeHandler = (e) => {
        const value = e.target.value
        setTargetAmount(value)
        setAmount(calculateTokenAmount(value))
    }

    const setPercentage = (percentage: number) => () => {
        let amount = Math.floor((usdc?.usdc ?? 0) * percentage) / 100
        setAmount(amount)
        setTargetAmount(calculateTargetAmount(amount))
    }

    const validate = () => {
        if (!wallet) {
            setValidationError('Select wallet')
            return false
        }
        if (amount > (usdc?.usdc ?? 0)) {
            setValidationError('Insufficient balance')
            return false
        } else if (amount < 10) {
            setValidationError('Minimum withdrawal amount: 25 $')
            return false
        }
        return true
    }

    const submit = () => {
        reset()
        if (validate()) {
            mutate({ wallet: wallet.pubkey, target_currency: currency.name, amount: amount })
        }
    }

    const clear = () => {
        setAmount(0)
        setTargetAmount(0)
    }

    useEffect(() => {
        setValidationError('')
    }, [amount])

    useEffect(() => {
        if (isSuccess) {
            clear()
        }
    }, [isSuccess])

    return (
        <div className='grid grid-cols-1 md:grid-cols-[1fr,max-content,1fr] col-start-1 col-end-[-1] gap-2'>
            <div className='col-start-1 col-end-[-1]'>
                <UserWalletSelect onSelect={(wallet) => setWallet(wallet)} />
            </div>
            <CurrencyInput currency={Br1TokenCurrency} value={amount.toString()} onChange={tokenChangeHandler} />
            <div className='flex flex-row items-center px-2 font-bold'>=</div>
            <CurrencyInput currency={currency} value={targetAmount.toString()} onChange={targetChangeHandler} />
            <div className='row-start-3 md:row-start-auto grid grid-cols-4 gap-1 text-[0.6em]'>
                {[25, 50, 75, 100].map((percentage) => (
                    <button
                        key={percentage}
                        className='bg-white/20 hover:outline outline-white/50'
                        onClick={setPercentage(percentage)}
                    >
                        {percentage} %
                    </button>
                ))}
            </div>
            <div className='col-start-[-2] text-[0.6em]'>Fee: {withdrawStrategy.fee * 100} %</div>

            <div className='col-start-1 col-end-[-1] bg-black-gradient text-[50%] p-2 my-2 flex flex-row gap-2 items-center'>
                <img src={hexagonExplanation} className='h-4 w-4' />
                <div className='tracking-wider'>
                    ESTIMATED WAIT PERIOD FOR WITHDRAWAL REQUESTS IS APPROXIMATELY 7 DAYS.
                </div>
            </div>

            <button className='col-start-1 col-end-[-1] bg-br1-gradient p-4 font-black hover:outline' onClick={submit}>
                REQUEST WITHDRAWAL {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>

            <div
                className={twMerge(
                    'col-start-1 col-end-[-1] text-[0.7em] tracking-widest text-center',
                    isSuccess ? 'text-green-500' : 'text-red-500'
                )}
            >
                {validationError ||
                    error?.error?.message ||
                    (isSuccess && <Highlight>Withdrawal request submitted.</Highlight>) || <span>&nbsp;</span>}
            </div>
            {withdrawRequests?.count > 0 && (
                <div className='col-start-1 col-end-[-1]'>
                    <WithdrawRequestList.UserTemplate />
                </div>
            )}
        </div>
    )
}

export const Withdraw = () => {
    const { currency } = useCurrency()
    return <>{currency ? <WithdrawForm /> : <SelectCurrency />}</>
}
