import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'

export const AccountsBetaUserCount = () => {
    const [requests, setRequests] = useState<number>()
    const [users, setUsers] = useState<number>()

    const getData = () => {
        jsonrpc('statistic.accounts.beta_users_count').then((res) => {
            const { requests, users } = res.result || {}
            setRequests(requests)
            setUsers(users)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Box className='w-full mx-auto'>
            <Header>Closed Beta</Header>
            <section className='grid grid-cols-2 w-full'>
                {[
                    {
                        name: 'requests',
                        value: requests,
                        note: 'Number of active requests',
                        as: (
                            <NavLink
                                to='/team-tools/accounts/users?beta_applicant__in=true'
                                className='hover:outline'
                            />
                        ),
                    },
                    {
                        name: 'users',
                        value: users,
                        note: 'Number of accepted users',
                        as: <NavLink to='/team-tools/accounts/users?beta_tester__in=true' className='hover:outline' />,
                    },
                ].map((item) => (
                    <Box key={item.name} className='items-stretch text-center'>
                        <Stat.Gray as={item.as} loading={item.value === undefined}>
                            <StatLabel className='capitalize text-amber-500'>{item.name}</StatLabel>
                            <StatNumber>{item.value}</StatNumber>
                        </Stat.Gray>
                        <small className='text-white/50'>{item.note}</small>
                    </Box>
                ))}
            </section>
        </Box>
    )
}
