import { useStake } from '../api/useStake'
import { useUnstake } from '../api/useUnstake'
import { useUpdateCache, useUserNFTs } from '../api/useUserNFTs'
import { useUserPoints } from '../api/useUserPoints'
import { StakeContext } from '../context'

export const UserStakeProvider = ({ children }) => {
    const { data, isFetched } = useUserNFTs()
    const { data: points } = useUserPoints()

    return (
        <StakeContext.Provider value={{ data, points, isFetched, useUpdateCache, useStake, useUnstake }}>
            {children}
        </StakeContext.Provider>
    )
}
