import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export default () => {
    const {pathname} = useLocation()

    useEffect(() => {
        document.querySelector('html').scrollTo({
            top: 1
        })
    }, [pathname])

    return null
}