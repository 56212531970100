import { useQuery } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'
import { JsonRpcError } from '~/types'
import { Balance } from '../types'

type BalanceResponse = {
    symbol: string
    amount: number
    updated_at?: string
    expires_at?: string
}

export const useBalance = () => {
    const { data } = useUser()

    return useQuery<BalanceResponse[], JsonRpcError, Balance>({
        queryKey: ['balance', data?.username],
        enabled: data?.username !== undefined,
        queryFn: async () => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'me.bank.balance',
                }),
            })
            const data = await res.json()
            if (data.error) {
                throw data.error
            }
            return data.result
        },
        retry: false,
        select: (data) => {
            const usdc = data?.find((item) => item.symbol.toLowerCase() === 'usdc')?.amount
            const point = data?.find((item) => item.symbol.toLowerCase() === 'point')?.amount
            const airdrop = data?.find((item) => item.symbol.toLowerCase() === 'airdrop')?.amount
            const airdropExpiredAt = data?.find((item) => item.symbol.toLowerCase() === 'airdrop')?.expires_at
            const totalInGame = Number(((usdc || 0) + (airdrop || 0)).toFixed(2))
            return { usdc, point, airdrop, airdropExpiredAt, totalInGame }
        },
    })
}
