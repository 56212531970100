import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import { Icons } from '../assets/icons'
import { StakeListContext } from '../context'
import { useStackableNFTs } from '../hooks/useStakableNFTs'

export const NFTTypeFilter = () => {
    return (
        <div className='relative text-xs'>
            <header className='absolute top-0 left-1 -translate-y-full font-bold'>FILTER</header>
            <div className='grid grid-cols-1 md:grid-cols-[repeat(4,max-content)] gap-x-4 '>
                <FilterButton Icon={Icons.Ape} value='ape' label={'apes'} />
                <FilterButton Icon={Icons.Droid} value='droid' label={'droids'} />
                <FilterButton Icon={Icons.Lootbox} value='lootbox' label={'loot boxes'} />
                <FilterButton Icon={Icons.Weapon} value='weapon' label={'weapons'} />
            </div>
        </div>
    )
}

const FilterButton = ({ Icon, value, label = null }) => {
    label = label || value
    const { staked, unstaked } = useStackableNFTs()
    const { filter, setFilter } = useContext(StakeListContext)

    const currentData = filter.staked ? staked : unstaked
    const filteredData = currentData.filter((nft) => nft.operative_type === value)
    const count = filteredData.length
    const rate = filter.staked ? filteredData.reduce((acc, nft) => acc + nft.stake_rate, 0) : null

    const checked = filter.types.includes(value)
    const toggle = () => {
        setFilter(({ types, ...rest }) => {
            return { types: checked ? types.filter((type) => type !== value) : [...types, value], ...rest }
        })
    }

    return (
        <button
            onClick={toggle}
            className={twMerge('group flex flex-row items-center gap-2 w-42', !checked && ' fill-white opacity-20')}
        >
            <Icon className='group-hover:stroke-white h-14 w-14' />

            <div className='flex flex-col flex-1 text-left'>
                <div className='flex flex-row items-center gap-2'>
                    <div className='text-xl font-black'>{count}</div>
                    <small className='font-bold uppercase'>{label}</small>
                </div>
                <small className=' text-white/60 font-bold h-4'>{rate != null && <span>{rate} PTS / DAY</span>}</small>
            </div>
        </button>
    )
}
