import { PayoutWalletBalance, WithdrawRequestList } from '../components'

export const WithdrawRequests = () => {
    return (
        <div className='grid grid-cols-1 gap-4'>
            <div className='flex flex-col items-center gap-2 text-[0.6rem] font-bold'>
                <div className='font-bold uppercase'>Payout wallets</div>
                <div className='flex flex-col md:flex-row items-center gap-4'>
                    <PayoutWalletBalance currency='usdc-spl' />
                    <PayoutWalletBalance currency='solana' />
                </div>
            </div>
            <WithdrawRequestList.AdminTemplate />
        </div>
    )
}
