import { BankContext } from './context'

const BalanceBlock = ({ amount, symbol, header = undefined, className = undefined, ...rest }) => {
    return (
        <div className='flex flex-col items-center gap-1'>
            {header && <header className='text-xs text-violet-500 font-bold'>{header}</header>}
            <div
                className={['flex flex-col items-center gap-1 px-4 py-2 rounded bg-white/5', className]
                    .filter(Boolean)
                    .join(' ')}
            >
                <div className='font-bold'>{amount}</div>
                <div className='text-xs text-white/50'>{symbol}</div>
            </div>
        </div>
    )
}

export const BankBalance = (props) => {
    return (
        <BankContext.Consumer>
            {({ bankBalance }) => (
                <BalanceBlock amount={bankBalance.amount} symbol={bankBalance.symbol} header='Bank' {...props} />
            )}
        </BankContext.Consumer>
    )
}

export const UsersBalance = (props) => {
    return (
        <BankContext.Consumer>
            {({ usersBalance }) =>
                usersBalance.map((b) => (
                    <BalanceBlock key={b.symbol} amount={b.amount} symbol={b.symbol} header='Users' {...props} />
                ))
            }
        </BankContext.Consumer>
    )
}
