import { useEffect, useState } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'

export const AccountsReferrals = () => {
    const [visits, setVisits] = useState<number>()
    const [signups, setSignups] = useState<number>()

    const getData = () => {
        jsonrpc('statistic.accounts.referrals').then((res) => {
            const [v, s] = res.result || []
            setVisits(v)
            setSignups(s)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Box className='w-full mx-auto'>
            <Header>Referrals</Header>
            <section className='grid grid-cols-2 gap-2 mt-2 w-full'>
                {[
                    ['Visits', visits],
                    ['Signups', signups],
                ].map(([name, value]) => (
                    <Stat.Gray key={name} loading={value === undefined}>
                        <StatLabel className='text-amber-500'>{name}</StatLabel>
                        <StatNumber>{value}</StatNumber>
                    </Stat.Gray>
                ))}
            </section>
        </Box>
    )
}
