import { useInfiniteQuery } from '@tanstack/react-query'
import { JsonRpcError } from '~/types'
import { LeaderboardResponse } from '../types'

export const useLeaderboard = ({
    type = undefined,
    begin = undefined,
    end = undefined,
    mode = undefined,
    kill_required = false,
    page_size,
    order_by,
    filters,
}) => {
    return useInfiniteQuery<LeaderboardResponse, JsonRpcError>({
        queryKey: ['leaderboard', type, begin, end, mode, page_size, order_by, filters],
        getNextPageParam: (_lastPage, pages) => {
            if (pages.length < _lastPage.pages) {
                return _lastPage.page + 1
            }
        },
        queryFn: async ({ pageParam = 1 }) => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'gameapi.leaderboard',
                    params: {
                        ...{ type, begin, end, mode, kill_required },
                        page_size,
                        page: pageParam,
                        order_by,
                        filters,
                    },
                }),
            })
            const data = await res.json()
            if (data.error) {
                throw data.error
            }
            return data.result
        },
        retry: false,
    })
}
