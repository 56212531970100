import React, { useCallback, useContext, useState } from 'react'

export const NotificationContext = React.createContext({
    notification: null,
    addNotification: (message, status) => {},
    removeNotification: () => {},
})

export function useNotification() {
    const { notification, addNotification, removeNotification } = useContext(NotificationContext)
    return { notification, addNotification, removeNotification }
}

export default function NotificationProvider({ children }) {
    const [notification, setNotification] = useState(null)

    const removeNotification = () => setNotification(null)

    const addNotification = (message, status) => {
        setNotification({ message, status })
    }

    const contextValue = {
        notification,
        addNotification: useCallback((message, status) => addNotification(message, status), []),
        removeNotification: useCallback(() => removeNotification(), []),
    }

    return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
}
