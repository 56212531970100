import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { GameSessionKills } from './GameSessionKills'

export const GameSession = ({
    session,
    rowNumber,
    showUsername = false,
}: {
    session: GameSession
    rowNumber: number
    showUsername?: boolean
}) => {
    const [showDetails, setShowDetails] = useState(false)

    const toggleDetails = () => {
        if (session?.kills_count > 0) setShowDetails((prev) => !prev)
    }
    return (
        <>
            <tr
                onClick={toggleDetails}
                className={twMerge(session?.kills_count > 0 && 'cursor-pointer hover:text-blue-500')}
            >
                <td className='text-xs text-white/20'>{rowNumber + 1}</td>
                {showUsername && <td>{session.username}</td>}

                <td>
                    {session?.kills_count > 0 && (
                        <button>
                            <FontAwesomeIcon icon={showDetails ? faChevronDown : faChevronRight} />
                        </button>
                    )}
                </td>

                <td title={`id: ${session?.server_id}`}>{session?.server_name}</td>
                <td title={session?.spawned_at}>{new Date(session?.spawned_at).toLocaleString()}</td>
                <td>{session?.ip}</td>
                <td>{session?.duration?.split('.')?.[0]}</td>
                <td>{session?.fee}</td>
                <td>{session?.kills_count}</td>
                <td className={twMerge(session?.earnings > 0 && 'text-green-500')}>{session?.earnings}</td>
                <td className={twMerge(session?.end_reason == 69 && 'text-red-500')}>
                    {endReasonFormat(session?.end_reason)}
                </td>
            </tr>
            {showDetails && (
                <tr>
                    <td></td>
                    <td colSpan={8} className='bg-white/5'>
                        <GameSessionKills session={session} />
                    </td>
                </tr>
            )}
        </>
    )
}

function endReasonFormat(endReason: number) {
    return {
        1: 'Death',
        2: 'Survival',
        3: 'Disconnect',
        4: 'Quit',
        69: 'Cheat',
    }[endReason]
}
