import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useSearchParams } from 'react-router-dom'
import { Page } from '~/components/Page'
import { LoginForm } from '../components'

export const LoginPage = () => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const [flashMessage, setFlashMessage] = useState('')

    useEffect(() => {
        if (searchParams.get('source') === 'password_reset') {
            setFlashMessage('New password sent to your email.')
        }
    }, [searchParams])
    return (
        <Page title='LOGIN' header='LOGIN'>
            <section className='animate-fade-in px-8 w-full max-w-4xl mx-auto flex flex-col items-center gap-y-8 text-xl text-center font-bold py-16 lg:py-24'>
                <header className='self-center text-4xl uppercase font-black text-center lg:text-5xl'>
                    {t('auth.layout_header')}
                </header>
                <div className='font-semibold'>{t('auth.login_header')}.</div>
                <div className='grid grid-cols-2 gap-1 w-full text-base md:text-xl whitespace-nowrap'>
                    <NavLink
                        to='/signup'
                        className='p-4 hover:outline'
                        style={{ background: 'linear-gradient(140deg,#000 0%,rgba(0,0,0,0.00) 100%)' }}
                    >
                        {t('auth.signup')}
                    </NavLink>
                    <NavLink
                        to='/login'
                        className='p-4 hover:outline'
                        style={{ background: 'linear-gradient(142deg,#03CEFF 0%,#C91FFF 100%)' }}
                    >
                        {t('auth.login')}
                    </NavLink>
                </div>
                {flashMessage && (
                    <div className='flex flex-row items-center justify-center gap-2 bg-black/50 text-yellow-500 w-full p-2 backdrop-blur-xl'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span>{flashMessage}</span>
                    </div>
                )}
                <LoginForm />
                <div>
                    {t('auth.have_not_account')}?{' '}
                    <NavLink to='/signup' className='underline hover:no-underline'>
                        {t('auth.signup')}.
                    </NavLink>
                </div>
                <div>
                    {t('auth.forgot_password')}?{' '}
                    <NavLink to='/password-reset' className='underline hover:no-underline'>
                        {t('auth.reset_password')}.
                    </NavLink>
                </div>
            </section>
        </Page>
    )
}
