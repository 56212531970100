import React, { useEffect, useState } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'

type Balance = {
    amount: number
    symbol: string
}

const initialBalance: Balance = {
    amount: undefined,
    symbol: undefined,
}

interface IBankContext {
    bankBalance: Balance
    usersBalance: Balance[]
    reloadBalance: () => void
}

export const BankContext = React.createContext<IBankContext>({
    bankBalance: initialBalance,
    usersBalance: [],
    reloadBalance: () => {},
})

export const BankProvider = ({ children }) => {
    const [bankBalance, setBankBalance] = useState<Balance>(initialBalance)
    const [usersBalance, setUsersBalance] = useState<Balance[]>([])

    const reloadBalance = () => {
        jsonrpc('bank.bank_balance', {}).then((resp) => setBankBalance(resp?.result || initialBalance))
        jsonrpc('bank.users_balance', {}).then((resp) => setUsersBalance(resp?.result || []))
    }

    useEffect(() => {
        reloadBalance()
    }, [])

    return <BankContext.Provider value={{ bankBalance, usersBalance, reloadBalance }}>{children}</BankContext.Provider>
}
