import { useEffect, useState } from 'react'
import { StakeListContext } from '../context'
import { useStackableNFTs } from '../hooks/useStakableNFTs'

export const StakeListProvider = ({ children }) => {
    const { unstaked, staked, data: _ } = useStackableNFTs()

    const [filter, setFilter] = useState<StakeFilter>({
        staked: !(unstaked.length > 0),
        types: ['ape', 'droid', 'lootbox', 'weapon'],
    })

    const data = _.filter((nft) => !!nft.staked === filter.staked && filter.types.includes(nft.operative_type))

    useEffect(() => {
        setFilter(({ staked: prev, types }) => {
            return { staked: prev ? staked.length > 0 : !(unstaked.length > 0), types }
        })
    }, [staked.length, unstaked.length])

    return <StakeListContext.Provider value={{ data, filter, setFilter }}>{children}</StakeListContext.Provider>
}
