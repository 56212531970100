import { useTranslation } from 'react-i18next'
import { Page } from '~/components/Page'
import { useViewer } from '../api'
import { ActivationCodeForm, ResendActivationEmailForm } from '../components'

export const ActivationPage = () => {
    const { t } = useTranslation()
    const { data: viewer } = useViewer()

    return (
        <Page title='ACTIVATE' header='ACTIVATE'>
            <section className='animate-fade-in px-8 w-full max-w-4xl mx-auto flex flex-col items-center gap-y-8 text-xl text-center font-bold py-16 lg:py-24'>
                <header className='self-center text-4xl uppercase font-black text-center lg:text-5xl'>
                    {t('auth.activate_your_account')}
                </header>
                <div className='flex flex-col font-semibold'>
                    <span>{t('auth.verify_your_email')}</span>
                    <span className='bg-[linear-gradient(162deg,#03CEFF_0%,#C91FFF_100%)] bg-clip-text text-transparent'>
                        {viewer?.email}
                    </span>
                </div>
                <div className='flex flex-col w-full'>
                    <ActivationCodeForm />
                    <ResendActivationEmailForm />
                </div>
            </section>
        </Page>
    )
}
