import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePayoutWalletBalance } from '../api'
import { formatWalletAddress } from '../utils'
import { currencies } from './SelectCurrency'

export const PayoutWalletBalance = ({ currency }) => {
    const usdc = currencies.find((c) => c.name === currency)
    const { data, isLoading, isSuccess, isError } = usePayoutWalletBalance({ currency: usdc.name })
    return (
        <div className='flex flex-col gap-1 items-center justify-center text-xs'>
            <div
                className='flex flex-row items-center gap-1 bg-white/10 rounded p-2 uppercase'
                title={data?.updated_at && new Date(data.updated_at).toLocaleString()}
            >
                <>
                    {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : data?.amount}
                    {isError && '...'}
                    <img src={usdc.img} alt={usdc.name} className='w-4 h-4' />
                </>
            </div>
            {isSuccess ? (
                <small title={data?.pubkey} className='text-white/30'>
                    {formatWalletAddress(data?.pubkey)}
                </small>
            ) : (
                <div>&nbsp;</div>
            )}
        </div>
    )
}
