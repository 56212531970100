import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useLootBoxes } from '../hooks/useLootboxes'

export const LootboxOpenVideo = ({ onEnd }) => {
    const { videoSrc } = useLootBoxes()

    const [opacity, setOpacity] = useState(0)
    const [bgOpacity, setBgOpacity] = useState(1)

    useEffect(() => {
        setOpacity(1)
    }, [])

    const close = () => {
        setOpacity(0)
        setBgOpacity(0)
        setTimeout(() => onEnd(), 1000)
    }

    return (
        <div
            className='relative w-full h-full transition-all duration-[1s] flex flex-col justify-center'
            style={{
                backgroundColor: `rgba(0, 0, 0, ${bgOpacity})`,
            }}
        >
            <video
                onClick={() => close()}
                onEnded={() => close()}
                src={videoSrc}
                autoPlay
                // muted
                className={twMerge('object-cover w-full h-3/4 transition-opacity duration-[1s]')}
                style={{ opacity: opacity }}
            />
            <div className='absolute bottom-0 left-1/2 -translate-x-1/2 font-bold'>CLICK TO SKIP</div>
        </div>
    )
}
