import { useQuery } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'
import { useStakeEpoch } from '../hooks/useStakeEpoch'

const API_URL = '/api/nft/staking/points'

export const useUserPoints = () => {
    const { data: user } = useUser()
    const { begin_timestamp, end_timestamp } = useStakeEpoch()

    const query = useQuery<UserPoints>({
        queryKey: [API_URL],
        queryFn: async () => {
            const res = await fetch(`${API_URL}?epoch_begin=${begin_timestamp}&epoch_end=${end_timestamp}`)
            const data = await res.json()
            return data
        },
        retry: false,
        enabled: !!user,
    })

    return query
}
