import { Route, Routes } from 'react-router-dom'
import NotFound from '~/components/NotFound'
import { DownloadPage } from './DownloadPage'

export const DownloadRoutes = () => {
    return (
        <Routes>
            <Route path='' element={<DownloadPage />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}
