import React from 'react'
import { Base, BaseProps } from './base'
import { Box } from './box'
import { twMerge } from 'tailwind-merge'

export const Stat = ({ children, className, loading, ...rest }: BaseProps) => {
    return React.createElement(Box, {
        className: twMerge('tracking-wide', children === undefined && 'animate-pulse', className),
        children: children,
        ...rest,
    })
}

Stat.Gray = ({ children, className, loading, ...rest }: BaseProps) => {
    return React.createElement(Box.Gray, {
        className: twMerge('tracking-wide', loading && 'animate-pulse', className),
        children: children,
        ...rest,
    })
}

export const StatLabel = ({ children, className, ...rest }: BaseProps) => {
    return React.createElement(Base, {
        className: twMerge('text-xs self-center', className),
        children: children,
        ...rest,
    })
}

export const StatNumber = ({ children, className, ...rest }: BaseProps) => {
    return React.createElement(Base, {
        className: twMerge('text-lg md:text-xl font-black self-center text-center w-full overflow-auto', className),
        children: children ?? <>&nbsp;</>,
        ...rest,
    })
}
