export const InputText = ({ className = undefined, ...rest }) => {
    return (
        <input
            type='text'
            className={['border rounded-lg p-2 font-bold bg-black/25 outline-white', className]
                .filter(Boolean)
                .join(' ')}
            {...rest}
        />
    )
}
