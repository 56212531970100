import { faClose, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWallet } from '@solana/wallet-adapter-react'
import { twMerge } from 'tailwind-merge'
import { WalletSelector } from './WalletSelector'

export const WalletSelectorModal = ({ lootbox, setWalletSelecting, dispatch }) => {
    const { publicKey } = useWallet()
    const rightWallet = publicKey?.toBase58() === lootbox.owner

    return (
        <div className='fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-2 bg-black p-8 border border-white/20 rounded-lg'>
            <button className='absolute top-2 right-2' onClick={() => setWalletSelecting(false)}>
                <FontAwesomeIcon icon={faClose} />
            </button>
            <header className='text-center flex flex-col gap-2'>
                <small className='uppercase text-white/50'>Connect wallet to open</small>
                <span className='uppercase font-bold'>{lootbox.name}</span>
                <small
                    className={twMerge(
                        'flex flex-row items-center gap-1 mx-auto',
                        rightWallet ? 'opacity-100 text-green-500 ' : 'opacity-50'
                    )}
                >
                    <span>Owner</span>
                    <FontAwesomeIcon icon={faWallet} />
                    <span>
                        {lootbox.owner.slice(0, 6)}...{lootbox.owner.slice(-6)}
                    </span>
                </small>
            </header>
            <WalletSelector />
            {publicKey && (
                <small>
                    {publicKey.toBase58().slice(0, 6)}...{publicKey.toBase58().slice(-6)}
                </small>
            )}
            {publicKey && !rightWallet && (
                <small className='uppercase text-yellow-500 font-bold'>Wallet mismatch</small>
            )}
            <button
                disabled={!rightWallet}
                className='bg-br1-gradient p-2 font-bold w-full disabled:opacity-20 hover:outline'
                onClick={() => {
                    setWalletSelecting(false)
                    dispatch({ type: 'UNLOCK' })
                }}
            >
                OPEN
            </button>
        </div>
    )
}
