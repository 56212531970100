import { useQuery } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'
import { JsonRpcError } from '~/types'
import { WithdrawRequestListResponse, WithdrawUserRequestsParams } from '../types'

export const useWithdrawUserRequests = ({ page = 1, filters }: WithdrawUserRequestsParams) => {
    const { data: user } = useUser()
    return useQuery<WithdrawRequestListResponse, JsonRpcError>({
        queryKey: ['withdraw_user_requests', page, filters],
        queryFn: async () => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'bank.withdraw_request.user_requests',
                    params: {
                        page,
                        filters,
                    },
                }),
            })
            const data = await res.json()
            if (data.error) {
                throw data
            } else {
                return data.result
            }
        },
        enabled: user.is_auth,
        keepPreviousData: true,
        staleTime: 1000 * 60,
    })
}
