import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Page } from '~/components/Page'
import { PasswordResetForm } from '../components'

export const PasswordResetPage = () => {
    const { t } = useTranslation()
    return (
        <Page title='LOGIN' header='LOGIN'>
            <section className='animate-fade-in px-8 w-full max-w-4xl mx-auto flex flex-col items-center gap-y-8 text-xl text-center font-bold py-16 lg:py-24'>
                <header className='self-center text-4xl uppercase font-black text-center lg:text-5xl'>
                    {t('auth.layout_header')}
                </header>
                <PasswordResetForm />
                <div>
                    {t('auth.do_you_remember_it')}?{' '}
                    <NavLink to='/login' className='underline hover:no-underline'>
                        {t('auth.login')}.
                    </NavLink>
                </div>
            </section>
        </Page>
    )
}
