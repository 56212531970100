import { useMutation, useQueryClient } from '@tanstack/react-query'
import { JsonRpcError } from '~/types'
import { WithdrawRequest, WithdrawRequestListResponse } from '../types'

export const useWithdrawRevokeApprove = () => {
    const queryClient = useQueryClient()

    return useMutation<WithdrawRequest, JsonRpcError, unknown>({
        mutationFn: async ({ uuid }) => {
            const resp = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'bank.withdraw_request.undo_approve',
                    params: {
                        uuid,
                    },
                }),
            })
            const data = await resp.json()

            if (data.error) {
                throw data
            } else {
                return data.result
            }
        },
        onSuccess: (data) => {
            queryClient.setQueriesData(['withdraw_requests'], (prev: WithdrawRequestListResponse) => {
                return {
                    ...prev,
                    data: prev.data?.map((item) => {
                        if (item.uuid === data.uuid) {
                            return data
                        } else {
                            return item
                        }
                    }),
                }
            })
            queryClient.invalidateQueries(['withdraw_request.summary'])
        },
        retry: false,
    })
}
