import { Fragment, useEffect } from "react"
import { useLocation } from "react-router-dom"

export default ({children}) => {
    const location = useLocation()

    const scrollToHash = (hash) => {
        document.querySelector(hash)?.scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    useEffect(() => {
        if (location.hash) {
            setTimeout(() => scrollToHash(location.hash),600)
        } 
    }, [location])
    

    return <Fragment>
        {children}
    </Fragment>
}