import { useContext, useMemo } from 'react'
import { StakeContext } from '../context'

export const useStackableNFTs = () => {
    const { data, isFetched, useStake, useUnstake } = useContext(StakeContext)

    const staked = useMemo(() => data.filter((nft) => !!nft.staked), [data])
    const stakedApes = useMemo(() => staked.filter((nft) => nft.operative_type === 'ape'), [staked])
    const stakedDroids = useMemo(() => staked.filter((nft) => nft.operative_type === 'droid'), [staked])
    const stakedLootboxes = useMemo(() => staked.filter((nft) => nft.operative_type === 'lootbox'), [staked])
    const stakedWeapons = useMemo(() => staked.filter((nft) => nft.operative_type === 'weapon'), [staked])

    const unstaked = useMemo(() => data.filter((nft) => !!!nft.staked), [data])
    const unstakedApes = useMemo(() => unstaked.filter((nft) => nft.operative_type === 'ape'), [unstaked])
    const unstakedDroids = useMemo(() => unstaked.filter((nft) => nft.operative_type === 'droid'), [unstaked])
    const unstakedLootboxes = useMemo(() => unstaked.filter((nft) => nft.operative_type === 'lootbox'), [staked])
    const unstakedWeapons = useMemo(() => unstaked.filter((nft) => nft.operative_type === 'weapon'), [staked])

    return {
        data,
        isFetched,
        useStake,
        useUnstake,
        staked,
        stakedApes,
        stakedDroids,
        unstaked,
        unstakedApes,
        unstakedDroids,
        stakedLootboxes,
        stakedWeapons,
        unstakedLootboxes,
        unstakedWeapons,
    }
}
