import { useMutation } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { useUpdateCache } from './useUserNFTs'

const API_URL = '/api/nft/staking/stake'

export const useStake = () => {
    const updateCache = useUpdateCache()

    const mutation = useMutation({
        mutationFn: async (nfts: NFT[]) => {
            const resp = await fetch(API_URL, {
                method: 'POST',
                body: JSON.stringify(nfts.map((nft) => nft.operative_id)),
                headers: {
                    'X-CSRFToken': useCrsfToken(),
                },
            })
            if (!resp.ok) {
                throw new Error('Failed to stake')
            }
        },
        onSuccess(data, variables, context) {
            updateCache(variables, { staked: true })
        },
        retry: false,
    })

    return { stake: mutation.mutate, isStaking: mutation.isLoading }
}
