import { KillRaceInfo } from '../types'

const bg_24 = '/media/assets/features/career/killrace_24_bg.png'
const bg_48 = '/media/assets/features/career/killrace_48_bg.png'
const bg_monthly = '/media/assets/features/career/killrace_monthly_bg.png'

export const useKillRaceList = () => {
    const all: KillRaceInfo[] = [
        {
            type: 'daily',
            title: '24 HOURS',
            banner: bg_24,
            enabled: true,
        },
        {
            type: 'two-days',
            title: '48 HOURS',
            banner: bg_48,
            enabled: true,
        },
        {
            type: 'monthly',
            title: 'MONTHLY',
            banner: bg_monthly,
            enabled: true,
        },
    ]
    const enabled = all.filter((r) => r.enabled)
    return { all, enabled }
}
