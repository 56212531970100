import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '~/components/Auth'
import { Auth } from '~/entities'
import { Button, InputText } from '~/shared/ui'
import { SignupContext, SignupProvider } from './context'

export const SignupForm = ({ className = undefined, onSignup = undefined }) => {
    const { auth } = useAuth()

    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        if (auth?.is_auth) navigate('/account')
    }, [auth])

    return (
        <SignupProvider>
            <SignupContext.Consumer>
                {({
                    username,
                    setUsername,
                    email,
                    setEmail,
                    password,
                    setPassword,
                    passwordRepeat,
                    setPasswordRepeat,
                    usernameError,
                    emailError,
                    passwordError,
                    passwordRepeatError,
                    commonError,
                    signup,
                }) => (
                    <Auth.Container className={className}>
                        <InputText
                            name='username'
                            value={username ?? ''}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder={t('auth:username').toUpperCase()}
                        />
                        <span className='uppercase text-xs text-red-500 -mt-1'>{usernameError || <>&nbsp;</>}</span>
                        <InputText
                            name='email'
                            value={email ?? ''}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t('auth:email').toUpperCase()}
                        />
                        <span className='uppercase text-xs text-red-500 -mt-1'>{emailError || <>&nbsp;</>}</span>
                        <InputText
                            type='password'
                            name='password1'
                            autocomplete='new-password'
                            value={password ?? ''}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={t('auth:password').toUpperCase()}
                        />
                        <span className='uppercase text-xs text-red-500 -mt-1'>{passwordError || <>&nbsp;</>}</span>
                        <InputText
                            type='password'
                            name='password2'
                            autocomplete='new-password'
                            value={passwordRepeat ?? ''}
                            onChange={(e) => setPasswordRepeat(e.target.value)}
                            placeholder={t('auth:password_repeat').toUpperCase()}
                        />
                        <span className='uppercase text-xs text-red-500 -mt-1'>
                            {commonError || passwordRepeatError || <>&nbsp;</>}
                        </span>
                        <Button.Gradient onClick={() => signup(onSignup)} className='uppercase text-xl font-semibold'>
                            {t('auth:register')}
                        </Button.Gradient>
                        <div className='flex flex-col gap-2 pt-4 uppercase text-[0.65rem] font-semibold'>
                            <div>
                                <span>{t('auth:already_have_account')} </span>
                                <NavLink to='/login' className='underline hover:no-underline'>
                                    {t('auth:login')}
                                </NavLink>
                            </div>
                        </div>
                    </Auth.Container>
                )}
            </SignupContext.Consumer>
        </SignupProvider>
    )
}
