import React from 'react'
import { Currency, Validator } from '../types'
import { DecimalPlacesValidator, PositiveNumberValidator } from '../utils'

interface CurrencyInputProps extends React.HTMLProps<HTMLInputElement> {
    currency: Currency
    validators?: Validator[]
}

export const CurrencyInput = ({ currency, validators = [], ...rest }: CurrencyInputProps) => {
    const { onChange } = rest

    const onChangeHandler = (e) => {
        const value = e.target.value
        if (
            [
                PositiveNumberValidator,
                currency.decimals ? DecimalPlacesValidator(currency.decimals) : () => true,
                ...validators,
            ].every((validator) => validator(value))
        ) {
            onChange && onChange(e)
        }
    }

    return (
        <label className='flex flex-row gap-2 items-center border border-white/50 p-2'>
            <img src={currency.img} className='h-6 w-6' />
            <input
                type='text'
                className='w-full text-white bg-black outline-none'
                {...rest}
                onChange={onChangeHandler}
            />
        </label>
    )
}
