import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AnchorScroll from './components/AnchorScroll'
import { AuthProvider, IsDeveloper, IsMarketing, IsStaff, RequireAuth } from './components/Auth'
import { CsrfProvider } from './components/Csrf'
import Menu from './components/Menu'
import NotFound from './components/NotFound'
import { PageContextProvider } from './components/Page'
import { PageFooter } from './components/PageFooter'
import ScrollToTop from './components/ScrollToTop'
import Spinner from './components/Spinner'
import { AuthRoutes } from './features/auth'
import { BankRoutes } from './features/bank'
import { KillRaceRoutes } from './features/career/routes'
import { DownloadRoutes } from './features/download'
import { LootboxRoutes } from './features/lootbox/routes'
import { StakeRoutes } from './features/stake/routes'
import { ModalProvider } from './hooks/useModal'
import './i18n'
import { LandingPage } from './routes'
import { WalletPage } from './routes/wallet/wallet'
import Airdrop from './team_tools/airdrop'
import { BankDeposit } from './team_tools/bank/deposit'
import { Br1AceDerby } from './team_tools/dashboard/br1_ace_derby'
import { Br1Race } from './team_tools/dashboard/br1_race'
import { Br1TreasureHunt } from './team_tools/dashboard/br1_treasure_hunt'
import { ServerControl } from './team_tools/dashboard/server_control'
import { Statistic } from './team_tools/dashboard/statistic'
import { TeamMembers } from './team_tools/dashboard/team_members'

const Account = lazy(() => import('./routes/account'))
const DataTools = lazy(() => import('./routes/data-tools'))
const DataToolsIndex = lazy(() => import('./routes/data-tools/index'))
const DataToolsHolders = lazy(() => import('./routes/data-tools/holders'))
const DataToolsServers = lazy(() => import('./routes/data-tools/servers'))
const DataToolsPlayers = lazy(() =>
    import('./routes/data-tools/players').then((module) => ({ default: module['Players'] }))
)
const Dashboard = lazy(() => import('./routes/dashboard').then((module) => ({ default: module['Dashboard'] })))
// TeanTools
const TeamTools = lazy(() => import('./team_tools'))
const TeamToolsDashboard = lazy(() =>
    import('./team_tools/dashboard').then((module) => ({ default: module['TeamToolsDashboard'] }))
)
const ApiInspector = lazy(() =>
    import('./team_tools/api_inspector').then((module) => ({ default: module['ApiInspector'] }))
)
const BanList = lazy(() => import('./team_tools/ban_list/ban_list'))
const BanListUsers = lazy(() => import('./team_tools/ban_list/users'))
const Docs = lazy(() => import('./team_tools/docs').then((module) => ({ default: module['DocsPage'] })))
const ClosedBeta = lazy(() => import('./team_tools/closed_beta'))
const ClosedBetaKeys = lazy(() =>
    import('./team_tools/closed_beta/keys').then((module) => ({ default: module['BetaKeys'] }))
)
const ClosedBetaRequests = lazy(() =>
    import('./team_tools/closed_beta/requests').then((module) => ({ default: module['BetaRequests'] }))
)
const ClosedBetaUsers = lazy(() =>
    import('./team_tools/closed_beta/users').then((module) => ({ default: module['BetaUsers'] }))
)
// Bank
const Bank = lazy(() => import('./team_tools/bank'))
const BankAccounts = lazy(() => import('./team_tools/bank/accounts'))
const BankBr1 = lazy(() => import('./team_tools/bank/br1'))
const BankAirdrop = lazy(() => import('./team_tools/bank/airdrop').then((module) => ({ default: module['Airdrop'] })))
const BankTransactions = lazy(() =>
    import('./team_tools/bank/transactions').then((module) => ({ default: module['Transactions'] }))
)
//Accounts
const Accounts = lazy(() => import('./team_tools/accounts'))
const Users = lazy(() => import('./team_tools/accounts/users'))
const User = lazy(() => import('./team_tools/accounts/user'))

const CollectionInfo = lazy(() => import('./routes/collection-info'))
const Collection = lazy(() => import('./routes/collection'))

const App = () => {
    return (
        <main className='flex flex-col w-full min-h-screen pt-16 md:pt-0'>
            <section className='relative flex-1 flex flex-col min-h-screen'>
                <div id='page-background' className='fixed inset-0 w-full max-w-[1920px] mx-auto'></div>
                <PageContextProvider>
                    <ScrollToTop />
                    <Menu />
                    <div id='modal-root'></div>
                    <Suspense
                        fallback={
                            <div className='min-h-screen h-full w-full flex flex-row items-center justify-center'>
                                <Spinner />
                            </div>
                        }
                    >
                        <AnchorScroll>
                            <section className='relative flex-1'>
                                <Routes>
                                    <Route path='/'>
                                        <Route index element={<LandingPage />} />
                                        <Route path='/*' element={<AuthRoutes />} />
                                        <Route
                                            path='dashboard'
                                            element={
                                                <RequireAuth>
                                                    <Dashboard />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path='account'
                                            element={
                                                <RequireAuth>
                                                    <Account />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path='wallet'
                                            element={
                                                <RequireAuth>
                                                    <WalletPage />
                                                </RequireAuth>
                                            }
                                        />
                                        {/* <Route
                                            path='data-tools'
                                            element={
                                                <RequireAuth>
                                                    <IsDroidHolder show403={true}>
                                                        <DataTools />
                                                    </IsDroidHolder>
                                                </RequireAuth>
                                            }
                                        >
                                            <Route index element={<DataToolsIndex />} />
                                            <Route path='servers' element={<DataToolsServers />} />
                                            <Route path='players' element={<DataToolsPlayers />} />
                                            <Route path='holders' element={<DataToolsHolders />} />
                                            <Route path='br1-race' element={<Br1RaceLeaderboard />} />
                                        </Route> */}
                                        <Route path='kill-race'>{...KillRaceRoutes()}</Route>
                                        <Route path='renting'>{...StakeRoutes}</Route>
                                        <Route path='loot-boxes'>{...LootboxRoutes}</Route>
                                        <Route
                                            path='download/*'
                                            element={
                                                <RequireAuth fallback='/signup'>
                                                    <DownloadRoutes />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route path='collection' element={<CollectionInfo />} />
                                        <Route path='collection/:name' element={<Collection />} />
                                        <Route
                                            path='team-tools'
                                            element={
                                                <RequireAuth>
                                                    <IsStaff show403={true}>
                                                        <TeamTools />
                                                    </IsStaff>
                                                </RequireAuth>
                                            }
                                        >
                                            <Route index element={<Navigate replace to='dashboard' />} />
                                            <Route path='dashboard' element={<TeamToolsDashboard />}>
                                                <Route index element={<Statistic />} />
                                                <Route path='br1-race' element={<Br1Race />} />
                                                <Route path='br1-treasure-hunt' element={<Br1TreasureHunt />} />
                                                <Route path='br1-ace-derby' element={<Br1AceDerby />} />
                                                <Route path='team-members' element={<TeamMembers />} />
                                                <Route path='server-control' element={<ServerControl />} />
                                            </Route>
                                            <Route
                                                path='api-inspector'
                                                element={
                                                    <IsDeveloper show403={true}>
                                                        <ApiInspector />
                                                    </IsDeveloper>
                                                }
                                            />
                                            <Route
                                                path='api-inspector/:name'
                                                element={
                                                    <IsDeveloper show403={true}>
                                                        <ApiInspector />
                                                    </IsDeveloper>
                                                }
                                            />
                                            <Route path='docs'>
                                                <Route path=':page' element={<Docs />} />
                                                <Route index element={<Navigate to='gameapi' replace />} />
                                            </Route>
                                            <Route
                                                path='closed-beta'
                                                element={
                                                    <IsMarketing show403={true}>
                                                        <ClosedBeta />
                                                    </IsMarketing>
                                                }
                                            >
                                                <Route path='keys' element={<ClosedBetaKeys />} />
                                                <Route path='requests' element={<ClosedBetaRequests />} />
                                                <Route path='users' element={<ClosedBetaUsers />} />
                                                <Route index element={<Navigate to='keys' replace />} />
                                            </Route>
                                            <Route
                                                path='bank'
                                                element={
                                                    <IsMarketing show403={true}>
                                                        <Bank />
                                                    </IsMarketing>
                                                }
                                            >
                                                <Route path='accounts' element={<BankAccounts />} />
                                                <Route path='br1' element={<BankBr1 />}>
                                                    <Route path='deposit' element={<BankDeposit />} />
                                                    <Route path='airdrop' element={<BankAirdrop />} />
                                                </Route>
                                                <Route path='transactions' element={<BankTransactions />} />
                                                <Route path=':user/transactions' element={<BankTransactions />} />
                                                <Route
                                                    path=':user/transactions/:currency'
                                                    element={<BankTransactions />}
                                                />
                                                <Route index element={<Navigate to='br1' replace />} />
                                                {...BankRoutes()}
                                            </Route>
                                            <Route
                                                path='accounts'
                                                element={
                                                    <IsMarketing show403={true}>
                                                        <Accounts />
                                                    </IsMarketing>
                                                }
                                            >
                                                <Route path='users' element={<Users />} />
                                                <Route path='users/:username' element={<User />} />
                                                <Route index element={<Navigate to='users' replace />} />
                                            </Route>
                                            <Route path='ban-list' element={<BanList />}>
                                                <Route path='users' element={<BanListUsers />} />
                                            </Route>
                                            <Route path='airdrop' element={<Airdrop />} />
                                        </Route>
                                    </Route>
                                    <Route path='*' element={<NotFound />} />
                                </Routes>
                            </section>
                        </AnchorScroll>
                        <ToastContainer position='bottom-right' hideProgressBar autoClose={3000} theme='dark' />
                    </Suspense>
                    <div className='sticky bg-[linear-gradient(90.01deg,#C91FFF_0%,#03CEFF_100%)] h-4 w-full'>
                        &nbsp;
                    </div>
                    <div className='sticky w-full bg-black bottom-0 place-self-end'>
                        <PageFooter />
                    </div>
                </PageContextProvider>
            </section>
        </main>
    )
}

const root = ReactDOM.createRoot(document.getElementById('app-root') as HTMLElement)

const queryClient = new QueryClient()

root.render(
    <QueryClientProvider client={queryClient}>
        <CsrfProvider>
            <AuthProvider>
                <BrowserRouter>
                    <ModalProvider>
                        <App />
                    </ModalProvider>
                </BrowserRouter>
            </AuthProvider>
        </CsrfProvider>
    </QueryClientProvider>
)
