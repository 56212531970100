import { Page } from '~/components/Page'
import { WalletContext } from '~/components/WalletContext'
// import { WalletSelector } from '~/features/deposit/solana_usdc/components/WalletSelector'
import { LootboxInfo } from '../components/LootboxInfo'
import { LootboxList } from '../components/LootboxList'
import { LootboxProvider } from '../providers/LootboxProvider'

export const LootboxMain = () => {
    return (
        <Page title='Loot-boxes'>
            <div className='flex flex-col'>
                <LootboxInfo />
                <WalletContext>
                    <LootboxProvider>
                        <LootboxList />
                    </LootboxProvider>
                </WalletContext>
            </div>
        </Page>
    )
}
