import { createContext, useState } from 'react'
import { Currency } from '../types'

interface CurrencyContextType {
    currency: Currency
    setCurrency: (currency: Currency) => void
}

export const CurrencyContext = createContext<CurrencyContextType>({
    currency: undefined,
    setCurrency: () => {},
})

export const CurrencyProvider = ({ children }) => {
    const [currency, setCurrency] = useState<Currency>(undefined)
    return <CurrencyContext.Provider value={{ currency, setCurrency }}>{children}</CurrencyContext.Provider>
}
