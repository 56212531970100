import { GameSession } from './GameSession'

export const GameSessionList = ({
    sessions,
    showUsername = false,
}: {
    sessions: GameSession[]
    showUsername?: boolean
}) => {
    return (
        <table className='border-separate border-spacing-4'>
            <thead className='text-white/50'>
                <tr className='text-left'>
                    <th></th>
                    {showUsername && <th>Player</th>}

                    <th></th>
                    <th className=''>Server</th>
                    <th className=''>Spawned</th>
                    <th className=''>Ip</th>
                    <th className=''>Duration</th>
                    <th className=''>Fee</th>
                    <th className=''>Kills</th>
                    <th className=''>Earnings</th>
                    <th>End</th>
                </tr>
            </thead>
            <tbody className='text-xs'>
                {sessions?.map((session, i) => (
                    <GameSession key={i} rowNumber={i} session={session} showUsername={showUsername} />
                ))}
            </tbody>
        </table>
    )
}
