import { getCookie } from './utils/cookie'

export const csrftoken = (): string => {
    return getCookie('csrftoken') ?? ''
}

type SignupType = {
    username: string
    email: string
    password1: string
    password2: string
}

type SignupValidationError = {
    message: string
    code: string
}

export type SignupErrors = {
    username: SignupValidationError[]
    email: SignupValidationError[]
    password1: SignupValidationError[]
    password2: SignupValidationError[]
}

export type SignupOk = {
    signup: boolean
}

type Wallet = {
    pubkey: string
    name: string | null
}

export type SocialConnect = {
    type: number
    social_username: string
    social_id: number
}

export type AuthUserType = {
    is_auth: boolean
    is_staff: boolean
    is_developer: boolean
    is_marketing: boolean
    is_executive: boolean
    is_holder: boolean
    is_ape_holder: boolean
    is_droid_holder: boolean
    username: string
    email: string
    email_confirmed: boolean
    wallets: Array<Wallet>
    closed_beta_key: string
    closed_beta_key_requested: boolean
    roles: Array<string>
    ape_count: number
    droid_count: number
    twitter_connected?: boolean
    discord_connected?: boolean
    beta_applicant: boolean
    beta_request_id: number
    beta_tester: boolean
    beta_id: number
    beta_key: string
}

export type PasswordChangeType = {
    old_password?: string
    new_password1: string
    new_password2: string
    reset_token?: string
}

type AddWalletType = {
    wallet: string
    signature: string
}

export type SocialConnectProvider = {
    name: string
    type: number
    link: string
    unlink: string
}

export type Referral = {
    url: string
    visit_count: number
    signup_count: number
}

class AccountApi {
    checkAuth = async (): Promise<AuthUserType> => {
        const response = await fetch(`/api/account/auth`)
        return await response.json()
    }

    nonce = async (wallet, walletName, hardware = false): Promise<string> => {
        const response = await fetch('/api/account/nonce', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ wallet, walletName, hardware }),
        })
        return await response.text()
    }

    signup = async ({ username, email, password1, password2 }: SignupType): Promise<SignupOk | SignupErrors> => {
        const form = new FormData()

        form.append('username', username)
        form.append('email', email)
        form.append('password1', password1)
        form.append('password2', password2)
        form.append('lang', navigator.language)
        form.append('time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone)

        const response = await fetch('/api/account/signup', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: form,
        })
        if (response.ok) {
            /* @ts-ignore */
            window.gtag('event', 'sign_up', {
                event_category: 'account',
                event_label: username,
                user_id: username,
            })
            return await response.json()
        } else throw await response.json()
    }

    login = async ({ username, password }: { username: string; password: string }): Promise<boolean> => {
        const form = new FormData()

        form.append('username', username)
        form.append('password', password)
        form.append('lang', navigator.language)
        form.append('time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone)

        const response = await fetch('/api/account/auth', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: form,
        })

        if (response.ok) {
            /* @ts-ignore */
            window.gtag('event', 'login', {
                event_category: 'account',
                event_label: username,
                user_id: username,
            })
            return await response.json()
        } else throw await response.json()
    }

    wallets = async (): Promise<string[]> => {
        const response = await fetch('/api/account/wallets')
        return await response.json()
    }

    addWallet = async ({ wallet, signature }: AddWalletType): Promise<boolean> => {
        const response = await fetch('/api/account/wallet', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({
                wallet,
                signature: signature,
            }),
        })

        return response.ok
    }

    removeWallet = async (wallet: string): Promise<boolean> => {
        const response = await fetch('/api/account/wallet', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ wallet }),
        })

        return response.ok
    }

    logout = async (): Promise<boolean> => {
        const response = await fetch('/api/account/auth', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
        })

        return response.ok
    }

    passwordResetRequest = async ({ email }: { email: string }): Promise<boolean> => {
        const form = new FormData()
        form.append('email', email)

        const response = await fetch('/api/account/password/reset-request', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: form,
        })

        return response.ok
    }

    changePassword = async ({
        old_password,
        new_password1,
        new_password2,
        reset_token,
    }: PasswordChangeType): Promise<any> => {
        const form = new FormData()

        if (old_password) form.append('old_password', old_password)
        form.append('new_password1', new_password1)
        form.append('new_password2', new_password2)
        if (reset_token) form.append('reset_token', reset_token)

        const response = await fetch('/api/account/password/change', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: form,
        })
        if (response.ok) return true
        else throw await response.json()
    }

    async socialConnectProviders(source = undefined): Promise<Array<SocialConnectProvider>> {
        const response = await fetch(`/api/account/social/connect/providers${source ? `?source=${source}` : ''}`)
        return await response.json()
    }

    async applyBeta(data): Promise<{ id: number }> {
        const response = await fetch('/api/account/closed_beta/apply', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify(data),
        })

        if (response.ok) return response.json()
        else throw await response.json()
    }

    async checkBetaKey(key: string): Promise<{ message: string }> {
        const response = await fetch('/api/account/closed_beta/key', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ key }),
        })

        if (response.ok) return response.json()
        else throw await response.json()
    }

    async referral(): Promise<Referral> {
        const response = await fetch('/api/account/referral')
        return await response.json()
    }
}

// Collection

export type CollectionInfo = {
    name: string
    days_since_mint: number
    description: string
    floor_price: number
    img: string
    mint_date: string
    mint_price: number
    roi: number
    supply: number
}

export type Trait = {
    type: string
    value: string
}

export type Token = {
    mint: string
    name: string
    is_legendary: boolean
    is_holder: boolean
    holder: string
    holder_username: string
    holder_wallet: string
    rank: number
    price?: number
    operative_type: string
    operative_id: number
    traits: Trait[]
    image_url: string
    animation_url: string
    model_url: string
    holder_discord: string
    holder_twitter: string
    holder_roles: Array<string>
}

export type TokenSearchResult = {
    token: {
        mint: string
        operative_id: number
    }
}

export type HolderSearchResult = {
    holder: {
        wallet?: string
        username?: string
    }
}

export type Holder = {
    tokens: Array<Token>
    wallets: Array<string>
    username: string | null
    discord: string | null
    twitter: string | null
    query_type: string
    roles: Array<string>
}

type CollectionApiCache = {
    traits: Record<string, Trait[]> | undefined
}

class CollectionApi {
    static cache: CollectionApiCache = {
        traits: undefined,
    }

    async info(): Promise<CollectionInfo[]> {
        const response = await fetch(`/api/collection/info`)
        return await response.json()
    }

    async infoByName(name): Promise<CollectionInfo> {
        const response = await fetch(`/api/collection/info/${name}`)
        return await response.json()
    }

    async traits(): Promise<Record<string, Trait[]>> {
        if (CollectionApi.cache.traits !== undefined) return CollectionApi.cache.traits
        else {
            const response = await fetch(`/api/collection/traits`)
            const traits = await response.json()
            CollectionApi.cache.traits = traits
            return traits
        }
    }

    async tokens(collectionName): Promise<Token[]> {
        const response = await fetch(`/api/collection/${collectionName}`)
        return await response.json()
    }

    async userTokens(): Promise<Token[]> {
        const response = await fetch(`/api/user/collection`)
        return await response.json()
    }

    async token(id_or_mint): Promise<Token> {
        const response = await fetch(`/api/collection/token/${id_or_mint}`)
        return await response.json()
    }

    async search(tokenOrHolder: string): Promise<TokenSearchResult | HolderSearchResult | undefined> {
        const resposne = await fetch(`/api/search?q=${encodeURIComponent(tokenOrHolder)}`)
        if (resposne.ok) return await resposne.json()
    }

    async holder(identity: string): Promise<Holder> {
        const response = await fetch(`/api/collection/holder/${identity}`)
        if (response.ok) return await response.json()
    }
}

class DashboardApi {
    async operatives(): Promise<Token[]> {
        const response = await fetch(`/api/dashboard/operatives`)
        const result = await response.json()
        return result.tokens ?? []
    }
}

// Data Tools

export type HolderSummaryType = {
    apes_holders: number
    apes_supply: number
    apes_tfv: number
    droids_holders: number
    droids_supply: number
    droids_tfv: number
    total_holders: number
    total_operatives: number
    total_tfv: number
}

export type HolderType = {
    apes: number
    droids: number
    is_holder: boolean
    legendary_apes: number
    legendary_droids: number
    holder: string
    wallets: Array<string>
    discord: string | null
    twitter: string | null
    tfv: number
    tfv_usd: number
    total: number
    updated: string
    rank: number
    roles: Array<string>
}

class DataToolsApi {
    async holderSummary(): Promise<HolderSummaryType> {
        const response = await fetch('/api/data-tools/holders-summary')
        return await response.json()
    }

    async holders(): Promise<HolderType[]> {
        const response = await fetch('/api/data-tools/holders')
        return await response.json()
    }
}

export default {
    account: new AccountApi(),
    collection: new CollectionApi(),
    dashboard: new DashboardApi(),
    datatools: new DataToolsApi(),
}
