import { csrftoken } from './_shared'

export class RaceGameAPIError extends Error {
    type: string
    constructor(message, type = undefined) {
        super(message || 'RaceGameAPIError')
        this.name = 'RaceGameAPIError'
        this.type = type
    }
}

export type QueueDetailedInfo = {
    name: string
    is_open: boolean
    password: string
    requirements: Array<string>
    participants: number
}

export type QueueInfo = {
    name: string
    is_open: boolean
    password: string
    requirements: Array<string>
    missing_requirements: Array<string>
    participants: number
    is_participant: boolean
    has_password: boolean
}

export type QueueAddUsersResult = {
    added: number
    queue: string
}

export type QueueRemoveUsersResult = {
    deleted: number
    queue: string
}

export type QueueIsParticipant = {
    queue: string
    is_participant: boolean
}

export type Statistic = {
    race_total: number
    prize_sum: number
    participants_total: number
    participants_unique: number
}

export type History = {
    id: number,
    timestamp: string,
    participants: number,
    prize: number
}


export class RaceGameApi {

    static async queue(queue:string, action: 'add_users', params?:object): Promise<QueueAddUsersResult>
    static async queue(queue:string, action: 'remove_users', params?:object): Promise<QueueRemoveUsersResult>
    static async queue(queue:string, action: 'join' | 'info', params?:object): Promise<QueueInfo>
    static async queue(queue:string, action: 'detailed_info' | 'configure' | 'open' | 'close', params?:object): Promise<QueueDetailedInfo>
    static async queue(queue:string, action:string, params:object = {}): Promise<QueueDetailedInfo|QueueAddUsersResult|QueueRemoveUsersResult|QueueIsParticipant> {
        const response = await fetch('/api/race/queue', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ queue, action, ...params }),
        })
        if (!response.ok) {
            const data = await response.json()
            throw new RaceGameAPIError(data?.error)
        }
        else return response.json()
    }

    static async statistic():Promise<Statistic> {
        const response = await fetch('/api/race/statistic')
        if (!response.ok) throw new RaceGameAPIError("Can't get statistic", 'statistic')
        else return response.json()
    }

    static async history():Promise<Array<History>> {
        const response = await fetch('/api/race/history')
        if (!response.ok) throw new RaceGameAPIError("Can't get race history", 'history')
        else return response.json()
    }
}
