import React, { useState } from 'react'
import { useLogin } from '../api'

interface ILoginContext {
    username: string
    setUsername: React.Dispatch<React.SetStateAction<string>>
    usernameError: string
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    passwordError: string
    commonError: string
    login: () => void
}

const initialContext: ILoginContext = {
    username: undefined,
    setUsername: () => {},
    usernameError: undefined,
    password: undefined,
    setPassword: () => {},
    passwordError: undefined,
    commonError: undefined,
    login: async () => {},
}

export const LoginContext = React.createContext<ILoginContext>(initialContext)

export const LoginProvider = ({ children }) => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const login = useLogin()

    return (
        <LoginContext.Provider
            value={{
                username,
                setUsername,
                password,
                setPassword,
                usernameError: login.error?.username?.[0].message,
                passwordError: login.error?.password?.[0].message,
                commonError: login.error?.__all__?.[0].message,
                login: () => login.mutate({ username, password }),
            }}
        >
            {children}
        </LoginContext.Provider>
    )
}
