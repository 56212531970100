import { faCrown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'

export const NftGuildsCount = () => {
    const [guilds, setGuilds] = useState<Record<string, [number, number]>>({})

    const fields = {
        gold: {
            note: '10 Apes or 20 Droids',
            color: 'rgb(238, 184, 2)',
            as: <NavLink to='/team-tools/accounts/users?guild__in=Gold+Guild' className='hover:outline' />,
        },
        platinum: {
            note: '25 Apes or 50 Droids',
            color: 'rgb(182, 223, 223)',
            as: <NavLink to='/team-tools/accounts/users?guild__in=Platinum+Guild' className='hover:outline' />,
        },
        diamond: {
            note: '50 Apes or 100 Droids',
            color: 'rgb(162, 126, 224)',
            as: <NavLink to='/team-tools/accounts/users?guild__in=Diamond+Guild' className='hover:outline' />,
        },
    }

    const getData = () => {
        jsonrpc('statistic.nft.guilds_count').then((res) => {
            setGuilds(res.result || {})
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Box className='w-full mx-auto'>
            <Header>Guilds</Header>
            <small className='text-white/50'>
                The number in brackets limits the selection to guilds that have created an account.
            </small>
            <section className='grid grid-cols-3 w-full'>
                {Object.entries(fields).map(([name, { note, color, as }], i) => {
                    const [all, beta] = guilds[name] || []
                    return (
                        <Box key={name} className='items-stretch text-center'>
                            <Box className='items-stretch p-0'>
                                <Stat.Gray as={as} loading={guilds[name] === undefined}>
                                    <StatLabel className='flex flex-row flex-wrap items-center justify-center gap-2'>
                                        <Box
                                            className='rounded border border-black/50 px-2'
                                            style={{ backgroundColor: color }}
                                        >
                                            <FontAwesomeIcon icon={faCrown} className='text-black/50' />
                                        </Box>
                                        <span className='first-letter:uppercase text-amber-500 '>
                                            {name.replaceAll('_', ' ')}
                                        </span>
                                    </StatLabel>
                                    <StatNumber>
                                        {all} ({beta})
                                    </StatNumber>
                                </Stat.Gray>
                                <small className='text-white/50'>{note}</small>
                            </Box>
                        </Box>
                    )
                })}
            </section>
        </Box>
    )
}
