export default {
    home_bg: new URL('../assets/home_bg.png?as=webp&width=1920', import.meta.url).toString(),
    home_bg_placeholder: new URL('../assets/home_bg.png?as=webp&width=96', import.meta.url).toString(),
    // br1_skull: new URL('../assets/br1_skull.png', import.meta.url).toString(),
    br1_skull_small: new URL('../assets/br1_skull.png?width=128', import.meta.url).toString(),
    // br1_logo: new URL('../assets/br1_logo.png', import.meta.url).toString(),
    // monkey: new URL('../assets/monkey.png', import.meta.url).toString(),
    // monkey_2: new URL('../assets/monkey_2.png?as=webp&width=1024', import.meta.url).toString(),
    magiceden: new URL('../assets/magic_eden.png?as=webp&height=64', import.meta.url).toString(),
    fractal: new URL('../assets/fractal.png?as=webp&height=64', import.meta.url).toString(),
    cardinal_logo: new URL('../assets/cardinal_logo.png?as=webp&height=192', import.meta.url).toString(),
    ludex_logo: new URL('../assets/ludex_logo.png?as=webp&height=192', import.meta.url).toString(),
    fractal_logo_svg: new URL('../assets/fractal_logo.svg', import.meta.url).toString(),
    magiceden_logo_svg: new URL('../assets/magiceden_logo.svg', import.meta.url).toString(),
    opensea_logo: new URL('../assets/opensea_logo.png?as=webp&width=128', import.meta.url).toString(),
    monkesocial_logo_svg: new URL('../assets/monkesocial_logo.svg', import.meta.url).toString(),
    goatventures_logo: new URL('../assets/goat_ventures_logo.png?as=webp&height=192', import.meta.url).toString(),
    // sixth_man_logo: new URL('../assets/6th_man_logo.png?as=webp&height=192', import.meta.url).toString(),
    investor: new URL('../assets/investor.png?as=webp&height=192', import.meta.url).toString(),
    solana_ventures_logo: new URL('../assets/solana_ventures_logo.png?as=webp&height=192', import.meta.url).toString(),
    bigbrain_holdings_logo: new URL(
        '../assets/bigbrain_holdings_logo.png?as=webp&height=192',
        import.meta.url
    ).toString(),
    krafton_logo: new URL('../assets/krafton.png?as=webp', import.meta.url).toString(),
    sino_logo: new URL('../assets/sino.png?as=webp', import.meta.url).toString(),
    shima_logo: new URL('../assets/shima.png?as=webp', import.meta.url).toString(),

    forbes_logo: new URL('../assets/forbes_logo.png?as=webp', import.meta.url).toString(),
    forbes_logo_placeholder: new URL('../assets/forbes_logo.png?as=webp&width=96', import.meta.url).toString(),
    yahoo_logo: new URL('../assets/yahoo_logo.png?as=webp', import.meta.url).toString(),
    yahoo_logo_placeholder: new URL('../assets/yahoo_logo.png?as=webp&width=96', import.meta.url).toString(),
    betakit_logo: new URL('../assets/betakit_logo.png?as=webp', import.meta.url).toString(),
    betakit_logo_placeholder: new URL('../assets/betakit_logo.png?as=webp&width=96', import.meta.url).toString(),
    bou_logo: new URL('../assets/bou_logo.png?as=webp', import.meta.url).toString(),
    bou_logo_placeholder: new URL('../assets/bou_logo.png?as=webp&width=96', import.meta.url).toString(),
    venturebeat_logo: new URL('../assets/venturebeat_logo.png?as=webp', import.meta.url).toString(),
    venturebeat_logo_placeholder: new URL(
        '../assets/venturebeat_logo.png?as=webp&width=96',
        import.meta.url
    ).toString(),
    techcrunch_logo: new URL('../assets/techcrunch_logo.png?as=webp', import.meta.url).toString(),

    discord_icon: new URL('../assets/discord.png?as=webp&width=64', import.meta.url).toString(),
    twitter_icon: new URL('../assets/twitter.png?as=webp&width=64', import.meta.url).toString(),
    tiktok_icon: new URL('../assets/tiktok.png?as=webp&width=64', import.meta.url).toString(),
    youtube_icon: new URL('../assets/youtube.png?as=webp&width=64', import.meta.url).toString(),
    user_profile_icon: new URL('../assets/user_profile.png?as=webp&width=64', import.meta.url).toString(),
    logout_icon: new URL('../assets/logout_icon.png?as=webp&width=64', import.meta.url).toString(),
    swap_icon: new URL('../assets/swap_icon.svg', import.meta.url).toString(),
    // solana_icon: new URL('../img/solana-sol-logo.svg', import.meta.url).toString(),
    solanafm_icon: new URL('../assets/solanafm_icon.svg', import.meta.url).toString(),
    solscan_icon: new URL('../assets/solscan_icon.png?as=webp&w=64', import.meta.url).toString(),
    solanaexplorer_icon: new URL('../assets/solana_explorer_icon_filled.webp?w=64', import.meta.url).toString(),
    magiceden_icon: new URL('../assets/me_icon.png?as=webp&width=64', import.meta.url).toString(),
    opensea_icon: new URL('../assets/opensea_icon.svg', import.meta.url).toString(),
    fractal_icon: new URL('../assets/fractal_icon.ico?as=webp&width=64', import.meta.url).toString(),
    // edit_icon: new URL('../assets/edit_icon.png?as=webp&width=64', import.meta.url).toString(),
    wallet_icon: new URL('../assets/wallet_icon.png?as=webp&width=64', import.meta.url).toString(),
    instagram_icon: new URL('../assets/instagram.png?as=webp&width=64', import.meta.url).toString(),
    key_icon: new URL('../assets/key_icon.png?as=webp', import.meta.url).toString(),

    sol_symbol: new URL('../assets/sol_symbol.png?as=webp&width=64', import.meta.url).toString(),
    star: new URL('../assets/star.png?as=webp&width=64', import.meta.url).toString(),

    // screenshot_1: new URL('../assets/screenshot_1.png?as=webp', import.meta.url).toString(),
    droid_2: new URL('../assets/droid_2.png?as=webp&height=600', import.meta.url).toString(),
    ape_2: new URL('../assets/ape_2.png?as=webp&height=800', import.meta.url).toString(),

    map_heat_zones: new URL('../assets/map_heat_zones.png?as=webp&width=1280', import.meta.url).toString(),

    // droid_creation_frame: new URL('../assets/droid_creation_placeholder.jpeg?as=webp', import.meta.url).toString(),
    // video: {
    //     droid_creation_720: new URL('../assets/video/droid_creation_720.mp4', import.meta.url).toString(),
    // },

    droid_404: new URL('../assets/droid_404.png?as=webp&width=1280', import.meta.url).toString(),

    review_avatar: new URL('../assets/review_avatar.png?as=webp&width=256', import.meta.url).toString(),
    review_avatar_placeholder: new URL('../assets/review_avatar.png?as=webp&width=64', import.meta.url).toString(),

    placeholders: {
        br1_logo: new URL('../assets/br1_logo.png?width=80', import.meta.url).toString(),
        // monkey: new URL('../assets/monkey.png?width=80', import.meta.url).toString(),
        // monkey_2: new URL('../assets/monkey_2.png?width=80', import.meta.url).toString(),
        magiceden: new URL('../assets/magic_eden.png??as=webp&height=16', import.meta.url).toString(),
        fractal: new URL('../assets/fractal.png?as=webp&height=16', import.meta.url).toString(),
        cardinal_logo: new URL('../assets/cardinal_logo.png?as=webp&width=64', import.meta.url).toString(),
        ludex_logo: new URL('../assets/ludex_logo.png?as=webp&width=64', import.meta.url).toString(),
        goatventures_logo: new URL('../assets/goat_ventures_logo.png?as=webp&width=64', import.meta.url).toString(),
        // sixth_man_logo: new URL('../assets/6th_man_logo.png?as=webp&width=64', import.meta.url).toString(),
        investor: new URL('../assets/investor.png?as=webp&width=64', import.meta.url).toString(),
        solana_ventures_logo: new URL(
            '../assets/solana_ventures_logo.png?as=webp&width=64',
            import.meta.url
        ).toString(),
        bigbrain_holdings_logo: new URL(
            '../assets/bigbrain_holdings_logo.png?as=webp&width=64',
            import.meta.url
        ).toString(),

        // screenshot_1: new URL('../assets/screenshot_1.png?width=80', import.meta.url).toString(),
        droid_2: new URL('../assets/droid_2.png?as=webp&width=80', import.meta.url).toString(),
        ape_2: new URL('../assets/ape_2.png?as=webp&width=80', import.meta.url).toString(),

        map_heat_zones: new URL('../assets/map_heat_zones.png?as=webp&width=80', import.meta.url).toString(),

        // droid_creation_frame: new URL('../assets/droid_creation_placeholder.jpeg?as=png&width=80', import.meta.url).toString(),
        droid_404: new URL('../assets/droid_404.png?as=webp&width=80', import.meta.url).toString(),
    },

    download_page: {
        // download_logo: new URL('../assets/download_page/download_logo.png?as=webp&width=1920', import.meta.url).toString(),
        // download_logo_placeholder: new URL('../assets/download_page/download_logo.png?as=webp&width=96', import.meta.url).toString(),
        droid_1: new URL('../assets/download_page/droid_1.png?as=webp&width=1920', import.meta.url).toString(),
        droid_1_placeholder: new URL(
            '../assets/download_page/droid_1.png?as=webp&width=96',
            import.meta.url
        ).toString(),
        droid_2: new URL('../assets/download_page/droid_2.jpeg?as=webp&width=1920', import.meta.url).toString(),
        droid_2_placeholder: new URL(
            '../assets/download_page/droid_2.jpeg?as=webp&width=96',
            import.meta.url
        ).toString(),
        droid_3: new URL('../assets/download_page/droid_3.jpeg?as=webp&width=1920', import.meta.url).toString(),
        droid_3_placeholder: new URL(
            '../assets/download_page/droid_3.jpeg?as=webp&width=96',
            import.meta.url
        ).toString(),
        droid_4: new URL('../assets/download_page/droid_4.jpeg?as=webp&width=1920', import.meta.url).toString(),
        droid_4_placeholder: new URL(
            '../assets/download_page/droid_4.jpeg?as=webp&width=96',
            import.meta.url
        ).toString(),
        droid_5: new URL('../assets/download_page/droid_5.jpeg?as=webp&width=1920', import.meta.url).toString(),
        droid_5_placeholder: new URL(
            '../assets/download_page/droid_5.jpeg?as=webp&width=96',
            import.meta.url
        ).toString(),
        droid_6: new URL('../assets/download_page/droid_6.png?as=webp&width=1920', import.meta.url).toString(),
        droid_6_placeholder: new URL(
            '../assets/download_page/droid_6.png?as=webp&width=96',
            import.meta.url
        ).toString(),
        droid_6_head: new URL(
            '../assets/download_page/droid_6_head.png?as=webp&width=1920',
            import.meta.url
        ).toString(),
        droid_6_head_placeholder: new URL(
            '../assets/download_page/droid_6_head.png?as=webp&width=96',
            import.meta.url
        ).toString(),
        ape: new URL('../assets/download_page/ape.png?as=webp&width=1920', import.meta.url).toString(),
        ape_placeholder: new URL('../assets/download_page/ape.png?as=webp&width=96', import.meta.url).toString(),
        weapons: new URL('../assets/download_page/weapons.jpeg?as=webp&width=1920', import.meta.url).toString(),
        weapons_placeholder: new URL(
            '../assets/download_page/weapons.jpeg?as=webp&width=96',
            import.meta.url
        ).toString(),
        microsoft_logo: new URL('../assets/microsoft_logo.png?as=webp&width=800', import.meta.url).toString(),
        microsoft_logo_placeholder: new URL(
            '../assets/microsoft_logo.png?as=webp&width=80',
            import.meta.url
        ).toString(),
        apple_logo: new URL('../assets/apple_logo.png?as=webp&width=800', import.meta.url).toString(),
        apple_logo_placeholder: new URL('../assets/apple_logo.png?as=webp&width=80', import.meta.url).toString(),
    },

    collection_info: {
        // apes_info: new URL('../assets/collection_page/ape_info.png?as=webp&width=1920', import.meta.url).toString(),
        // apes_info_placeholder: new URL('../assets/collection_page/ape_info.png?as=webp&width=96', import.meta.url).toString(),
        // droids_info: new URL('../assets/collection_page/droid_info.png?as=webp&width=1920', import.meta.url).toString(),
        // droids_info_placeholder: new URL('../assets/collection_page/droid_info.png?as=webp&width=96', import.meta.url).toString(),
        apes: new URL('../assets/collection_page/ape_1.png?as=webp&width=1920', import.meta.url).toString(),
        apes_800: new URL('../assets/collection_page/ape_1.jpeg?as=webp&width=800', import.meta.url).toString(),
        apes_placeholder: new URL('../assets/collection_page/ape_1.jpeg?as=webp&width=96', import.meta.url).toString(),
        droids: new URL('../assets/collection_page/droid_1.png?as=webp&width=1920', import.meta.url).toString(),
        droids_800: new URL('../assets/collection_page/droid_1.jpeg?as=webp&width=800', import.meta.url).toString(),
        droids_placeholder: new URL(
            '../assets/collection_page/droid_1.jpeg?as=webp&width=96',
            import.meta.url
        ).toString(),
        ape: new URL('../assets/collection_page/ape_2.jpeg?as=webp&width=1920', import.meta.url).toString(),
        ape_placeholder: new URL('../assets/collection_page/ape_2.jpeg?as=webp&width=96', import.meta.url).toString(),
        droid: new URL('../assets/collection_page/droid_2.png?as=webp&width=1920', import.meta.url).toString(),
        droid_placeholder: new URL(
            '../assets/collection_page/droid_2.png?as=webp&width=96',
            import.meta.url
        ).toString(),
        humans: new URL('../assets/collection_page/humans.png?as=webp&width=1920', import.meta.url).toString(),
        humans_placeholder: new URL(
            '../assets/collection_page/humans.png?as=webp&width=96',
            import.meta.url
        ).toString(),

        rarity_icon: new URL('../assets/collection_page/rarity_icon.png?as=webp&width=32', import.meta.url).toString(),
        hat_icon: new URL('../assets/collection_page/hat_icon.png?as=webp&width=32', import.meta.url).toString(),
        bodypattern_icon: new URL('../assets/collection_page/bodypattern_icon.svg', import.meta.url).toString(),
        colordrop_icon: new URL(
            '../assets/collection_page/colordrop_icon.png?as=webp&width=32',
            import.meta.url
        ).toString(),
        colordrop_add_icon: new URL(
            '../assets/collection_page/colordrop_add_icon.png?as=webp&width=32',
            import.meta.url
        ).toString(),
        tattoo_icon: new URL('../assets/collection_page/tattoo_icon.png?as=webp&width=32', import.meta.url).toString(),
        shorts_icon: new URL('../assets/collection_page/shorts_icon.png?as=webp&width=32', import.meta.url).toString(),

        holder_header: new URL(
            '../assets/collection_page/holder_header.png?as=webp&width=1920',
            import.meta.url
        ).toString(),
        holder_header_placeholder: new URL(
            '../assets/collection_page/holder_header.png?as=webp&width=96',
            import.meta.url
        ).toString(),
    },

    forbidden: {
        ape: new URL('../assets/403/ape.png?as=webp&width=1920', import.meta.url).toString(),
        ape_placeholder: new URL('../assets/403/ape.png?as=webp&width=96', import.meta.url).toString(),
        droid: new URL('../assets/403/droid.png?as=webp&width=1920', import.meta.url).toString(),
        droid_placeholder: new URL('../assets/403/droid.png?as=webp&width=96', import.meta.url).toString(),
    },

    auth: {
        signup_bg: new URL('../assets/auth/signup_bg.png?as=webp&width=1920', import.meta.url).toString(),
        signup_bg_placeholder: new URL('../assets/auth/signup_bg.png?as=webp&width=96', import.meta.url).toString(),
        email_confirm_bg: new URL('../assets/auth/email_confirm_bg.png?as=webp&width=1920', import.meta.url).toString(),
        email_confirm_bg_placeholder: new URL(
            '../assets/auth/email_confirm_bg.png?as=webp&width=96',
            import.meta.url
        ).toString(),
        account_bg: new URL('../assets/auth/account_bg.png?as=webp&width=1920', import.meta.url).toString(),
        account_bg_placeholder: new URL('../assets/auth/account_bg.png?as=webp&width=96', import.meta.url).toString(),
    },

    dashboard_bg: new URL('../assets/dashboard_bg.png?as=webp&width=1920', import.meta.url).toString(),
    dashboard_bg_placeholder: new URL('../assets/dashboard_bg.png?as=webp&width=96', import.meta.url).toString(),

    datatools_droid: new URL('../assets/datatools_droid.png?as=webp', import.meta.url).toString(),
    datatools_droid_placeholder: new URL('../assets/datatools_droid.png?as=webp&width=96', import.meta.url).toString(),
}
