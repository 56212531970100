import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { createContext, useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Icons } from '../assets/icons'
import { useStakeEpoch } from '../hooks/useStakeEpoch'
import { BorderGradient } from './BorderGradient'
import { ASSET_URL, StakeImage, StakeProgress, StakeRate } from './StakeItem'

const unionArrow = new URL('../assets/union-arrow.svg', import.meta.url).href
const lootBoxImage = new URL('../assets/lootbox.png', import.meta.url).href
const randomWeapon = new URL('../assets/random-weapon.png', import.meta.url).href

type StakeInfoType = {
    Icon: React.ReactNode
    title: string
    rate?: number
    pfp?: string
    type: string
    disabled?: boolean
}

const stakeInfo = [
    {
        Icon: <Icons.Ape fill='url(#svg_legendarygradient)' />,
        title: 'LEGENDARY APE EXAMPLE',
        rate: 120,
        pfp: ASSET_URL('ape', 977),
        type: 'ape',
    },
    {
        Icon: <Icons.Ape fill='url(#svg_commongradient)' />,
        title: 'COMMON APE EXAMPLE',
        rate: 60,
        pfp: ASSET_URL('ape', 1823),
        type: 'ape',
    },
    {
        Icon: <Icons.Droid fill='url(#svg_legendarygradient)' />,
        title: 'LEGENDARY DROID EXAMPLE',
        rate: 90,
        pfp: ASSET_URL('droid', 6735),
        type: 'droid',
    },
    {
        Icon: <Icons.Droid fill='url(#svg_commongradient)' />,
        title: 'COMMON DROID EXAMPLE',
        rate: 30,
        pfp: ASSET_URL('droid', 2759),
        type: 'droid',
    },
    {
        Icon: <Icons.Lootbox />,
        title: 'LOOT BOX EXAMPLE',
        type: 'lootbox',
        rate: 50,
        pfp: ASSET_URL('lootbox', 1),
    },
    {
        Icon: <Icons.Weapon fill='url(#svg_commongradient)' />,
        title: 'COMMON WEAPON EXAMPLE',
        type: 'weapon',
        rate: 10,
        pfp: ASSET_URL('weapon', 0),
    },
    {
        Icon: <Icons.Weapon fill='url(#svg_weapon_legendary_gradient)' />,
        title: 'LEGENDARY WEAPON EXAMPLE',
        type: 'weapon',
        rate: 45,
        pfp: ASSET_URL('weapon', 7),
    },
    {
        Icon: <Icons.Weapon fill='url(#svg_legendarygradient)' />,
        title: 'ULTRA WEAPON EXAMPLE',
        type: 'weapon',
        rate: 105,
        pfp: ASSET_URL('weapon', 115),
    },
]

type OperativeContextType = {
    items: StakeInfoType[]
    setItems: React.Dispatch<React.SetStateAction<{}[]>>
    selected: number
    setSelected: React.Dispatch<React.SetStateAction<number>>
}
const OperativeContext = createContext<OperativeContextType>(undefined)

const OperativeProvider = ({ children }) => {
    const [items, setItems] = useState(stakeInfo)
    const [selected, setSelected] = useState(0)

    return (
        <OperativeContext.Provider value={{ items, setItems, selected, setSelected }}>
            {children}
        </OperativeContext.Provider>
    )
}

export const StakeExample = () => {
    const { is_started } = useStakeEpoch()
    return (
        <BorderGradient>
            <div className='flex flex-col gap-4 p-6 max-w-md'>
                <OperativeProvider>
                    <h1 className='flex flex-row items-center gap-4 font-bold'>
                        <span className='h-6 w-6 bg-white text-black rounded-full flex flex-row items-center justify-center'>
                            ?
                        </span>
                        <span className='text-base'>HOW DOES RENTING WORK?</span>
                    </h1>
                    <div className='relative h-10'>
                        <div className='absolute z-50 left-0 right-0'>
                            <Dropdown />
                        </div>
                    </div>
                    <StakeItemExample />
                    {/* <h2 className='text-base md:text-lg font-bold'>
                        {is_started ? 'CURRENT' : 'NEXT'} CLAIMABLE ITEM:
                    </h2>
                    <WeaponExample /> */}
                    <h3 className='text-white/50 font-bold'>CLAIM REQUIREMENTS:</h3>
                    <div className='text-sm'>
                        Claiming 1000 BR1 Points requires renting{' '}
                        <span className=' whitespace-nowrap'>(1) BR1: Ape & (1) BR1: Droid until 100%</span>
                    </div>
                    <RewardRequirements />
                </OperativeProvider>
            </div>
        </BorderGradient>
    )
}

const Dropdown = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { items, setItems, selected, setSelected } = useContext(OperativeContext)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const select = (i) => {
        setSelected(i)
        setIsOpen(false)
    }

    return (
        <div className='flex flex-col text-xs font-bold border bg-black/90'>
            <div className='flex flex-row flex-1 group cursor-pointer' onClick={toggle}>
                <div className='flex-1'>
                    <DropdownItem Icon={items[selected].Icon} title={items[selected].title} disabled={false} />
                </div>
                <div className='px-2 group-hover:bg-white/10 flex flex-row items-center'>
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                </div>
            </div>
            {isOpen &&
                items.map(({ Icon, title, disabled }, i) => {
                    if (i === selected) return null
                    return (
                        <DropdownItem
                            key={i}
                            Icon={Icon}
                            title={title}
                            disabled={disabled}
                            onClick={() => !disabled && select(i)}
                        />
                    )
                })}
        </div>
    )
}

const DropdownItem = ({ Icon, title, disabled, onClick = () => {} }) => {
    return (
        <div
            className={twMerge(
                'flex flex-row items-center gap-2 p-2 cursor-pointer select-none',
                disabled && 'opacity-30',
                !disabled && 'hover:bg-white/10'
            )}
            onClick={onClick}
        >
            <div className='h-6 w-7'>{Icon}</div>
            <div className='flex-1'>{title}</div>
        </div>
    )
}

const StakeItemExample = () => {
    const { items, selected } = useContext(OperativeContext)
    const item = items[selected]
    return (
        <div className='flex flex-row items-end origin-top-left'>
            <div className='flex flex-col font-black text-xs'>
                <div className='relative w-40 h-40'>
                    <StakeImage url={item.pfp} />
                    <div className='absolute bottom-0 w-full'>
                        <StakeRate rate={item.rate} />
                    </div>
                </div>
                <StakeProgress progress={65.44} type={item.type} />
            </div>

            <div className='flex flex-col whitespace-nowrap text-xs font-bold'>
                <div className='flex flex-row gap-2 p-1 items-center h-10'>
                    <img src={unionArrow} className='h-6 w-6' />
                    <small>DAILY POINTS</small>
                </div>
                <div className='flex flex-row gap-2 p-1 items-center h-10'>
                    <img src={unionArrow} className='h-6 w-6' />
                    <small>CLAIM PROGRESS</small>
                </div>
            </div>
        </div>
    )
}

const LootBoxExample = () => {
    return (
        <div
            className='border border-green-500 flex flex-col w-52'
            style={{
                backgroundImage: `linear-gradient(89deg, rgb(217, 217, 217, 0.15) -69.28%, rgba(217, 217, 217, 0) 162.18%)`,
            }}
        >
            <div className='relative h-48 flex flex-col items-center justify-center'>
                <img src={lootBoxImage} className='w-3/4' />
                <Icons.Lootbox className='absolute h-8 w-10 left-2 top-2' />
            </div>

            <div
                className='font-bold p-4 text-center text-sm -mt-4'
                style={{
                    background:
                        'linear-gradient(89deg, rgb(217, 217, 217,0.15) -69.28%, rgba(217, 217, 217, 0.00) 162.18%)',
                }}
            >
                BR1: LOOT BOX
            </div>
        </div>
    )
}

const WeaponExample = () => {
    return (
        <div
            className='border border-green-500 flex flex-col w-52'
            style={{
                backgroundImage: `linear-gradient(89deg, rgb(217, 217, 217, 0.15) -69.28%, rgba(217, 217, 217, 0) 162.18%)`,
            }}
        >
            <div className='relative h-48 flex flex-col items-center justify-center'>
                <img src={randomWeapon} className='w-3/4' />
                <Icons.Weapon className='absolute h-8 w-10 left-2 top-2' />
            </div>

            <div
                className='font-bold p-4 text-center text-sm -mt-4'
                style={{
                    background:
                        'linear-gradient(89deg, rgb(217, 217, 217,0.15) -69.28%, rgba(217, 217, 217, 0.00) 162.18%)',
                }}
            >
                RANDOM WEAPON
            </div>
        </div>
    )
}

const RewardRequirements = () => {
    return (
        <div className='flex flex-col gap-2 text-center'>
            <div className='flex flex-col md:flex-row gap-x-2 md:items-center font-bold '>
                <div className='bg-[rgba(201,31,255,0.75)] flex flex-row items-center gap-2 p-2 flex-1'>
                    <Icons.Ape className='h-6 w-8' fill='#fff' />
                    <div>100%</div>
                </div>
                <div className='text-xl'>+</div>
                <div className='bg-[#0088D5] flex flex-row items-center gap-2 p-2 flex-1'>
                    <Icons.Droid className='h-6 w-8' fill='#fff' />
                    <div>100%</div>
                </div>
                <div className='text-xl'>=</div>
                <div className='flex flex-col md:pt-4'>
                    <Icons.SkullPoint className='h-12 w-14 self-center' />
                    <small>1000</small>
                </div>
            </div>
        </div>
    )
}
