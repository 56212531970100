import { useQuery } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'

const API_URL = '/api/account/wallet'

export const useUserWalletList = () => {
    const csrfToken = useCrsfToken()

    return useQuery<Wallet[]>({
        queryKey: ['user_wallets'],
        queryFn: async () => {
            const res = await fetch(API_URL, {
                headers: {
                    'X-CSRFToken': csrfToken,
                },
            })
            return await res.json()
        },
        initialData: [],
    })
}
