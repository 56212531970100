import React from 'react'
import { twMerge } from 'tailwind-merge'

export type BaseProps = {
    children: React.ReactNode
    className?: string
    as?: React.ReactElement
    [p: string]: any
}

export const Base = ({ children, className, as, ...rest }: BaseProps) => {
    const el = as || <div />
    if (React.isValidElement(el)) {
        return React.cloneElement(el as JSX.Element, {
            children: children,
            className: twMerge(className, (el as JSX.Element).props.className),
            ...rest,
        })
    }
}
