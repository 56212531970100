import { useMutation, useQueryClient } from '@tanstack/react-query'

type Response = {
    uuid: string
    status: number
}

export const useCheckTransaction = () => {
    const queryClient = useQueryClient()

    return useMutation<Response, unknown, unknown>({
        mutationFn: async ({ uuid }) => {
            const resp = await fetch(`/api/bank/deposit/solana_usdc/${uuid}`, {
                method: 'GET',
            })
            return await resp.json()
        },
        retry: false,
        onSuccess: (data) => {
            const { status, uuid } = data
            if (status === 2) queryClient.invalidateQueries(['balance'])
            queryClient.setQueriesData<DeposiListResponse>(['deposit.solana_usdc.list'], (list) => {
                const { data } = list
                const deposit = data.find((item) => item.uuid == uuid)
                const depositIndex = data.findIndex((item) => item.uuid == uuid)
                const newData = data.slice(0)
                newData[depositIndex] = { ...deposit, status }
                if (deposit) {
                    return {
                        ...list,
                        data: newData,
                    }
                }
            })
        },
    })
}
