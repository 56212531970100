import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import { Page } from '~/components/Page'
import { PasswordSetForm } from '../components'

export const PasswordSetPage = () => {
    const { t } = useTranslation()
    const { uid, token } = useParams()
    return (
        <Page title='PASSWORD CHANGE' header='PASSWORD CHANGE'>
            <section className='animate-fade-in px-8 w-full max-w-4xl mx-auto flex flex-col items-center gap-y-8 text-xl text-center font-bold py-16 lg:py-24'>
                <header className='self-center text-4xl uppercase font-black text-center lg:text-5xl'>
                    {t('auth.layout_header')}
                </header>
                <PasswordSetForm uid={uid} token={token} />
                <div>
                    <NavLink to='/password-reset' className='underline hover:no-underline'>
                        RESET AGAIN.
                    </NavLink>
                </div>
            </section>
        </Page>
    )
}
