import { useTranslation } from 'react-i18next'
import { Text } from '~/shared/ui'

export const AuthContainer = ({ children, className = undefined }) => {
    const { t } = useTranslation()
    return (
        <div
            className={[
                'bg-black bg-[url(/src/js/shared/assets/img/bg.auth.png?as=webp)] bg-cover bg-no-repeat bg-right-top',
                'rounded-xl border',
                'w-4/5 md:w-3/4 lg:w-3/5 mx-auto overflow-hidden',
                className,
            ]
                .filter(Boolean)
                .join(' ')}
        >
            <div className='flex flex-col gap-2  bg-black/50 md:bg-black/20 p-8'>
                <h1 className='font-bold text-3xl lg:text-5xl uppercase'>
                    <Text.Gradient>{t('auth:form.header')}</Text.Gradient>
                </h1>
                <h2 className='md:text-xl uppercase'>{t('auth:form.subheader')}</h2>
                <section className='md:w-3/4 max-w-sm flex flex-col gap-2 my-4'>{children}</section>
            </div>
        </div>
    )
}