import { DepositWithdrawProvider } from '../hooks/useDepositWithdraw'
import { CurrencyProvider } from '../providers'
import { DepositWithdraw } from './DepositWithdraw'

export const DepositWithdrawModal = ({ action }) => {
    return (
        <DepositWithdrawProvider initAction={action}>
            <CurrencyProvider>
                <DepositWithdraw />
            </CurrencyProvider>
        </DepositWithdrawProvider>
    )
}
