import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOtpInput } from 'react-otp-input-hook'
import { useActivate } from '../api'

export const ActivationCodeForm = () => {
    const { t } = useTranslation()
    const [otp, setOtp] = useState('')
    const { register } = useOtpInput({
        onInputValueChange: setOtp,
    })
    const defaultOptions = { required: true }

    const { mutate, isLoading, error: activationError } = useActivate()

    const error = useMemo(() => {
        return (
            activationError?.code?.map((v) => v?.message)?.[0] || activationError?.__all__?.map((v) => v?.message)?.[0]
        )
    }, [activationError])

    const handleSubmit = (e) => {
        e.preventDefault()
        mutate({ code: otp })
    }

    return (
        <form className='flex flex-col items-center gap-2 w-full' onSubmit={handleSubmit}>
            <section className='flex flex-col gap-2'>
                <div className='flex flex-row gap-2'>
                    {['digit-1', 'digit-2', 'digit-3', 'digit-4'].map((name) => (
                        <input
                            key={name}
                            className='bg-black/50 backdrop-blur-2xl border text-white text-center text-5xl p-2'
                            size={1}
                            {...register(name, defaultOptions)}
                        />
                    ))}
                </div>
                <label className='login-form error'>{error}</label>
            </section>

            <button
                disabled={isLoading}
                className='flex flex-row gap-2 items-center justify-center p-4 mt-8 w-full md:w-1/2 mx-auto hover:outline'
                style={{ background: 'linear-gradient(142deg,#03CEFF 0%,#C91FFF 100%)' }}
            >
                <span>{t('auth.enter_code')}</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </form>
    )
}
