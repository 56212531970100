import { useWallet } from '@solana/wallet-adapter-react'
import * as web3 from '@solana/web3.js'
import { useQueryClient } from '@tanstack/react-query'
import base58 from 'bs58'
import { useState } from 'react'
import api from '~/api'

export const useWalletMesageSigner = ({ isLedger = false }) => {
    const { wallet, signMessage, publicKey, signTransaction } = useWallet()
    const [processing, setProcessing] = useState(false)
    const queryClient = useQueryClient()

    const approve = async () => {
        // Software wallet with message signing
        if (!isLedger) {
            const nonce = await api.account.nonce(publicKey, wallet.adapter.name)
            if (!nonce) return false

            const message = new TextEncoder().encode(nonce)
            const signature = await signMessage!(message)
            const success = await api.account.addWallet({
                wallet: publicKey!.toString(),
                signature: base58.encode(signature),
            })

            return success
        }
        // Ledget wallet
        else {
            const message = await api.account.nonce(publicKey, wallet.adapter.name, true)
            const msg = web3.Message.from(base58.decode(message))
            const tx = web3.Transaction.populate(msg)

            if (tx.instructions[0].programId.toString() !== '11111111111111111111111111111111') {
                throw new Error('Wrong transaction instruction programId')
            }

            if (!tx.instructions[0].keys.every((k) => k.pubkey.toString() === publicKey.toString())) {
                throw new Error('Transaction instruction keys not same as choosen wallet')
            }

            const signed = await signTransaction(tx)
            const success = await api.account.addWallet({
                wallet: publicKey!.toString(),
                signature: base58.encode(signed.serialize()),
            })

            return success
        }
    }

    const link = async () => {
        try {
            setProcessing(true)
            const result = await approve()
            if (result === true) {
                queryClient.invalidateQueries(['user_wallets'])
            }
        } finally {
            setProcessing(false)
        }
    }

    return { link, processing }
}
