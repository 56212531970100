import { ProgressiveImg } from "./ProgressiveImg"
import assets from "../assets"
import { NavLink } from "react-router-dom"
import { usePage } from "./Page"
import { useEffect } from "react"

export default () => {
    const {setTitle} = usePage()

    useEffect(() => {
        setTitle('404 Not Found')
    }, [])

    return <div className="relative grid grid-cols-1 md:grid-cols-2 border border-[#D9D9D9] rounded w-3/4 mx-auto m-16" style={{background: "linear-gradient(102.02deg,rgba(201,31,255,0.15) -27.42%,rgba(3,206,255,0.15) 189.79%)"}}>
        <div className="flex flex-col items-center md:items-start text-center md:text-left py-4 md:pl-16">
            <h1 className="text-6xl font-bold">OOPS.</h1>
            <h2 className="text-4xl font-bold">PAGE NOT FOUND</h2>
            <p className="text-xl mt-4">OUR DROIDS CANNOT PROCESS THIS DATA</p>
            <p className="text-xl mt-4">TRY AGAIN LATER</p>
            <NavLink to="/" className="button-default w-max mt-4">BACK TO HOME</NavLink>
        </div>
        <ProgressiveImg src={assets.droid_404} placeholder={assets.droid_404} className="w-full h-full object-contain object-bottom"/>
    </div>
}