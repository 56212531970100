import { Fragment, useEffect, useState } from 'react'
import { IsDeveloper } from '~/components/Auth'
import { Box } from '~/shared/ui'
import { TeamUser } from '../api'
import { jsonrpc } from '~/shared/api/jsonrpc'

export const TeamMembers = () => {
    const [members, setMembers] = useState<Array<TeamUser>>([])

    useEffect(() => {
        jsonrpc('accounts.staff').then((resp) => setMembers(resp.result))
    }, [])

    return (
        <IsDeveloper>
            <Box>
                <header className='uppercase font-bold text-center py-2 text-2xl'>Team Members</header>
                <Box className='border border-white/10 rounded-lg overflow-hidden py-8'>
                    <div className=' bg-custom-gradient-1 h-1 absolute top-0 left-0 w-full'></div>

                    <div className='grid grid-cols-[repeat(6,max-content)] gap-4 p-4 items-center w-full lg:justify-center  overflow-auto'>
                        <div className='opacity-50 font-light'>User</div>
                        <div className='opacity-50 font-light'>Email</div>
                        <div className='opacity-50 font-light'>Last login</div>
                        <div className='opacity-50 font-light'>Developer</div>
                        <div className='opacity-50 font-light'>Marketing</div>
                        <div className='opacity-50 font-light'>Executive</div>
                        {members.map(({ username, email, last_login, is_developer, is_marketing, is_executive }, i) => (
                            <Fragment key={i}>
                                <div className=''>{username}</div>
                                <div>{email}</div>
                                <div className='text-xs'>{last_login}</div>
                                {[is_developer, is_marketing, is_executive].map((value, i) => (
                                    <div key={i} className='flex flex-row items-center'>
                                        <div
                                            className={`h-3 w-3 mx-auto rounded-full bg-white ${
                                                value ? '' : 'opacity-10'
                                            }`}
                                        >
                                            &nbsp;
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                    </div>
                </Box>
            </Box>
        </IsDeveloper>
    )
}
