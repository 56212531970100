import { useMutation } from '@tanstack/react-query'

const API_URL = '/api/lootbox/send_and_confirm'

export const useSendAndConfirm = () => {
    return useMutation({
        mutationFn: async ({ transaction }: { transaction: string }) => {
            const resp = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    transaction,
                }),
            })
            if (resp.status !== 200) throw new Error('Failed to send and confirm transaction')
        },
    })
}
