import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

const lootboxImage = new URL('../assets/lootbox.png', import.meta.url).href

export const LootboxImage = () => {
    const [imgLoaded, setImgLoaded] = useState(false)
    const onload = () => setImgLoaded(true)
    return (
        <img
            loading='lazy'
            src={lootboxImage}
            onLoad={onload}
            className={twMerge(
                'h-60 opacity-0',
                !imgLoaded && 'bg-white/5 animate-pulse',
                imgLoaded && 'opacity-100 transition-opacity duration-500'
            )}
        />
    )
}
