import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import urls from '../external_urls'
import { useAuth } from './Auth'

export const PageFooter = () => {
    const { t } = useTranslation()
    const { auth } = useAuth()

    const BR1_LOGO = '/media/assets/br1_logo.webp'
    const BRAVO_READY_LOGO = '/media/assets/bravo_ready_logo_small.webp'
    return (
        <div className='flex flex-col items-center uppercase text-xs text-white/60 text-center max-w-[1920px] mx-auto py-8'>
            <img src={BR1_LOGO.toString()} className='w-2/5 max-w-xs mb-8' />
            <div className='grid grid-cols-2 md:grid-cols-3 justify-between flex-wrap gap-8 w-4/5 font-black'>
                <div className='flex flex-col items-center gap-y-2'>
                    <header className='min-h-[3em] text-lg font-black text-white flex flex-row items-center'>
                        {t('footer.about.header')}
                    </header>
                    <Link to={{ pathname: '/', hash: 'trailers' }} className='hover:text-white'>
                        {t('footer.about.trailers')}
                    </Link>
                    <Link to={{ pathname: '/', hash: 'game_modes' }} className='hover:text-white'>
                        {t('footer.about.game_modes')}
                    </Link>
                    <Link to={{ pathname: '/', hash: 'game_mechanics' }} className='hover:text-white'>
                        {t('footer.about.game_mechanics')}
                    </Link>
                    <Link to={{ pathname: '/', hash: 'partners' }} className='hover:text-white'>
                        {t('footer.about.partners_and_investors')}
                    </Link>
                    <Link to={{ pathname: '/', hash: 'news' }} className='hover:text-white'>
                        {t('footer.about.news')}
                    </Link>
                </div>
                <div className='flex flex-col items-center  gap-y-2'>
                    <header className='min-h-[3em] text-lg font-black text-white flex flex-row items-center'>
                        DOWNLOAD
                    </header>
                    {auth?.is_auth == false && (
                        <NavLink to='/signup' className='hover:text-white uppercase'>
                            {t('footer.sign_up')}
                        </NavLink>
                    )}
                    <NavLink to='/download' className='hover:text-white'>
                        DOWNLOAD BR1
                    </NavLink>
                    <a href={urls.media_kit} target='_blank' className='hover:text-white'>
                        MEDIA KIT
                    </a>
                </div>
                <div className='flex flex-col items-center gap-y-2'>
                    <header className='min-h-[3em] text-lg font-black text-white flex flex-row items-center'>
                        {t('footer.social.header')}
                    </header>
                    <a href={urls.discord} target='_blank' className='hover:text-white'>
                        Discord
                    </a>
                    <a href={urls.twitter} target='_blank' className='hover:text-white'>
                        Twitter
                    </a>
                    <a href={urls.instagram} target='_blank' className='hover:text-white'>
                        Instagram
                    </a>
                    <a href={urls.tiktok} target='_blank' className='hover:text-white'>
                        Tiktok
                    </a>
                    <a href={urls.youtube} target='_blank' className='hover:text-white'>
                        Youtube
                    </a>
                </div>
            </div>
            <div className='w-full bg-[#131313] mt-8 text-white'>
                <div className='grid grid-cols-1 md:grid-cols-3 items-center justify-center w-4/5 mx-auto py-8 font-black gap-y-8'>
                    <div className='flex flex-col items-center justify-center gap-2 text-xs'>
                        <div>© {new Date().getFullYear()} BR1: INFINITE</div>
                        <div>13904580 Canada Inc.</div>
                        {/* <div>TERMS</div>
                    <div>PRIVACY</div> */}
                    </div>
                    <div className='w-max mx-auto border rounded-full py-2 px-10'>OPEN BETA PHASE</div>
                    <img src={BRAVO_READY_LOGO.toString()} className='mx-auto' />
                </div>
            </div>
        </div>
    )
}
