import { twMerge } from 'tailwind-merge'
import { Deposit as DepositSolanaUsdc } from '~/features/deposit/solana_usdc'
import { useCurrency } from '../hooks'
import { useDepositWithdraw } from '../hooks/useDepositWithdraw'
import { SelectCurrency } from './SelectCurrency'
import { Withdraw } from './Withdraw'

const lockImg = new URL('../assets/lock.png', import.meta.url).toString()

const actions = {
    deposit: true,
    withdraw: true,
}

export const DepositWithdraw = () => {
    const { action, setAction } = useDepositWithdraw()
    const { currency, setCurrency } = useCurrency()

    const switchAction = (e) => {
        setAction(e.target.value)
        setCurrency(undefined)
    }

    return (
        <div className='relative grid grid-cols-2 md:grid-cols-4 gap-4 p-4 md:p-8 bg-black border w-full max-w-xl'>
            <header className=' col-start-1 col-end-[-1] uppercase text-xl md:text-2xl font-bold flex flex-row items-center justify-center gap-4'>
                {currency?.img && <img src={currency.img} className='h-8 w-8' />}
                <span>{action}</span>
                <span className='hidden md:inline'>{currency?.name}</span>
            </header>
            {Object.entries(actions).map(([actionName, enabled]) => (
                <button
                    key={actionName}
                    disabled={!enabled}
                    className={twMerge(
                        'relative col-span-2 uppercase text-base p-1 font-bold disabled:cursor-not-allowed',
                        actionName === action ? 'bg-br1-gradient border' : 'bg-black-gradient'
                    )}
                    value={actionName}
                    onClick={switchAction}
                >
                    {actionName}
                    {!enabled && (
                        <div className='absolute top-0 left-0 pl-4 z-50 flex flex-row items-center justify-left bg-black/70 w-full h-full'>
                            <img src={lockImg} className=' h-4' />
                        </div>
                    )}
                </button>
            ))}

            {currency ? (
                <>
                    {action === 'withdraw' && <Withdraw />}
                    {action === 'deposit' && (
                        <div className='col-span-2 md:col-span-4'>
                            <DepositSolanaUsdc />
                        </div>
                    )}
                </>
            ) : (
                <SelectCurrency />
            )}
        </div>
    )
}
