import { getCookie } from '../../utils/cookie';

export const csrftoken = (): string => {
    return getCookie('csrftoken') ?? ''
}

export const fetchPost = async ({ url, headers, body }: { url: string; headers?: any; body?: any }) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': csrftoken(),
            ...headers,
        },
        body: JSON.stringify(body),
    })
    if (response.ok) {
        if (response.headers.get('Content-Type') == 'text/csv') {
            const filename = response.headers.get('Content-Disposition').split('filename=')[1]
            const blob = await response.blob()
            const fileUrl = window.URL.createObjectURL(blob)
            const fileLink = document.createElement('a')
            fileLink.href = fileUrl
            fileLink.download = filename
            fileLink.click()
            return
        }
        return response.json()
    } else throw response.json()
}