import React,{useEffect, useState } from "react"

export const ProgressiveImg = ({ src, placeholder, className = '', ...props }) => {
    const [imgSrc, setImgSrc] = useState(placeholder)
    const [blur, setBlur] = useState(true)

    useEffect(() => {
        const img = new Image()
        img.src = src
        img.onload = () => {
            setImgSrc(img.src)
            setBlur(false)
        }
    }, [src, placeholder])

    return <img src={imgSrc} className={className + ` transition-all ease-linear ${blur ? 'blur-lg' : 'blur-none'}`} {...props} />
}