import { useEffect, useState } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Stat, StatLabel, StatNumber, Header } from '~/shared/ui'

export const AccountsBetaUserActiveCount = () => {
    const [accounts, setAccounts] = useState<Record<string, number>>({})

    const periods = ['today', 'week', 'month', '24_hours', '7_days', '30_days']

    const getValues = () => {
        jsonrpc('statistic.accounts.active_beta_users_count', { periods }).then((resp) => {
            setAccounts(resp.result || {})
        })
    }

    useEffect(() => getValues(), [])

    return (
        <Box className='w-full text-center'>
            <Header>Active beta users</Header>
            <small className='text-white/50'>
                Active means that the user has tried to join the game BR1 Infinite in the selected time period.
            </small>
            <section className='grid grid-cols-3 gap-2 mt-2 w-full'>
                {periods.map((period) => {
                    const value = accounts[period]
                    return (
                        <Stat.Gray
                            key={period}
                            loading={value === undefined}
                            className={period === 'total' && 'col-span-3'}
                        >
                            <StatLabel className='capitalize text-amber-500'>{period.replace('_', ' ')}</StatLabel>
                            <StatNumber>{value}</StatNumber>
                        </Stat.Gray>
                    )
                })}
            </section>
        </Box>
    )
}
