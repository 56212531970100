import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WalletSendTransactionError } from '@solana/wallet-adapter-base'
import { useWallet } from '@solana/wallet-adapter-react'
import { Connection, Transaction } from '@solana/web3.js'
import { useState } from 'react'
import { WalletContext } from '~/components/WalletContext'
import { useCurrency } from '~/features/bank/hooks'
import { DecimalPlacesValidator, PositiveNumberValidator } from '~/features/bank/utils'
import { useConfirmTransaction } from '../api/useConfirmTransaction'
import { useMakeTransaction } from '../api/useMakeTransaction'
import { DepositList } from './DepositList'
import { WalletSelector } from './WalletSelector'

const usdcIcon = new URL('~/features/bank/assets/usdc.png', import.meta.url).toString()

export const Deposit = () => {
    return (
        <WalletContext>
            <div className='flex flex-col gap-2'>
                <WalletSelector />
                <PayButton />
                <DepositList />
            </div>
        </WalletContext>
    )
}

const PayButton = () => {
    const { publicKey, sendTransaction } = useWallet()
    const { mutate: makeTransaction, isLoading } = useMakeTransaction()
    const [amount, setAmount] = useState('')
    const { mutate: confirmTransaction } = useConfirmTransaction()
    const { currency } = useCurrency()

    const amountOnChange = (e) => {
        const value = e.target.value
        if (
            [PositiveNumberValidator, DecimalPlacesValidator(currency.decimals)].every((validator) => validator(value))
        ) {
            setAmount(value)
        }
    }
    const pay = (e) => {
        e.preventDefault()
        makeTransaction(
            { wallet: publicKey.toString(), amount: amount, deposit_type: currency.deposit_type },
            {
                onSuccess: async (data) => {
                    const { uuid, tx, cluster_url } = data
                    const connection = new Connection(cluster_url, 'confirmed')
                    const transaction = Transaction.from(Buffer.from(tx, 'base64'))
                    sendTransaction(transaction, connection)
                        .then((signature) => {
                            setAmount('')
                            confirmTransaction({ uuid, signature })
                        })
                        .catch((e) => {
                            if (e instanceof WalletSendTransactionError) {
                                console.log(e.message, e.name, e.cause, e.error)
                            }
                        })
                },
            }
        )
    }

    return (
        <form onSubmit={pay} className='flex flex-col gap-2'>
            <label className='flex flex-row gap-2 p-2 w-full border border-white/50'>
                <img src={currency.img} className='h-6 w-6 mx-1' />
                <input
                    type='text'
                    className='w-full bg-black focus:outline-none placeholder:uppercase placeholder:text-white/20 placeholder:text-xs'
                    placeholder='amount'
                    value={amount}
                    onChange={amountOnChange}
                />
            </label>
            <button
                disabled={!publicKey || amount == ''}
                className='flex flex-row items-center justify-center gap-2 bg-br1-gradient w-full p-2 rounded text-center font-bold disabled:opacity-10'
            >
                <span>PAY</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </form>
    )
}
