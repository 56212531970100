import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCheckTransaction } from './useCheckTransaction'

type Response = {
    uuid: string
    status: number
}

export const useConfirmTransaction = () => {
    const queryClient = useQueryClient()
    const { mutate: checkTransaction } = useCheckTransaction()

    return useMutation<Response, unknown, unknown>({
        mutationFn: async ({ uuid, signature }) => {
            const resp = await fetch('/api/bank/deposit/solana_usdc/tx', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uuid,
                    signature,
                }),
            })
            return await resp.json()
        },
        retry: false,
        onSuccess: (data) => {
            queryClient.invalidateQueries(['deposit.solana_usdc.list'])
            const { uuid } = data
            const checkInterval = setInterval(() => {
                checkTransaction(
                    { uuid },
                    {
                        onSuccess: (data) => {
                            const { status } = data
                            if (status === 2) clearInterval(checkInterval)
                        },
                    }
                )
            }, 6000)
        },
    })
}
