import { useQuery } from '@tanstack/react-query'
import { AnonymousViewer, Viewer } from '../types'

export const useUser = () => {
    return useQuery<Viewer, typeof AnonymousViewer>({
        queryKey: ['viewer'],
        queryFn: async () => {
            const res = await fetch('/api/account/auth')
            if (res.ok) {
                return await res.json()
            } else {
                return AnonymousViewer
            }
        },
        retry: false,
        staleTime: 1000 * 60,
    })
}
