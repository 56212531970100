import { AccountsCount } from './accounts_count'
import { AccountsCountChart } from './accounts_count_chart'
import { AccountsReferrals } from './accounts_referrals'
import { AccountsActiveCount } from './accounts_active_count'
import { AccountsBetaUserCount } from './accounts_beta_user_count'
import { AccountsBetaUserActiveCount } from './accounts_beta_user_active_count'

export const Accounts = {
    Count: AccountsCount,
    CountChart: AccountsCountChart,
    Referrals: AccountsReferrals,
    ActiveCount: AccountsActiveCount,
    BetaUserCount: AccountsBetaUserCount,
    BetaUserActiveCount: AccountsBetaUserActiveCount,
}

import { NftTokensCount } from './nft_tokens_count'
import { NftHoldersCount } from './nft_holders_count'
import { NftGuildsCount } from './nft_guilds_count'

export const Nft = {
    TokensCount: NftTokensCount,
    HoldersCount: NftHoldersCount,
    GuildsCount: NftGuildsCount,
}

import { ConnectionsTotalCount } from './connections_total_count'
import { ConnectionsOneCount } from './connections_one_count'
import { ConnectionsTwoCount } from './connections_two_count'
import { ConnectionsThreeCount } from './connections_three_count'

export const Connections = {
    TotalCount: ConnectionsTotalCount,
    OneCount: ConnectionsOneCount,
    TwoCount: ConnectionsTwoCount,
    ThreeCount: ConnectionsThreeCount,
}
