import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useInput } from '~/hooks/useInput'
import { RequiredValidator } from '~/utils/validators'
import { usePasswordReset } from '../api'

export const PasswordResetForm = () => {
    const { t } = useTranslation()
    const { mutate, error, isLoading, isSuccess, reset } = usePasswordReset()

    const email = useInput('', { validators: [RequiredValidator()] })

    const handleSubmit = (e) => {
        e.preventDefault()
        reset()
        const valid = [email].map((f) => f.validate()).every((v) => v)
        if (!valid) return
        mutate(
            { email: email.value },
            {
                onError: (e) => {
                    email.setErrors(e?.email?.map((v) => v?.message) || e?.__all__?.map((v) => v?.message))
                },
            }
        )
    }

    return (
        <form noValidate className='flex flex-col gap-1 w-full' onSubmit={handleSubmit}>
            <input
                value={email.value}
                onChange={email.onChange}
                type='text'
                name='email'
                placeholder='EMAIL'
                className='login-form'
            />
            {email.error && <label className='login-form error'>{email.error}</label>}
            {isSuccess && <label className='login-form success'>{t('auth.password_reset_link_sent')}.</label>}

            <button
                disabled={isLoading}
                className='flex flex-row gap-2 items-center justify-center p-4 mt-8 mx-auto hover:outline w-full md:w-1/2'
                style={{ background: 'linear-gradient(142deg,#03CEFF 0%,#C91FFF 100%)' }}
            >
                <span>{t('auth.reset_password')}</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </form>
    )
}
