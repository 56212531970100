export type Validator = (value: any) => boolean

export const MaxLengthValidator =
    (maxLength: number): Validator =>
    (value) =>
        value.length <= maxLength

export const NumericValidator =
    ({ decimalPlaces }): Validator =>
    (value) => {
        if (!isNaN(value)) {
            if (decimalPlaces) {
                const decimals = value.split('.')?.[1]
                if (decimals && decimals.length > decimalPlaces) return false
            }
            return true
        }
    }

export const IntegerValidator: Validator = (value) => {
    if (!isNaN(value)) {
        if (value.includes('.')) return false
        return true
    }
}

export const RangeValidator =
    ({ min, max }): Validator =>
    (value) => {
        if (value === '') return true
        if (!isNaN(value)) {
            const num = Number(value)
            if (num >= min && num <= max) return true
        }
    }
