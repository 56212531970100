import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { useInput } from '~/hooks/useInput'
import { MinLengthValidator, RequiredValidator } from '~/utils/validators'
import { useLogin } from '../api'

export const LoginForm = () => {
    const { t } = useTranslation()
    const { mutate, error, isLoading, reset } = useLogin()
    const navigate = useNavigate()

    const username = useInput('', { validators: [RequiredValidator(), MinLengthValidator(3)] })
    const password = useInput('', { validators: [RequiredValidator(), MinLengthValidator(8)] })

    const submit = (e) => {
        e.preventDefault()
        reset()
        const valid = [username, password].map((f) => f.validate()).every((v) => v)
        if (!valid) return
        mutate(
            { username: username.value, password: password.value },
            {
                onError: (e) => {
                    username.setErrors(e?.username?.map((v) => v?.message))
                    password.setErrors(e?.password?.map((v) => v?.message))
                },
            }
        )
    }

    return (
        <form noValidate className='flex flex-col gap-1 w-full' onSubmit={submit}>
            <input
                value={username.value}
                onChange={username.onChange}
                type='text'
                name='username'
                placeholder={t('auth.username')}
                className='login-form'
                maxLength={16}
            />
            <label className='login-form error'>{username.error}</label>

            <input
                value={password.value}
                onChange={password.onChange}
                type='password'
                name='password'
                placeholder={t('auth.password')}
                className='login-form'
                maxLength={64}
            />
            <label className='login-form error'>{password.error}</label>

            <label className='login-form error flex flex-col items-center'>
                {error?.__all__?.map((v) =>
                    v.code === 'email_not_confirmed' ? <Navigate to='/signup' /> : <span>{v?.message}</span>
                )}
            </label>

            <button
                disabled={isLoading}
                className='flex flex-row gap-2 items-center justify-center p-4 mt-8 mx-auto hover:outline w-full md:w-1/2'
                style={{ background: 'linear-gradient(142deg,#03CEFF 0%,#C91FFF 100%)' }}
            >
                <span>{t('common.enter')}</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </form>
    )
}
