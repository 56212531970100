import moment from 'moment'
import { useState, useEffect } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Chart } from '~/shared/ui'

export const AccountsCountChart = ({ days = 14 }) => {
    const [labels, setLabels] = useState<string[]>([])
    const [registered, setRegistered] = useState<number[]>([])
    const [activated, setActivated] = useState<number[]>([])
    const [downloads, setDownloads] = useState<number[]>([])
    const [activePlayers, setActivePlayers] = useState<number[]>([])

    const getData = () => {
        jsonrpc('statistic.accounts.count.history', { days, start_date: moment().toISOString(true) }).then((res) => {
            const labels = Object.keys(res.result || {})
            const data = Object.values(res.result || {})
            setLabels(labels)
            setRegistered(data.map(([v, _]: [number?, number?]) => v))
            setActivated(data.map(([_, a]: [number?, number?]) => a))
        })
        jsonrpc('gameapi.statistic.downloads').then((resp) => {
            setDownloads(Object.values(resp.result.history))
        })
        jsonrpc('gameapi.statistic.active_players').then((resp) => {
            setActivePlayers(Object.values(resp.result.history))
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Chart.Line
            className='max-h-40'
            options={{
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    tooltip: {
                        mode: 'index',
                    },
                },
                borderColor: 'red',
                scales: {
                    x: {
                        ticks: {},
                        type: 'time',
                        time: {
                            unit: 'day',
                            tooltipFormat: moment.localeData().longDateFormat('L'),
                        },
                    },
                    y: {
                        min: 0,
                        ticks: {
                            precision: 0,
                        },
                    },
                },
            }}
            data={{
                labels,
                datasets: [
                    {
                        label: 'registrations',
                        data: [...registered],
                        borderColor: '#fff',
                        backgroundColor: '#fff',
                    },
                    {
                        label: 'activations',
                        data: [...activated],
                        borderColor: 'rgb(34, 197, 94)',
                        backgroundColor: 'rgb(34, 197, 94)',
                    },
                    {
                        label: 'downloads',
                        data: [...downloads],
                        borderColor: '#eab308',
                        backgroundColor: '#eab308',
                    },
                    {
                        label: 'active players',
                        data: [...activePlayers],
                        borderColor: '#f43f5e',
                        backgroundColor: '#f43f5e',
                    },
                ],
            }}
        />
    )
}
