import { faGamepad } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { IsMarketing } from '~/components/Auth'
import { Box } from '~/shared/ui'
import { jsonrpc } from '../../shared/api/jsonrpc'

const Leaderboard = () => {
    const [leaderboard, setLeaderboard] = useState([])

    useEffect(() => {
        jsonrpc('treasurehunter.leaderboard', {}).then((data) => setLeaderboard(data.result ?? []))
    }, [])

    const headers = ['ID', 'Time', 'Winner', 'Prize in USDC']

    return (
        <div className=' max-w-[100vw] overflow-x-auto px-4'>
            <table className='border-separate border-spacing-y-2 text-sm text-center tracking-wide cursor-default'>
                <thead>
                    <tr className='text-white/80 text-sm'>
                        {headers.map((header) => (
                            <td className='pb-4 px-6' key={header}>
                                {header}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {leaderboard.map(({ id, timestamp, winner, prize }, i) => (
                        <tr className='odd:bg-gray-400/10' key={i}>
                            <td>{id}</td>
                            <td>{new Date(timestamp).toLocaleString()}</td>
                            <td className='px-4'>{winner}</td>
                            <td>{prize}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export const Br1TreasureHunt = () => {
    return (
        <IsMarketing>
            <Box>
                <header className='uppercase font-bold text-center py-2 text-2xl'>BR1 Treasure Hunt</header>
                <Box className='border border-white/10 rounded-lg overflow-hidden py-8'>
                    <div className=' bg-custom-gradient-1 h-1 absolute top-0 left-0 w-full'></div>
                    <Box className='w-4/5 max-w-full mx-auto gap-y-8 items-center'>
                        <a
                            target='_blank'
                            href='/team-tools/games/br1-treasure-hunt/'
                            className='button-default flex flex-row gap-x-2 my-4 items-center hover:scale-105'
                        >
                            <FontAwesomeIcon icon={faGamepad} />
                            <span>Start Game</span>
                        </a>
                        <div className='flex flex-col gap-y-4'>
                            <header className='text-xl tracking-widest text-white/80 font-bold text-center'>
                                Latest Winners
                            </header>
                            <Leaderboard />
                        </div>
                    </Box>
                </Box>
            </Box>
        </IsMarketing>
    )
}
