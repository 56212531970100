import moment from 'moment'
import { useEffect, useState } from 'react'

export const Countdown = ({ seconds }: { seconds: number }) => {
    const [value, setValue] = useState(seconds)

    useEffect(() => {
        setValue(seconds)
        const interval = setInterval(() => {
            setValue((v) => {
                if (v > 0) return v - 1
                else {
                    clearInterval(interval)
                    return 0
                }
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [seconds])

    return format(value)
}

const format = (value) => {
    const duration = moment.duration(value, 'seconds')
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
    if (days > 0) {
        return `${formatDays(days)}${time}`
    } else {
        return time
    }
}

const formatDays = (days: number) => {
    if (days == 0) return ''
    else if (days == 1) return `${days} day `
    else return `${days} days `
}
