import React, { useState } from 'react'
import api from '~/api'

interface IPasswordResetContext {
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    emailError: string
    commonError: string
    reset: (callback) => Promise<void>
}

const initialContext: IPasswordResetContext = {
    email: undefined,
    setEmail: () => {},
    emailError: undefined,
    commonError: undefined,
    reset: async () => {},
}

export const PasswordResetContext = React.createContext<IPasswordResetContext>(initialContext)

export const PasswordResetProvider = ({ children }) => {
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>()
    const [commonError, setCommonError] = useState<string>()

    const reset = async (callback = undefined) => {
        return api.account
            .passwordResetRequest({ email })
            .then(() => {
                if (callback) callback()
            })
            .catch((errors) => {
                setEmailError(errors?.email?.[0].message)
                setCommonError(errors?.__all__?.[0].message)
            })
    }

    return (
        <PasswordResetContext.Provider value={{ email, setEmail, emailError, commonError, reset }}>
            {children}
        </PasswordResetContext.Provider>
    )
}
