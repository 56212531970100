import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { ResendActivationEmailBadRequest } from '../types'

export const useResendActivationEmail = () => {
    const queryClient = useQueryClient()
    const csrfToken = useCrsfToken()

    return useMutation<unknown, ResendActivationEmailBadRequest, unknown>({
        mutationFn: async () => {
            const resp = await fetch('/api/account/activate', {
                method: 'PUT',
                headers: {
                    'X-CSRFToken': csrfToken,
                },
            })
            if (resp.status >= 500) {
                try {
                    return await resp.json()
                } catch (e) {
                    throw { __all__: [{ message: 'Internal server error', code: 'server_error' }] }
                }
            }
            if (resp.status === 400) {
                throw await resp.json()
            }
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(['viewer'])
        },
    })
}
