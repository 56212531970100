import { createContext, useContext, useEffect, useState } from "react";
import { getCookie } from "../utils/cookie";

const CsrfContext = createContext<string>('')

export const CsrfProvider = ({ children }) => {
    const [csrf, setCsrf] = useState<string>('')

    useEffect(() => {
        setCsrf(getCookie('csrftoken') ?? '')
    }, [])

    return <CsrfContext.Provider value={csrf}>{children}</CsrfContext.Provider>
}

export const useCsrf = () => {
    return useContext(CsrfContext)
}