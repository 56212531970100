import { useGameSessionKills } from '../api/useGameSessionKills'
import { GameSessionList } from './GameSessionList'

export const GameSessionKills = ({ session }: { session: GameSession }) => {
    const { data } = useGameSessionKills({ session_id: session.session_id })
    return (
        <div className='flex flex-col gap-2'>
            <div className='px-4 pt-2'>
                <span className='text-blue-500'>{session.username}</span>
                <small className='text-white/50'> {`<${session.session_id}>`}</small> KILLS
            </div>
            <GameSessionList sessions={data} showUsername={true} />
        </div>
    )
}
