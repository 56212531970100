import { useContext } from 'react'
import { LootboxContext } from '../providers/LootboxProvider'

export const useLootBoxes = () => {
    const { lootboxes, isFetched, setLootboxes, setCurrentOpening, currentOpening, videoSrc } =
        useContext(LootboxContext)

    const finishProcessing = (lootbox) => {
        setLootboxes((prev) => prev.filter((l) => l.id !== lootbox.id))
    }
    const nextLootbox = () => {
        return lootboxes.filter((l) => l !== currentOpening)[0]
    }
    const openNextLootbox = () => {
        const next = lootboxes.find((l) => l !== currentOpening)
        setCurrentOpening(next)
        finishProcessing(currentOpening)
    }
    return {
        lootboxes,
        isFetched,
        setLootboxes,
        currentOpening,
        setCurrentOpening,
        nextLootbox,
        finishProcessing,
        openNextLootbox,
        videoSrc,
    }
}
