import { LootboxDropRates } from './LootboxDropRates'

const bg = new URL('../assets/bg.png', import.meta.url).href

export const LootboxInfo = () => {
    return (
        <div
            className='h-full max-h-[1080px] w-full bg-top bg-contain md:bg-cover bg-no-repeat'
            style={{
                backgroundImage: `url(${bg})`,
            }}
        >
            <div className='grid grid-cols-1 md:grid-cols-[2fr,1fr,2fr] gap-8 px-4 md:p-12 2xl:p-24'>
                <div className='flex flex-col gap-y-2 lg:gap-y-8 font-black text-center md:text-left mt-52 md:mt-0'>
                    <header className='text-2xl lg:text-5xl xl:text-7xl 2xl:text-8xl'>
                        OPEN YOUR
                        <br className='hidden md:block' /> LOOT
                        <br className='hidden md:block' /> BOXES
                    </header>
                    <div className='text-xl lg:text-3xl text-white/70'>
                        OPEN YOUR BOX
                        <br className='hidden md:block' /> TO REVEAL
                        <br className='hidden md:block' />
                        WHAT’S INSIDE
                    </div>
                    <div className='text-2xl lg:text-5xl xl:text-7xl 2xl:text-8xl'>OR</div>
                    <div className='text-xl  lg:text-3xl text-white/70'>
                        HOLD ONTO THEM
                        <br className='hidden md:block' />
                        TO SEE WHAT
                        <br className='hidden md:block' />
                        HAPPENS NEXT
                    </div>
                </div>
                <div className='md:col-start-3 w-max md:justify-self-end flex flex-col gap-8 mx-auto md:mx-0'>
                    {/* <Link to='/account#wallets' className='bg-br1-gradient font-black p-4 hover:outline text-center'>
                        ADD ANOTHER WALLET
                    </Link> */}
                    <LootboxDropRates />
                </div>
            </div>
        </div>
    )
}
