import { faGamepad } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IsMarketing } from '~/components/Auth'
import { Box } from '~/shared/ui'

export const Br1AceDerby = () => {
    return (
        <IsMarketing>
            <Box>
                <header className='uppercase font-bold text-center py-2 text-2xl'>BR1 Ace Derby</header>
                <Box className='border border-white/10 rounded-lg overflow-hidden py-8'>
                    <div className=' bg-custom-gradient-1 h-1 absolute top-0 left-0 w-full'></div>
                    <Box className='w-4/5 max-w-full mx-auto gap-y-8 items-center'>
                        <a
                            target='_blank'
                            href='/team-tools/games/br1-ace-derby/'
                            className='button-default flex flex-row gap-x-2 my-4 items-center hover:scale-105'
                        >
                            <FontAwesomeIcon icon={faGamepad} />
                            <span>Start Game</span>
                        </a>
                    </Box>
                </Box>
            </Box>
        </IsMarketing>
    )
}
