import { useMutation } from '@tanstack/react-query'

const API_URL = '/api/lootbox/open'

export const useOpenLootbox = () => {
    return useMutation({
        mutationFn: async ({ mint }: { mint: string }) => {
            const resp = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    mint,
                }),
            })
            return await resp.json()
        },
    })
}
