import { ProgressiveImg } from "./ProgressiveImg"
import assets from "../assets"
import { usePage } from "./Page"
import { useEffect } from "react"

export default () => {

    const {setTitle} = usePage()

    useEffect(() => {
        setTitle('403 holders only')
    }, [])

    return <div className="max-w-[1313px]  grid grid-cols-1 md:grid-cols-2 border border-[#D9D9D9] rounded w-4/5 lg:w-3/5 mx-auto m-16" style={{background: 'linear-gradient(102.02deg, rgba(201, 31, 255, 0.15) -27.42%, rgba(3, 206, 255, 0.15) 189.79%)'}}>
        <div className="relative z-20 flex flex-col items-center md:items-start text-center md:text-left py-8 px-2 md:pl-16">
            <h1 className="text-5xl font-bold">SORRY.</h1>
            <h2 className="text-3xl font-bold mt-2">YOUR ACCESS IS RESTRICTED!</h2>
        </div>
        <div className=" relative h-80 md:h-auto">
            <ProgressiveImg src={assets.forbidden.ape} placeholder={assets.forbidden.ape_placeholder} className="absolute bottom-0 md:right-32 z-10 h-56 object-contain object-bottom"/>
        </div>
    </div>
}