import { useGameSessionHistory } from '../api/useGameSessionHistory'
import { GameSessionList } from './GameSessionList'

export const GameSessionHistory = ({ user_id }) => {
    const { sessions, count, fetchNextPage, hasNextPage } = useGameSessionHistory({ user_id })
    return (
        <div className='flex flex-col gap-2 pt-4 overflow-x-auto max-w-full'>
            <div className='text-center border-b border-white/20'>GameSessions: {count}</div>
            <GameSessionList sessions={sessions} />
            {hasNextPage && <button onClick={() => fetchNextPage()}>Load More</button>}
        </div>
    )
}
