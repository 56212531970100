import { Stat, StatLabel, StatNumber } from '~/shared/ui'
import { useStatisticEarningFee } from '../api'

export const StatisticEarningFee = () => {
    const { data, isLoading } = useStatisticEarningFee()
    const keys = {
        total: 'Total',
        today: 'Today',
        week: 'Week',
        month: 'Month',
        hours_24: '24 hours',
        days_7: '7 days',
        days_30: '30 days',
    }
    return (
        <div className='flex flex-col gap-1'>
            <header className='text-xl font-semibold'>Collected Fees</header>
            <small className='text-white/50'>From players earnings</small>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-2 text-center mt-2 w-full'>
                {Object.entries(keys).map(([k, v], i) => (
                    <Stat.Gray key={i} className={i === 0 && 'col-start-1 col-end-[-1]'} loading={isLoading}>
                        <StatLabel className='text-amber-500'>{v}</StatLabel>
                        <StatNumber>{data?.[k]}</StatNumber>
                    </Stat.Gray>
                ))}
            </div>
        </div>
    )
}
