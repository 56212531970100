import { useQuery } from '@tanstack/react-query'
import { JsonRpcError } from '~/types'
import { EarningFeeStatistic } from '../types'

export const useStatisticEarningFee = () => {
    return useQuery<EarningFeeStatistic, JsonRpcError>({
        queryKey: ['statistic.earning_fee'],
        queryFn: async () => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'gameapi.statistic.earnings_fee',
                    params: {},
                }),
            })
            const data = await res.json()
            if (data.error) {
                throw data
            } else {
                return data.result
            }
        },
        retry: false,
    })
}
