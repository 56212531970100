import { useKillRaceHistory } from '../api/useKillRaceHistory'

export const KillRaceHistory = () => {
    const { data, isFetched } = useKillRaceHistory()
    const hasData = data && data.length > 0

    if (!isFetched) return null
    return (
        <div className='flex flex-col gap-4'>
            <header className='text-2xl font-black text-center'>TOURNAMENT HISTORY</header>
            <div className='overflow-x-auto'>
                <table className='font-bold border-separate border-spacing-2 text-center min-w-max w-full'>
                    <thead>
                        <tr className='text-white/50'>
                            <th>START DATE</th>
                            <th>END DATE</th>
                            <th>RANK</th>
                            <th>KILLS</th>
                            <th>SESSIONS</th>
                            <th>K/D</th>
                            <th>EARNED</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!hasData && (
                            <tr className='bg-[#d9d9d9]/10 border-4 border-black'>
                                <td colSpan={7} className='text-center p-2'>
                                    NO DATA
                                </td>
                            </tr>
                        )}
                        {hasData &&
                            data.map((entry, i) => (
                                <tr key={i} className='bg-[#d9d9d9]/10 border-4 border-black'>
                                    <td>{new Date(entry.begin).toLocaleString()}</td>
                                    <td>{new Date(entry.end).toLocaleString()}</td>
                                    <td>{entry.rank}</td>
                                    <td>{entry.kills}</td>
                                    <td>{entry.sessions}</td>
                                    <td>{entry.kill_death_ratio}</td>
                                    <td>${entry.earnings}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <small className='text-white/25 uppercase text-right'>* last 10 tournaments</small>
        </div>
    )
}
