import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StatisticEarningFee } from '~/features/bank/components'
import { Box, Header } from '~/shared/ui'
import { Accounts } from '~/widgets'
import { Connections, Nft } from '~/widgets/statistic'
import { ActivePlayers } from './ActivePlayers'
import { Downloads } from './Downloads'

export const StatisticEntry = ({
    name,
    value,
    brackets_value,
    title,
}: {
    name: any
    value: number
    brackets_value?: number
    title?: string
}) => {
    return (
        <div className='flex flex-col gap-1'>
            <div className='text-sm flex flex-row gap-1 items-start md:items-center justify-center text-center'>
                <div className='opacity-80 tracking-wide'>{name}</div>
                {title && (
                    // <FontAwesomeIcon title={title} icon={faCircleQuestion} className='bg-white text-black outline-white/80 hover:outline-white rounded-full' />
                    <div
                        title={title}
                        className='flex flex-row items-center justify-center rounded-full border border-white/50 hover:border-white p-2 h-4 w-4 text-[0.7em] bg-[rgba(200,200,200,0.1)] cursor-default'
                    >
                        <FontAwesomeIcon icon={faQuestion} />
                    </div>
                )}
            </div>
            <div className='flex flex-col items-center gap-2 bg-[rgba(200,200,200,0.1)] p-3 rounded group-hover:outline outline-zinc-700'>
                <div className='text-2xl min-h-[2rem] font-bold tracking-wide'>
                    {value} {brackets_value !== undefined && <span>({brackets_value})</span>}
                </div>
            </div>
        </div>
    )
}

export const StatisticRow = (props) => {
    return (
        <section
            className={['flex flex-col items-center justify-center gap-3 pt-4 text-center', props.className]
                .filter(Boolean)
                .join(' ')}
        >
            {props.header && (
                <header className='text-white/80 text-xl tracking-widest font-bold'>{props.header}</header>
            )}
            {props.subheader && <header className='text-white/40 text-xs py-2'>{props.subheader}</header>}
            <div
                className={`grid grid-cols-1 md:grid-cols-2 items-center justify-center gap-4 ${
                    props.gridClassName ?? ''
                }`}
            >
                {props.children}
            </div>
        </section>
    )
}

export const Statistic = () => {
    return (
        <Box>
            <header className='uppercase font-bold text-center py-2 text-2xl'>Dashboard</header>
            <Box className='border border-white/10 rounded-lg overflow-hidden py-8'>
                <div className=' bg-custom-gradient-1 h-1 absolute top-0 left-0 w-full'></div>
                <Box className='w-4/5 mx-auto gap-y-8'>
                    <Accounts.Count />
                    <Box.Gray className='w-full'>
                        <Accounts.CountChart days={14} />
                    </Box.Gray>
                    <Accounts.Referrals />
                    <Accounts.ActiveCount />
                    <Accounts.BetaUserCount />
                    <Downloads />
                    <ActivePlayers />
                    <StatisticEarningFee />
                    <Box>
                        <Header>NFTs</Header>
                        <section className='grid grid-cols-2'>
                            <Nft.TokensCount />
                            <Nft.HoldersCount />
                        </section>
                    </Box>
                    <Connections.TotalCount />
                    <Connections.OneCount />
                    <Connections.TwoCount />
                    <Connections.ThreeCount />
                    <Nft.GuildsCount />
                </Box>
            </Box>
        </Box>
    )
}
