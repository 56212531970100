import { useLogout } from '../api'

const icon = new URL('../assets/logout.png?width=128&height=128', import.meta.url).toString()

export const LogoutButton = () => {
    const logout = useLogout()
    return (
        <button
            onClick={() => logout.mutate()}
            className='h-9 w-9 flex flex-row items-center justify-center border rounded-full'
        >
            <img src={icon} alt='logout' className='w-3 h-3 object-contain' />
        </button>
    )
}

export const LogoutButtonMobile = () => {
    const logout = useLogout()
    return <button onClick={() => logout.mutate()}>LOGOUT</button>
}
