import { WalletReadyState } from '@solana/wallet-adapter-base'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '~/components/Page'
import { WalletContext } from '~/components/WalletContext'
import { useUserWalletList } from '~/features/wallet/api/useUserWalletList'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { useWalletMesageSigner } from '~/hooks/useWalletMessageSigner'
import { longStringPreview } from '~/utils/formatter'
import './wallet.css'

export const WalletPage = () => {
    const csrfToken = useCrsfToken()
    const action = () => {
        fetch('/api/account/user/wallet_guide', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            },
        })
    }
    return (
        <Page title='Web3 Wallet' header='Web3 Wallet' className='wallet-page'>
            <div className='wallet-page__logo'></div>
            <header>
                <h1>
                    BR1: INFINITE <span className='break-before'>IS A WEB3 GAME</span>
                </h1>
                <h2>
                    TAKE FULL CONTROL <span className='break-before'>OF YOUR GAME ITEMS.</span>
                </h2>
                <h3>
                    Set up a secure game wallet{' '}
                    <span className='break-before'>to unlock the full power of BR1: INFINITE.</span>
                    <br />
                    Own your items, trade them,
                    <span className='break-before'> or sell them—it's all up to you!</span>
                </h3>
            </header>
            <div className='wallet-page__grid'>
                <input type='checkbox' id='wallet-page__toggle' hidden />
                <div className='wallet-page__lines wallet-page--newbie'></div>
                <div className='wallet-page__lines wallet-page--veteran'></div>
                <label htmlFor='wallet-page__toggle' className='wallet-page__choice wallet-page--newbie'>
                    <div>
                        <h3>NEW TO THIS? NO WORRIES.</h3>
                        <h2>LET’S GET YOU STARTED</h2>
                    </div>
                    <figure>
                        <img src={new URL('me-powered-by.png', import.meta.url).href} />
                    </figure>
                </label>

                <div className='wallet-page__block wallet-page--newbie'>
                    <header>HERE’S EVERYTHING YOU NEED TO KNOW</header>
                    <div className='wallet-page__info-container wallet-page--newbie'>
                        <section className='wallet-page__info'>
                            <header>WHAT MAKES BR1: INFINITE DIFFERENT?</header>
                            <p>
                                In this game, you actually own your items! Unlike other games, your items aren’t stuck
                                in the game — they’re yours to keep, trade, or sell, even outside the game.
                            </p>
                        </section>
                        <section className='wallet-page__info'>
                            <header>WHAT’S A WEB3 WALLET?</header>
                            <p>
                                It’s a secure account where you keep your game items and money. Only you can access it,
                                and you’re in full control. Use it to trade, sell, or simply store your stuff!
                            </p>
                        </section>
                        <section className='wallet-page__info'>
                            <header>GET YOUR GAMING WALLET READY</header>
                            <p>
                                Setting up your game account is quick and easy. We recommend starting with Magic Eden
                                Wallet, our trusted investor & partner. Download it{' '}
                                <a target='_blank' href='https://wallet.magiceden.io/'>
                                    <u>here</u>
                                </a>{' '}
                                to get started.
                            </p>
                        </section>
                        <section className='wallet-page__info'>
                            <header>HOW TO CONNECT YOUR WALLET TO YOUR BR1 ACCOUNT</header>
                            <p>
                                Once your account is ready, you’re good to go! Just select your wallet from the dropdown
                                below, sign the message, and you’re all set to start owning your game items. Welcome to
                                the future of gaming!
                            </p>
                        </section>
                    </div>
                </div>

                <label htmlFor='wallet-page__toggle' className='wallet-page__choice wallet-page--veteran'>
                    <div>
                        <h3>I’M A WEB3 VETERAN</h3>
                        <h2>CONNECT MY WALLET</h2>
                    </div>
                    <figure>
                        <img src={new URL('wallet-grouped.png', import.meta.url).href} />
                    </figure>
                </label>

                <div className='wallet-page__block wallet-page--veteran'>
                    <header>CONNECT YOUR WALLET</header>
                    <div className='wallet-page--veteran'>
                        <WalletContext>
                            <WalletConnect />
                        </WalletContext>
                    </div>
                </div>
            </div>
            <Link to='/download' className='wallet-page__skip-button' onClick={action}>
                FINISHED? START PLAYING
            </Link>
        </Page>
    )
}

const WalletConnect = () => {
    const { wallets, wallet, connected, connecting, select, connect, publicKey } = useWallet()
    const [selected, setSelected] = useState(undefined)
    const [isLedger, setIsLedger] = useState(false)

    const { link, processing } = useWalletMesageSigner({ isLedger })
    const { data: userWallets } = useUserWalletList()
    const connectedWallets = userWallets?.map((wallet) => wallet.pubkey) || []
    const availableWallets = wallets.filter(
        (wallet) =>
            wallet.adapter.readyState === WalletReadyState.Installed ||
            wallet.adapter.readyState === WalletReadyState.Loadable
    )

    const handleSelect = (name) => {
        select(name)
        setSelected(name)
    }

    useEffect(() => {
        if (selected && wallet?.adapter.name == selected) {
            connect().catch(() => setSelected(undefined))
        }
    }, [selected, wallet])

    return (
        <section className='wallet-page__connect-wallet'>
            <div className='wallet-page__choose-wallet'>
                {availableWallets.map((wallet) => (
                    <div
                        className='wallet-page__wallet-icon'
                        onClick={() => handleSelect(wallet.adapter.name)}
                        data-selected={wallet.adapter.name === selected}
                    >
                        <img src={wallet.adapter.icon} />
                    </div>
                ))}
            </div>
            <button
                className='wallet-page__connect-button'
                disabled={!connected || connectedWallets.includes(publicKey?.toBase58())}
                data-connected={connectedWallets.includes(publicKey?.toBase58())}
                onClick={link}
            >
                <span>{longStringPreview(publicKey?.toBase58())}</span>
                {processing
                    ? 'SIGNING...'
                    : connectedWallets.includes(publicKey?.toBase58())
                    ? 'CONNECTED'
                    : connecting
                    ? 'CONNECTING...'
                    : connected
                    ? isLedger
                        ? 'SIGN TX'
                        : 'SIGN MESSAGE'
                    : 'SELECT WALLET'}
            </button>
            <label className='wallet-page__is-ledger' htmlFor='wallet-page__is-ledger__toggle'>
                USING LEDGER?{' '}
                <input
                    type='checkbox'
                    id='wallet-page__is-ledger__toggle'
                    onChange={() => setIsLedger(!isLedger)}
                    disabled={!connected || connectedWallets.includes(publicKey?.toBase58())}
                />
            </label>
        </section>
    )
}
