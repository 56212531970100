import { Link } from 'react-router-dom'

export const LootboxNotFound = () => {
    return (
        <div className='flex flex-col items-center gap-8 font-black w-max mx-auto text-center py-12'>
            <div className='text-xl md:text-4xl'>
                WE CAN’T FIND ANY
                <br /> LOOT BOXES IN YOUR WALLET
            </div>
            <Link
                to='/account#wallets'
                className='bg-br1-gradient p-4 px-10 hover:outline w-4/5 md:w-3/4 whitespace-nowrap'
            >
                ADD ANOTHER WALLET
            </Link>
            <a
                href='https://magiceden.io/marketplace/br1_loot_boxes'
                target='_blank'
                className='bg-[#FF1FC0] p-4 px-10 hover:outline w-4/5 md:w-3/4 whitespace-nowrap'
            >
                SHOP ON MAGIC EDEN
            </a>
        </div>
    )
}
