import { createContext, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { useLogout, useViewer } from '~/features/auth/api'
import api, { AuthUserType } from '../api'
import Forbidden from './Forbidden'
import ForbiddenApes from './ForbiddenApes'
import ForbiddenDroids from './ForbiddenDroids'
import ForbiddenGeneric from './ForbiddenGeneric'

type AuthContextType = {
    auth?: AuthUserType
    setAuth?
    logout: () => void
    checkAuth: () => Promise<void>
}

const AuthContext = createContext<AuthContextType>({
    logout: api.account.logout,
    checkAuth: async () => {},
})

export const AuthProvider = ({ children }) => {
    const viewer = useViewer()
    const logout = useLogout()

    return (
        <AuthContext.Provider
            value={{
                auth: viewer.data as unknown as AuthUserType,
                setAuth: () => undefined,
                logout: logout.mutate,
                checkAuth: () => undefined,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}

export const RequireAuth = (props) => {
    const viewer = useViewer()
    const children = props.children
    const fallback = props.fallback || '/login'

    if (viewer.isLoading) return null
    if (viewer.data?.is_auth === false) return <Navigate to={fallback} replace={true} />
    if (viewer.data?.is_auth === true) {
        if (viewer.data?.email_confirmed == true) {
            return children
        } else {
            return <Navigate to='/activate' replace={true} />
        }
    }
}

export const IsAuth = ({ children }) => {
    const { auth } = useAuth()
    if (auth) return auth?.is_auth === true ? children : null
}

export const IsHolder = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth)
        return auth?.is_ape_holder === true || auth?.is_droid_holder === true ? (
            children
        ) : show403 ? (
            <Forbidden />
        ) : null
}

export const IsApeHolder = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth) return auth?.is_ape_holder === true ? children : show403 ? <ForbiddenApes /> : null
}

export const IsDroidHolder = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth) return auth?.is_droid_holder === true ? children : show403 ? <ForbiddenDroids /> : null
}

export const IsStaff = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth)
        return [auth.is_staff, auth.is_developer, auth.is_marketing, auth.is_executive].some((e) => e) === true ? (
            children
        ) : show403 ? (
            <ForbiddenGeneric />
        ) : null
}

export const IsDeveloper = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth)
        return [auth.is_developer, auth.is_executive].some((e) => e) === true ? (
            children
        ) : show403 ? (
            <ForbiddenGeneric />
        ) : null
}

export const IsMarketing = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth)
        return [auth.is_marketing, auth.is_executive].some((e) => e) === true ? (
            children
        ) : show403 ? (
            <ForbiddenGeneric />
        ) : null
}

export const IsExecutive = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth) return auth?.is_executive === true ? children : show403 ? <ForbiddenGeneric /> : null
}

export const IsMarketingOrDeveloper = ({ children, show403 = false }) => {
    const { auth } = useAuth()
    if (auth)
        return [auth.is_marketing, auth.is_developer, auth.is_executive].some((e) => e) === true ? (
            children
        ) : show403 ? (
            <ForbiddenGeneric />
        ) : null
}
