export const Text = ({ children, ...rest }) => {
    return <div {...rest}>{children}</div>
}

const TextGradient = ({ children, className = undefined }) => {
    return (
        <Text
            className={[
                'text-transparent bg-clip-text',
                'bg-[linear-gradient(269.59deg,#03ceff_-11.05%,#c91fff_52.25%,#00ff90_112.98%)]',
                className,
            ]
                .filter(Boolean)
                .join(' ')}
        >
            {children}
        </Text>
    )
}

Text.Gradient = TextGradient
