{
    "auth": {
        "activate_your_account": "ATIVE SUA CONTA",
        "activation_email_sent": "Email de ativação enviado!",
        "already_have_account": "JÁ TEM UMA CONTA",
        "confirm_password": "CONFIRME A SENHA",
        "do_you_remember_it": "VOCÊ SE LEMBRA DISSO",
        "enter_code": "CONFIRMAR CÓDIGO",
        "forgot_password": "ESQUECEU A SENHA",
        "have_not_account": "NÃO TEM UMA CONTA",
        "layout_header": "SPAWNAR. MATAR. GANHAR. REPETIR.",
        "login": "ENTRE AGORA",
        "login_header": "FAÇA LOGIN PARA COMEÇAR A GANHAR HOJE",
        "password": "SENHA",
        "password_reset_link_sent": "Link de redefinição de senha enviado para o email",
        "resend_code": "REENVIAR CÓDIGO",
        "reset_password": "RECUPERAÇÃO DE SENHA",
        "signup": "INSCREVER-SE",
        "signup_header": "INSCREVA-SE PARA COMEÇAR A GANHAR HOJE",
        "username": "USUÁRIO",
        "verify_your_email": "VERIFIQUE SEU ENDEREÇO DE EMAIL CONFIRMANDO O CÓDIGO DE 4 DÍGITOS ENVIADO PARA"
    },
    "common": {
        "enter": "ENTRAR",
        "reset": "RECUPERAÇÃO"
    },
    "download": {
        "beta": {
            "discord_button": "Nos mande mensagem",
            "email_button": "Nos mande email",
            "header": "Torne-se um Beta Tester",
            "header2": "Para começar a ganhar",
            "tweet_button": "Nos marque no Twitter"
        },
        "header": "BEM VINDO AO BR1: INFINITE",
        "header2": "BETA TESTER"
    },
    "footer": {
        "about": {
            "game_mechanics": "MECÂNICAS DE JOGO",
            "game_modes": "MODOS DE JOGO",
            "header": "SOBRE BR1",
            "news": "FINANCING NEWS",
            "partners_and_investors": "PARCEIROS E\nINVESTIDORES",
            "trailers": "TRAILERS"
        },
        "sign_up": "REGISTRE-SE",
        "social": {
            "header": "JUNTE-SE À NOSSA COMUNIDADE"
        }
    },
    "landing": {
        "as_seen_on": "TAMBÉM VISTO EM",
        "download_now": "BAIXE BETA AGORA",
        "game_modes": {
            "free_for_all": "Free-for-all é um modo livre de riscos no qual jogadores podem entrar no jogo sem pagar nenhuma taxa. Sempre gratuito pra jogar, para que você possa afiar suas habilidades. Ganhe recompensas, pontos de experiência e até fundos para sua conta completando as várias missões dessa modalidade.",
            "infinite": "BR1: INFINITE é o primeiro jogo de tiro \"pay-to-spawn\" e \"kill-to-earn\" do mundo. Pague para entrar no campo de batalha e, para cada jogador que você matar, ganhe a taxa de entrada dele  (pago e medido em USD). Os modos de risco variam de US$ 0,10 a US$ 1 por spawn/eliminação."
        },
        "logo": {
            "header": "MATE PARA GANHAR NO PRIMEIRO FPS DO MUNDO",
            "subheader": "QUE USA DINHEIRO REAL"
        },
        "news": "FINANCING NEWS",
        "operatives": {
            "apes": "APES",
            "apes_description": "ORIGINALMENTE CRIADOS COMO PROJETOS CIENTÍFICOS DESTINADOS A MELHORAR AS CAPACIDADES FÍSICAS, OS MACACOS EVOLUÍRAM PARA ALÉM DO SEU PAPEL INICIAL DE TRABALHADORES MANUAIS E OPERÁRIOS, TORNANDO-SE PARTICIPANTES FASCINANTES E FORMIDÁVEIS NA ARENA BR1",
            "droids": "DROIDS",
            "droids_description": "OS DRÓIDES TELECOMANDADOS SÃO FREQUENTEMENTE UTILIZADOS EM AMBIENTES PERIGOSOS, EXECUTANDO TAREFAS PERIGOSAS DEMAIS PARA UMA INTERVENÇÃO BIOLÓGICA. A SUA COMBINAÇÃO ÚNICA DE PRECISÃO MECÂNICA E ALGORITMOS AVANÇADOS TORNA-OS ADVERSÁRIOS FORMIDÁVEIS NA ARENA.",
            "header": "OPERATIVOS",
            "humans": "HUMANS"
        },
        "partners_and_investors": "PARCEIROS E INVESTIDORES",
        "tabs": {
            "early_access": {
                "header": "JOGUE O BETA",
                "text": "Você chegou cedo! Experimente o Beta de BR1: INFINITE de graça hoje."
            },
            "infinite_royal": {
                "header": "SEM COMEÇO. SEM FINAL.",
                "text_1": "Infinite Royale:",
                "text_2": "Um modo de jogo infinito e sem temporizador.",
                "text_3": "Ação sem limites. Toda intensidade."
            },
            "kill_to_earn": {
                "header": "SPAWNAR. MATAR. GANHAR. REPETIR.",
                "text": "Mergulhe na emocionante dinâmica de risco-recompensa. Pay-to-spawn e kill-to-earn alimenta a ação intensa e de alto risco onde a estratégia se alia à adrenalina."
            },
            "leaderboard": {
                "header": "SUBA NOS RANKINGS",
                "text": "Elimine para ganhar e subir pro topo das tabelas de classificação."
            }
        },
        "trailers": {
            "ape": "TRAILER CINEMATOGRÁFICO DOS APES",
            "droid": "TRAILER CINEMATOGRÁFICO DOS DROIDS",
            "hide": "ESCONDER",
            "playing": "EM REPRODUÇÃO",
            "show": "VER"
        }
    },
    "menu": {
        "login": "ENTRE AGORA",
        "signup": "INSCREVER-SE"
    }
}
