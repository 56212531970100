import { useEffect, useState } from 'react'

type InputOptions = {
    validators?: ((value: string) => string | undefined)[]
}

export const useInput = (initialValue: string, options?: InputOptions) => {
    const [value, setValue] = useState(initialValue)
    const [errors, setErrors] = useState<string[]>([])
    const [hasErrors, setHasErrors] = useState(false)

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    const validateInput = () => {
        const _errors = options?.validators.map((validator) => validator(value)).filter((e) => e)
        setErrors(_errors)
        return _errors?.length === 0
    }

    useEffect(() => {
        setHasErrors(errors?.length > 0)
    }, [errors])

    return {
        value,
        onChange: handleChange,
        validate: validateInput,
        errors,
        error: errors?.[0],
        hasErrors,
        setErrors,
    }
}
