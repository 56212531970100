import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useLayoutEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Icons } from '../assets/icons'
import { useStackableNFTs } from '../hooks/useStakableNFTs'
import { useStakeEpoch } from '../hooks/useStakeEpoch'

type StakeItemProps = {
    nft: NFT
}

export const ASSET_URL = (type, id) => {
    if (['ape', 'droid'].includes(type)) {
        return `https://assets.bravoready.com/br1-infinite/images/webp/min/${type}s/${id}-pfp.webp`
    } else if (type === 'lootbox') {
        return `https://assets.bravoready.com/br1-infinite/images/png/loot-boxes/loot-box.png`
    } else if (type === 'weapon') {
        return `https://assets.bravoready.com/br1-infinite/images/webp/min/weapons/${id}.webp`
    }
}

const itemTitle = (type) => {
    switch (type) {
        case 'lootbox':
            return 'Loot Box'
        case 'weapon':
            return 'Weapon'
        default:
            return 'Operative'
    }
}

export const StakeItem = ({ nft }: StakeItemProps) => {
    const { useStake, useUnstake } = useStackableNFTs()
    const { stake, isStaking } = useStake()
    const { unstake, isUnstaking } = useUnstake()

    const isLoading = isStaking || isUnstaking
    const handleStake = () => (!!nft.staked ? unstake([nft]) : stake([nft]))

    return (
        <div className='flex flex-col gap-2 w-max font-black p-2'>
            <header className='uppercase'>
                {itemTitle(nft.operative_type)} #{nft.operative_id}
            </header>
            <div className='flex flex-col font-black'>
                <div className='relative h-60 w-60'>
                    <StakeImage url={ASSET_URL(nft.operative_type, nft.operative_id)} />
                    <div className='absolute bottom-0 w-full'>
                        <StakeRate rate={nft.stake_rate} />
                    </div>
                </div>
                <NFTStakeProgress nft={nft} />
            </div>

            <button
                disabled={isLoading}
                onClick={handleStake}
                className='flex flex-row gap-x-2 justify-center items-center uppercase p-2 bg-white/10 hover:outline outline-white/50'
            >
                <span>{nft.staked ? 'Remove' : 'Add'}</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </div>
    )
}

export const StakeInfo = ({ url, rate, progress, type }) => {
    return (
        <div className='flex flex-col font-black'>
            <div className='relative h-60 w-60'>
                <StakeImage url={url} />
                <div className='absolute bottom-0 w-full'>
                    <StakeRate rate={rate} />
                </div>
            </div>
        </div>
    )
}

export const StakeImage = ({ url }) => {
    const [imgLoaded, setImgLoaded] = useState(false)
    const onload = () => setImgLoaded(true)

    useLayoutEffect(() => setImgLoaded(false), [url])

    return (
        <div className={twMerge('w-full h-full', !imgLoaded && 'bg-white/10 animate-pulse')}>
            <img
                loading='lazy'
                onLoad={onload}
                src={url}
                className={twMerge(
                    'w-full h-full object-contain opacity-0',

                    imgLoaded && 'opacity-100 transition-opacity duration-500'
                )}
            />
        </div>
    )
}

export const StakeRate = ({ rate }) => {
    if (!rate) return null
    return (
        <div className='w-full flex flex-row gap-2 p-1 items-center bg-black/50'>
            <Icons.SkullPoint className='h-8 w-8 scale-125' />
            <div>{rate} PTS / DAY</div>
        </div>
    )
}

export const StakeProgress = ({ progress, type }) => {
    if (progress == null || !['ape', 'droid'].includes(type)) return <div className='h-10'></div>

    const gradient = (type) => {
        switch (type) {
            case 'ape':
                return `linear-gradient(89deg, rgba(201, 31, 255, 0.75) -15.29%, rgba(0, 0, 0, 0.75) 95.99%)`
            case 'droid':
                return `linear-gradient(89deg, rgba(3, 164, 255, 0.75) -15.29%, rgba(0, 0, 0, 0.75) 95.99%)`
        }
    }
    return (
        <div
            className='w-full flex flex-row gap-2 p-1 items-center bg-black/75'
            style={{
                background: gradient(type),
            }}
        >
            <Icons.Lootbox fill='#fff' className='h-8 w-8  scale-125' />

            <div>{progress} %</div>
        </div>
    )
}

export const NFTStakeProgress = ({ nft }: StakeItemProps) => {
    const { epoch_duration, active } = useStakeEpoch()
    const [epochStaked, setEpochStaked] = useState(nft.epoch_staked)

    const progress = Math.min(Math.floor((epochStaked / (epoch_duration * 0.3)) * 10000) / 100, 100)

    useEffect(() => {
        if (active) {
            const interval = setInterval(() => {
                setEpochStaked((prev) => prev + nft.stake_rate / 60 / 60 / 24)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [])
    if (!active) return null
    return <StakeProgress progress={progress} type={nft.operative_type} />
}
