import { useContext, useEffect, useState } from 'react'
import { Bank } from '~/entities'
import api from '../api'
import ErrorRow from '../components/ErrorRow'
import { jsonrpc } from '~/shared/api/jsonrpc'

const DepositForm = () => {
    const [amount, setAmount] = useState('')
    const [comment, setComment] = useState('')
    const [accept, setAccept] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')

    const onAmountChange = (e) => {
        setAmount(e.target.value)
        setError('')
    }

    const { reloadBalance } = useContext(Bank.Context)

    const deposit = async (e) => {
        e.preventDefault()
        setError(null)

        api.bank
            .depositBonus(amount, comment ?? null)
            .then(() => {
                reloadBalance()
                setAmount('')
                setComment('')
                setAccept(false)
                setSuccess(true)
                setTimeout(() => setSuccess(false), 2000)
            })
            .catch((data) => setError(`Error: ${data.error ?? 'Error'}`))
    }

    return (
        <div className='flex flex-col gap-2 w-max max-w-full mx-auto border border-white border-opacity-20 rounded p-4'>
            <form onSubmit={deposit} className='flex flex-col flex-wrap items-center gap-4 '>
                <header className='text-sm opacity-50'>
                    Deposit <span className='font-bold'>USDC</span>
                </header>
                <input
                    value={amount}
                    onChange={onAmountChange}
                    type='number'
                    min={0}
                    step={0.1}
                    placeholder='amount'
                    className='input-team-tools w-full'
                />
                <textarea
                    placeholder='comment'
                    onChange={(e) => setComment(e.target.value)}
                    maxLength={255}
                    className='input-team-tools w-full text-sm'
                />
                <label className='flex flex-row items-center gap-2 cursor-pointer pt-2'>
                    <input type='checkbox' checked={accept} onChange={(e) => setAccept(e.target.checked)} />
                    <span className='opacity-75 text-sm'>I understand that this action will change BR1 balance</span>
                </label>
                {success ? (
                    <div className=' text-green-600'>Deposited</div>
                ) : (
                    <button
                        disabled={!amount || !accept}
                        className='hover:text-[#C91FFF] disabled:cursor-not-allowed disabled:hover:text-inherit disabled:opacity-20 font-bold'
                    >
                        Deposit
                    </button>
                )}
            </form>

            <ErrorRow message={error} />
        </div>
    )
}

const TransactionsList = () => {
    const initialState = {
        data: [],
        offset: undefined,
        count: undefined,
        has_more: undefined,
        max_id: undefined,
    }
    const [transactions, setTransactions] = useState(initialState)
    const [loading, setLoading] = useState(false)

    const load = async () => {
        setLoading(true)
        jsonrpc('bank.transactions', {
            offset: transactions.offset,
            filters: { operation__in: [1] },
        })
            .then((response) => {
                setTransactions((prev) => ({ ...response.result, data: [...prev.data, ...response.result.data] }))
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <div className='flex flex-col gap-y-4 w-full'>
            <header className='flex flex-row gap-x-2 items-center justify-center'>
                <span className='text-white/20'>Total:</span>
                {transactions.count}
            </header>
            {transactions.data.map((t, i) => (
                <div key={i} className='flex flex-col w-full text-xs'>
                    <span className='text-white/20'>#{i + 1}</span>
                    <div className='flex flex-col gap-y-2 border rounded border-white/20 p-2 font-bold'>
                        <div>
                            <span className='text-white/50 font-normal'>id:</span> {t.id}
                        </div>
                        <div>
                            <span className='text-white/50 font-normal'>account:</span> {t.username}
                        </div>
                        <div>
                            <span className='text-white/50 font-normal'>operation:</span>{' '}
                            <span className='text-green-500'>Deposit</span>
                        </div>
                        <div>
                            <span className='text-white/50 font-normal'>USDC:</span> {t.amount}
                        </div>
                        <div>
                            <span className='text-white/50 font-normal'>created by:</span> {t.initiator}
                        </div>
                        <div>
                            <span className='text-white/50 font-normal'>created at:</span>{' '}
                            {new Date(t.created_at).toLocaleString()}
                        </div>
                        <div>
                            <span className='text-white/50 font-normal'>comment:</span> {t.comment}
                        </div>
                    </div>
                </div>
            ))}
            <footer className='flex flex-row justify-center'>
                {transactions.has_more && <button onClick={load}>LOAD PREVIOUS</button>}
            </footer>
        </div>
    )
}

export const BankDeposit = () => {
    return (
        <div className='flex flex-col items-center gap-8 w-full'>
            <DepositForm />
            <TransactionsList />
        </div>
    )
}
