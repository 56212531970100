import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Validator } from './validators'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faUndo, faUndoAlt } from '@fortawesome/free-solid-svg-icons'

interface TextInputProps {
    type?: string
    placeholder?: string
    defaultValue?: string
    label?: any
    validators?: Validator[]
    onChange?: (value: string) => void
    className?: string
}

export const TextInput = ({
    type = 'text',
    placeholder,
    defaultValue,
    label,
    validators = [],
    onChange,
    className,
}: TextInputProps) => {
    const [readOnly, setReadOnly] = useState(true)
    const [value, setValue] = useState(defaultValue ?? '')

    const onValueChange = (e) => {
        const newValue = e.target.value
        if (validators.every((validator) => validator(newValue))) {
            setValue(newValue)
            onChange?.(newValue)
        }
    }

    return (
        <label className={twMerge('flex flex-col gap-y-1 text-sm overflow-hidden', className)}>
            {label && (
                <div
                    className={twMerge(
                        'flex flex-row items-center gap-x-2 max-w-full w-max',
                        'self-start text-xs text-white/50 font-bold whitespace-nowrap text-ellipsis overflow-hidden text-left'
                    )}
                >
                    {label}
                </div>
            )}
            <input
                type={type}
                placeholder={placeholder}
                readOnly={readOnly}
                onFocus={() => setReadOnly(false)}
                className={twMerge(
                    'border border-white/20 rounded p-1 bg-inherit outline-none focus:border-white/50',
                    'placeholder:text-white/20 placeholder:text-sm',
                    'w-full'
                )}
                value={value}
                onChange={onValueChange}
            />
        </label>
    )
}

export const PasswordInput = ({
    placeholder = 'Password',
    defaultValue,
    label = 'Password',
    validators = [],
    onChange,
}: TextInputProps) => {
    const [visible, setVisible] = useState(false)

    const togglePassword = (e) => {
        e.preventDefault()
        setVisible((prev) => !prev)
    }

    const PasswordLabel = () => {
        return (
            <div className='flex flex-row gap-x-2 items-center'>
                {label}{' '}
                <FontAwesomeIcon
                    icon={visible ? faEye : faEyeSlash}
                    onClick={togglePassword}
                    className='cursor-pointer'
                />
            </div>
        )
    }

    return (
        <TextInput
            type={visible ? 'text' : 'password'}
            placeholder={placeholder}
            defaultValue={defaultValue}
            label={<PasswordLabel />}
            validators={validators}
            className='-webkit-text-security[disc]'
            onChange={onChange}
        />
    )
}
