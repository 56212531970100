import { Icons } from '../assets/icons'

export const StakeDescription = () => {
    return (
        <div className='flex flex-row'>
            <div className='flex flex-col gap-4'>
                <h1 className='text-3xl md:text-7xl font-bold flex flex-row items-center gap-8 select-none'>
                    <span>RENTING HUB</span>
                </h1>
                <div className='text-white/60 text-sm md:text-xl font-semibold flex flex-col gap-8 underline-offset-4'>
                    <div>
                        Renting yields 2 major rewards: <u>BR1 Points</u> & <u>Claimable Items</u>
                    </div>
                    <ol className='list-inside list-disc'>
                        <li>
                            Rent your BR1 NFTs to earn
                            <span className=' whitespace-nowrap'>
                                <Icons.SkullPoint className='inline-block h-10 w-10' /> <u>BR1 Points</u>.
                            </span>
                        </li>
                        <li>
                            Rent your BR1 NFTs to unlock{' '}
                            <span className=' whitespace-nowrap'>
                                <Icons.UnknownReward className='inline-block h-10 w-10 fill-[#00FF90]' />
                                <u>Claimable Items</u>
                            </span>{' '}
                            every renting period.
                        </li>
                    </ol>
                    <small>
                        {' '}
                        *  NFTs must be rented for 30% of the renting period to claim a reward AND remain rented until
                        the <span className='text-[#E38CFF]'>CLAIM DAY</span>.
                    </small>
                </div>
            </div>
        </div>
    )
}
