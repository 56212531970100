import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useReducer, useState } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Chart, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'

enum ActionType {
    Loading = 'Loading',
    Loaded = 'Loaded',
}

const reducer = (state, action) => {
    switch (action.type) {
        case ActionType.Loading:
            return { ...state, loading: true }
        case ActionType.Loaded:
            return { ...state, loading: false, data: action.data }
        default:
            throw new Error('Unknow action')
    }
}

export const Downloads = () => {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        data: {
            total: undefined,
            history: {},
        },
    })

    const loader = async () => {
        dispatch({ type: ActionType.Loading })
        const resp = await jsonrpc('gameapi.statistic.downloads')
        dispatch({ type: ActionType.Loaded, data: resp.result })
    }

    useEffect(() => {
        loader()
    }, [])
    return (
        <Box>
            <Header>Downloads</Header>
            <small className='text-white/50'>
                Based on first User's <span className='text-white'>Login</span> event.
            </small>
            <Box>
                <Stat.Gray loading={state.loading}>
                    <StatLabel className='text-amber-500'>Total</StatLabel>
                    <StatNumber>{state.data.total}</StatNumber>
                </Stat.Gray>
                <div className='grid grid-cols-3 gap-2 mt-2 w-full'>
                    <Stat.Gray loading={state.loading}>
                        <StatLabel className='text-amber-500'>Today</StatLabel>
                        <StatNumber>{state.data.today}</StatNumber>
                    </Stat.Gray>
                    <Stat.Gray loading={state.loading}>
                        <StatLabel className='text-amber-500'>Week</StatLabel>
                        <StatNumber>{state.data.week}</StatNumber>
                    </Stat.Gray>
                    <Stat.Gray loading={state.loading}>
                        <StatLabel className='text-amber-500'>Month</StatLabel>
                        <StatNumber>{state.data.month}</StatNumber>
                    </Stat.Gray>
                    <Stat.Gray loading={state.loading}>
                        <StatLabel className='text-amber-500'>24 Hours</StatLabel>
                        <StatNumber>{state.data['24_hours']}</StatNumber>
                    </Stat.Gray>
                    <Stat.Gray loading={state.loading}>
                        <StatLabel className='text-amber-500'>7 Days</StatLabel>
                        <StatNumber>{state.data['7_days']}</StatNumber>
                    </Stat.Gray>
                    <Stat.Gray loading={state.loading}>
                        <StatLabel className='text-amber-500'>30 Days</StatLabel>
                        <StatNumber>{state.data['30_days']}</StatNumber>
                    </Stat.Gray>
                </div>
            </Box>
            <Box.Gray className='w-full'>
                <DownloadsChart history={state.data.history} />
            </Box.Gray>
        </Box>
    )
}

const DownloadsChart = ({ history = {} }) => {
    const [labels, setLabels] = useState<string[]>([])
    const [data, setData] = useState<number[]>([])

    useEffect(() => {
        setLabels(Object.keys(history))
        setData(Object.values(history))
    }, [history])

    return (
        <Chart.Line
            className='max-h-40'
            options={{
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    tooltip: {
                        mode: 'index',
                    },
                },
                borderColor: 'red',
                scales: {
                    x: {
                        ticks: {},
                        type: 'time',
                        time: {
                            unit: 'day',
                            tooltipFormat: moment.localeData().longDateFormat('L'),
                        },
                    },
                    y: {
                        min: 0,
                        ticks: {
                            precision: 0,
                        },
                    },
                },
            }}
            data={{
                labels,
                datasets: [
                    {
                        label: 'Downloads',
                        data: [...data],
                        borderColor: '#eab308',
                        backgroundColor: '#eab308',
                    },
                ],
            }}
        />
    )
}
