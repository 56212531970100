import { useQuery } from '@tanstack/react-query'
import { KillRaceLog } from '../types'

const API_URL = '/api/game/killrace/history'

export const useKillRaceHistory = () => {
    return useQuery<KillRaceLog[]>({
        queryKey: ['kill_race', 'history'],
        queryFn: async () => {
            const res = await fetch(API_URL)
            return await res.json()
        },
        retry: false,
    })
}
