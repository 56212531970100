import { useContext } from 'react'
import { StakeListContext } from '../context'
import { useStackableNFTs } from '../hooks/useStakableNFTs'
import { Button } from './Button'

export const StakeListFilter = () => {
    const { filter, setFilter } = useContext(StakeListContext)
    const { staked, unstaked } = useStackableNFTs()

    const items: Array<[boolean, string, number]> = [
        [true, 'rented', staked.length],
        [false, 'not rented', unstaked.length],
    ]

    return (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 uppercase font-black'>
            {items.map(([value, label, count]) => {
                const checked = value === filter.staked
                return (
                    <label key={label}>
                        <Button active={checked}>
                            <div
                                className='p-4 text-center'
                                style={{
                                    opacity: checked ? 1 : 0.5,
                                }}
                            >
                                <span>{label} </span>
                                <span>[{count}]</span>
                            </div>
                            <input
                                type='checkbox'
                                checked={checked}
                                onChange={() => setFilter(({ staked, ...rest }) => ({ ...rest, staked: value }))}
                                className='hidden'
                            />
                        </Button>
                    </label>
                )
            })}
        </div>
    )
}
