export const Container = ({ children, className = undefined, ...rest }) => {
    return (
        <div
            className={['flex flex-col p-[1px] overflow-hidden rounded-lg', className].filter(Boolean).join(' ')}
            {...rest}
        >
            <div
                className={['flex-1 flex flex-col  overflow-hidden rounded-lg', 'bg-[rgba(20,18,24,0.85)]']
                    .filter(Boolean)
                    .join(' ')}
            >
                <div className='flex-1 p-4'>{children}</div>
            </div>
        </div>
    )
}

const ContainerGradient = ({ children, className = undefined, ...rest }) => {
    return (
        <Container className={['bg-custom-gradient-1', className].filter(Boolean).join(' ')} {...rest}>
            {children}
        </Container>
    )
}

Container.Gradient = ContainerGradient
