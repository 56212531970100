import { Fragment, useEffect, useState } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { ServerEdit } from './server_edit'

export const Servers = () => {
    const [servers, setServers] = useState([])

    const getServers = async () => {
        const response = await jsonrpc('gameapi.servers', {})
        const servers = response.result
        setServers(servers)
    }

    useEffect(() => {
        getServers()
    }, [])

    return (
        <div className='flex flex-col gap-10'>
            {servers.map((server) => (
                <Fragment key={server.id}>
                    <ServerEdit key={server.id} server={server} />
                    <hr className='border-white/10' />
                </Fragment>
            ))}
        </div>
    )
}
