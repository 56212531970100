import {
    BarElement,
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import 'chartjs-adapter-moment'

ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

export const Chart = {
    Bar,
    Line,
}
