import { faGamepad } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { IsMarketing } from '~/components/Auth'
import { Box, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'
import { History, RaceGameApi, Statistic } from '../api/racegame'
import { QueueConfig } from '../race_game/QueueConfig'

const RaceHistory = () => {
    const [history, setHistory] = useState<Array<History>>([])

    useEffect(() => {
        RaceGameApi.history().then((data) => setHistory(data))
    }, [])

    const headers = ['ID', 'Time', 'Participants', 'Prize in USDC']

    return (
        <div className=' max-w-[100vw] overflow-x-auto px-4'>
            <table className='border-separate border-spacing-y-2 text-sm text-center tracking-wide cursor-default'>
                <thead>
                    <tr className='text-white/80 text-sm'>
                        {headers.map((header) => (
                            <td className='pb-4 px-6' key={header}>
                                {header}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {history.map(({ id, timestamp, participants, prize }, i) => (
                        <tr className='odd:bg-gray-400/10' key={i}>
                            <td>{id}</td>
                            <td>{new Date(timestamp).toLocaleString()}</td>
                            <td>{participants}</td>
                            <td>{prize}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export const Br1Race = () => {
    const [statistic, setStatistic] = useState<Statistic | object>({})

    useEffect(() => {
        RaceGameApi.statistic().then((data) => {
            setStatistic(data)
        })
    }, [])

    const headers = {
        race_total: 'Races',
        participants_total: 'Participants',
        participants_unique: 'Unique Participants',
        prize_sum: 'Total Prizes in USDC',
    }

    return (
        <IsMarketing>
            <Box>
                <header className='uppercase font-bold text-center py-2 text-2xl'>BR1 Race</header>
                <Box className='border border-white/10 rounded-lg overflow-hidden py-8'>
                    <div className=' bg-custom-gradient-1 h-1 absolute top-0 left-0 w-full'></div>
                    <Box className='w-4/5 max-w-full mx-auto gap-y-8 items-center'>
                        <a
                            target='_blank'
                            href='/team-tools/games/br1-race/'
                            className='button-default flex flex-row gap-x-2 my-4 items-center hover:scale-105'
                        >
                            <FontAwesomeIcon icon={faGamepad} />
                            <span>Start Game</span>
                        </a>
                        <QueueConfig />
                        <Header>Statistics</Header>
                        <Box className='grid grid-cols-4 gap-2'>
                            {Object.entries(headers).map(([key, label]) => (
                                <Stat.Gray key={key}>
                                    <StatLabel className='text-amber-500'>{label}</StatLabel>
                                    <StatNumber>{statistic[key]}</StatNumber>
                                </Stat.Gray>
                            ))}
                        </Box>
                        <div className='flex flex-col gap-y-4 pt-4'>
                            <header className='text-xl tracking-widest text-white/80 font-bold text-center'>
                                Latest Races
                            </header>
                            <RaceHistory />
                        </div>
                    </Box>
                </Box>
            </Box>
        </IsMarketing>
    )
}
