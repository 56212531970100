import React, { useState } from 'react'
import api from '~/api'

interface ISignupContext {
    username: string
    setUsername: React.Dispatch<React.SetStateAction<string>>
    usernameError: string
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    emailError: string
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    passwordError: string
    passwordRepeat: string
    setPasswordRepeat: React.Dispatch<React.SetStateAction<string>>
    passwordRepeatError: string
    commonError: string
    signup: (callback: CallableFunction) => Promise<void>
}

const initialContext: ISignupContext = {
    username: undefined,
    setUsername: () => {},
    usernameError: undefined,
    email: undefined,
    setEmail: () => {},
    emailError: undefined,
    password: undefined,
    setPassword: () => {},
    passwordError: undefined,
    passwordRepeat: undefined,
    setPasswordRepeat: () => {},
    passwordRepeatError: undefined,
    commonError: undefined,
    signup: async () => {},
}

export const SignupContext = React.createContext<ISignupContext>(initialContext)

export const SignupProvider = ({ children }) => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordRepeat, setPasswordRepeat] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [usernameError, setUsernameError] = useState<string>()
    const [emailError, setEmailError] = useState<string>()
    const [passwordError, setPasswordError] = useState<string>()
    const [passwordRepeatError, setPasswordRepeatError] = useState<string>()
    const [commonError, setCommonError] = useState<string>()

    const signup = async (callback = undefined) => {
        return api.account
            .signup({ username, email, password1: password, password2: passwordRepeat })
            .then(() => {
                if (callback) callback()
            })
            .catch((errors) => {
                setUsernameError(errors?.username?.[0].message)
                setEmailError(errors?.email?.[0].message)
                setPasswordError(errors?.password1?.[0].message)
                setPasswordRepeatError(errors?.password2?.[0].message)
                setCommonError(errors?.__all__?.[0].message)
            })
    }

    return (
        <SignupContext.Provider
            value={{
                username,
                setUsername,
                email,
                setEmail,
                password,
                setPassword,
                passwordRepeat,
                setPasswordRepeat,
                usernameError,
                emailError,
                passwordError,
                passwordRepeatError,
                commonError,
                signup,
            }}
        >
            {children}
        </SignupContext.Provider>
    )
}
