import { useQuery } from '@tanstack/react-query'
import { SETTINGS } from '../types'

export const useSettings = () => {
    return useQuery<SETTINGS>({
        queryKey: ['settings'],
        queryFn: async () => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'settings',
                    params: {},
                }),
            })
            const response = await res.json()
            if (response.result) {
                return response.result
            }
        },
        retry: false,
        initialData: {
            WITHDRAWAL_ENABLED: false,
        },
    })
}
