import {
    faCheckCircle,
    faCheckToSlot,
    faEye,
    faSpinner,
    faStopwatch,
    faUser,
    faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'
import { GameSessionHistory } from '~/team_tools/features/player/components/GameSessionHistory'
import { useWithdrawCheckFinalized, useWithdrawDelete, useWithdrawProcess, useWithdrawRevokeApprove } from '../api'
import { useWithdrawApprove } from '../api/useWithdrawApprove'
import { useWithdrawDecline } from '../api/useWithdrawDecline'
import { WithdrawRequest as WithdrawRequestType } from '../types'
import { formatWalletAddress } from '../utils'
import { currencies } from './SelectCurrency'
import { WithdrawRequestListContext } from './WithdrawRequestList'

type WithdrawRequestContextType = {
    withdrawRequest: WithdrawRequestType
    details: boolean
    showDetails: React.Dispatch<React.SetStateAction<boolean>>
}

const WithdrawRequestContext = createContext<WithdrawRequestContextType>({
    withdrawRequest: undefined,
    details: false,
    showDetails: () => {},
})

type WithdrawRequestProviderProps = {
    withdrawRequest: WithdrawRequestType
    children: React.ReactNode
}

const WithdrawRequest = ({ withdrawRequest, children }: WithdrawRequestProviderProps) => {
    const [details, showDetails] = useState(false)
    return (
        <WithdrawRequestContext.Provider value={{ withdrawRequest, details, showDetails }}>
            {children}
        </WithdrawRequestContext.Provider>
    )
}

const WithdrawRequestStatus = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const status = withdrawRequest?.status

    const textColor = {
        requested: 'text-white',
        approved: 'text-blue-500',
        processing: 'text-yellow-500',
        finalized: 'text-green-500',
        declined: 'text-red-500',
    }

    const statusIcon = {
        requested: faEye,
        approved: faCheckToSlot,
        processing: faStopwatch,
        finalized: faCheckCircle,
        declined: faWarning,
    }

    return (
        <small className={twMerge('flex flex-row gap-x-1 items-center uppercase', textColor[status])}>
            <span>{status}</span>
            <FontAwesomeIcon icon={statusIcon[status]} />
        </small>
    )
}

const WithdrawRequestStatusForUser = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const status = {
        requested: 'submitted',
        approved: 'processing',
        processing: 'processing',
        finalized: 'completed',
        declined: 'declined',
    }[withdrawRequest?.status]

    const textColor = {
        submitted: 'text-white',
        processing: 'text-yellow-500',
        completed: 'text-green-500',
        declined: 'text-red-500',
    }

    const statusIcon = {
        submitted: faEye,
        processing: faStopwatch,
        completed: faCheckCircle,
        declined: faWarning,
    }

    return (
        <small className={twMerge('flex flex-row gap-x-1 items-center uppercase', textColor[status])}>
            <span>{status}</span>
            <FontAwesomeIcon icon={statusIcon[status]} />
        </small>
    )
}

const WithdrawRequestAmount = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)

    return (
        <div className='flex flex-row gap-1'>
            <div>{withdrawRequest.amount}</div>
            <img src={currencies.find((c) => c.name == withdrawRequest.currency)?.img} className='h-4 w-4' />
        </div>
    )
}

const WithdrawRequestDetailsButton = ({ children }) => {
    const { showDetails } = useContext(WithdrawRequestContext)
    return <button onClick={() => showDetails((prev) => !prev)}>{children}</button>
}

const WithdrawRequestDetailsContainer = ({ children }) => {
    const { details } = useContext(WithdrawRequestContext)
    return details && children
}

const WithdrawRequestDetails = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const tx_date = withdrawRequest.status_history.find((t) => t.status === 'processing')?.created_at
    const tx = withdrawRequest.blockchain_tx
    return (
        <div className='grid grid-cols-[max-content,auto] gap-1 p-2'>
            <div className='text-white/50'>Id:</div>
            <div> {withdrawRequest.uuid}</div>
            <div className='text-white/50'>To:</div>
            <div> {formatWalletAddress(withdrawRequest.wallet)}</div>
            {tx_date && <div className='col-span-2 text-white/30 mt-2'>{new Date(tx_date).toLocaleString()}</div>}
            {tx && (
                <>
                    <div className='text-white/50'>Tx:</div>
                    <a
                        target='_blank'
                        href={withdrawRequest.blockchain_tx_url}
                        className={twMerge(
                            'hover:underline underline-offset-2 w-max',
                            { processing: 'text-yellow-500', finalized: 'text-green-500' }[withdrawRequest.status]
                        )}
                    >
                        {formatWalletAddress(withdrawRequest.blockchain_tx)}
                    </a>
                </>
            )}
        </div>
    )
}

const WithdrawRequestDeleteButton = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const { mutate, error, isLoading } = useWithdrawDelete()
    const remove = () => {
        mutate({ uuid: withdrawRequest.uuid })
    }
    return (
        <button
            onClick={remove}
            className='flex flex-row gap-1 items-center p-1 m-1 border border-white/10 rounded hover:border-white/50 underline-offset-2 w-max'
        >
            <div className='text-red-500 uppercase font-bold'>Delete</div>
            {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        </button>
    )
}

const WithdrawRequestApproveButton = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const { mutate, isLoading } = useWithdrawApprove()
    const [comment, setComment] = useState<string>('')

    const approve = () => {
        mutate(
            { uuid: withdrawRequest.uuid, comment },
            { onSuccess: () => toast.success('Request approved'), onError: (error) => toast.error(error.error.message) }
        )
    }
    return (
        <div className='flex flex-row gap-2 p-2 w-full'>
            <button
                onClick={approve}
                className='flex flex-row gap-1 items-center p-1 m-1 border border-white/10 rounded hover:border-white/50 underline-offset-2 w-max disabled:opacity-50'
            >
                <div className='text-green-500 uppercase font-bold'>Approve</div>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
            <input
                type='text'
                className='bg-black text-white border border-white/20 rounded px-2 flex-1'
                placeholder='Comment...'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
        </div>
    )
}

const WithdrawRequestDeclineButton = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const { mutate, isLoading } = useWithdrawDecline()
    const [comment, setComment] = useState<string>('')

    const decline = () => {
        mutate(
            { uuid: withdrawRequest.uuid, comment },
            { onSuccess: () => toast.success('Request declined'), onError: (error) => toast.error(error.error.message) }
        )
    }
    return (
        <div className='flex flex-row gap-2 p-2 w-full'>
            <button
                onClick={decline}
                className='flex flex-row gap-1 items-center p-1 m-1 border border-white/10 rounded hover:border-white/50 underline-offset-2 w-max disabled:opacity-50'
            >
                <div className='text-red-500 uppercase font-bold'>Decline</div>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
            <input
                type='text'
                className='bg-black text-white border border-white/20 rounded px-2 flex-1'
                placeholder='Decline reason'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
        </div>
    )
}

const WithdrawRequestRevokeApproveButton = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const { mutate, isLoading } = useWithdrawRevokeApprove()
    const revoke = () => {
        mutate(
            { uuid: withdrawRequest.uuid },
            { onSuccess: () => toast.info('Request revoked'), onError: (error) => toast.error(error.error.message) }
        )
    }
    return (
        <button
            onClick={revoke}
            className='flex flex-row gap-1 items-center p-1 m-1 border border-white/20 rounded hover:border-white/50 underline-offset-2 w-max'
        >
            <div className='text-blue-500 uppercase font-bold'>Revoke</div>
            {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        </button>
    )
}

const WithdrawRequestProcessButton = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const { mutate, isLoading } = useWithdrawProcess()

    const approve = () => {
        mutate(
            { uuid: withdrawRequest.uuid },
            {
                onSuccess: () => toast.success('Request processed'),
                onError: (error) => toast.error(error.error.message),
            }
        )
    }
    return (
        <button
            onClick={approve}
            className='flex flex-row gap-1 items-center p-1 m-1 border border-white/10 rounded hover:border-white/50 underline-offset-2 w-max'
        >
            <div className='text-yellow-500 uppercase font-bold'>Pay now</div>
            {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        </button>
    )
}

const WithdrawRequestCheckFinalizedButton = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const { mutate, isLoading } = useWithdrawCheckFinalized()

    const approve = () => {
        mutate(
            { uuid: withdrawRequest.uuid },
            {
                onSuccess: () => toast.success('Request finalized'),
                onError: (error) => toast.error(error.error.message),
            }
        )
    }
    return (
        <button
            onClick={approve}
            className='flex flex-row gap-1 items-center p-1 m-1 border border-white/10 rounded hover:border-white/50 underline-offset-2 w-max'
        >
            <div className='text-green-500 uppercase font-bold'>Check finalized</div>
            {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        </button>
    )
}

const WithdrawRequestUserTemplate = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const deleteable = withdrawRequest.status === 'requested'
    return (
        <div className='flex flex-col font-bold tracking-wide shadow-sm'>
            <small className='text-white/30'>{new Date(withdrawRequest.created_at).toLocaleString()}</small>
            <div className='flex flex-col border border-white/10'>
                <WithdrawRequest.DetailsButton>
                    <div className='flex flex-row justify-between bg-white/5 hover:bg-white/10 p-1'>
                        <WithdrawRequest.StatusForUser />
                        <WithdrawRequest.Amount />
                    </div>
                </WithdrawRequest.DetailsButton>
                <WithdrawRequest.DetailsContainer>
                    <small>
                        <WithdrawRequest.Details />
                        {deleteable && <WithdrawRequest.DeleteButton />}
                    </small>
                </WithdrawRequest.DetailsContainer>
            </div>
        </div>
    )
}

const WithdrawRequestAdminTemplate = () => {
    const { withdrawRequest } = useContext(WithdrawRequestContext)
    const { setFilters } = useContext(WithdrawRequestListContext)
    const approvable = withdrawRequest.status === 'requested'
    const revokeable = withdrawRequest.status === 'approved'
    const approve = withdrawRequest.status_history.find((t) => t.status === 'approved')
    const processable = withdrawRequest.status === 'approved'
    const not_finalized = withdrawRequest.status === 'processing'

    const payoutError = withdrawRequest.payout_error

    const filterHistory = () => {
        setFilters((prev) => ({ ...prev, username: withdrawRequest.created_by }))
    }
    return (
        <div className='flex flex-col font-bold tracking-wide shadow-sm'>
            <div className='flex flex-row gap-1 items-center hover:underline cursor-pointer' onClick={filterHistory}>
                <FontAwesomeIcon icon={faUser} className='opacity-50' />
                <div>{withdrawRequest.created_by}</div>
            </div>
            <small className='text-white/30'>{new Date(withdrawRequest.created_at).toLocaleString()}</small>
            <div className='flex flex-col border border-white/10'>
                <WithdrawRequest.DetailsButton>
                    <div className='flex flex-row justify-between bg-white/5 hover:bg-white/10 p-1'>
                        <div className='flex flex-row gap-1 items-center'>
                            <WithdrawRequest.Status />
                            {payoutError && <FontAwesomeIcon icon={faWarning} className='text-red-500' />}
                        </div>
                        <WithdrawRequest.Amount />
                    </div>
                </WithdrawRequest.DetailsButton>
                <WithdrawRequest.DetailsContainer>
                    <small>
                        <WithdrawRequest.Details />
                        {withdrawRequest.approve_comment && (
                            <div className='p-2 text-white/50'>Comment: {withdrawRequest.approve_comment}</div>
                        )}

                        <div className='flex flex-row items-center'>
                            {approvable && <WithdrawRequest.ApproveButton />}
                            {approvable && <WithdrawRequestDeclineButton />}
                            {revokeable && <WithdrawRequest.RevokeApproveButton />}
                            {processable && <WithdrawRequestProcessButton />}
                            {/* {not_finalized && <WithdrawRequestCheckFinalizedButton />} */}
                        </div>
                    </small>
                    {payoutError && (
                        <div className='flex flex-col gap-1 p-2'>
                            <div className='text-red-500'>Payout error</div>
                            <div className='overflow-x-auto'>{payoutError}</div>
                        </div>
                    )}
                    <div className='p-2'>
                        <GameSessionHistory user_id={withdrawRequest.user_id} />
                    </div>
                </WithdrawRequest.DetailsContainer>
            </div>
            {approve ? (
                <small
                    className='flex flex-row items-center gap-1 self-end'
                    title={new Date(approve.created_at).toLocaleString()}
                >
                    <span className='text-white/30'>approved by:</span>
                    <span className='text-white'>{approve.created_by}</span>
                </small>
            ) : (
                <small>&nbsp;</small>
            )}
        </div>
    )
}

WithdrawRequest.Amount = WithdrawRequestAmount
WithdrawRequest.ApproveButton = WithdrawRequestApproveButton
WithdrawRequest.RevokeApproveButton = WithdrawRequestRevokeApproveButton
WithdrawRequest.DeleteButton = WithdrawRequestDeleteButton
WithdrawRequest.DetailsContainer = WithdrawRequestDetailsContainer
WithdrawRequest.DetailsButton = WithdrawRequestDetailsButton
WithdrawRequest.Details = WithdrawRequestDetails
WithdrawRequest.Status = WithdrawRequestStatus
WithdrawRequest.StatusForUser = WithdrawRequestStatusForUser
WithdrawRequest.AdminTemplate = WithdrawRequestAdminTemplate
WithdrawRequest.UserTemplate = WithdrawRequestUserTemplate

export { WithdrawRequest }
