import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { useViewer } from '~/features/auth'
import { useSettings } from '~/features/settings'
import { Icons } from '~/features/stake/assets/icons'
import { useModal } from '~/hooks/useModal'
import { useBalance } from '../api'
import { DepositWithdrawModal } from './DepositWithdrawModal'

const coinIcon = new URL('../assets/coin.png', import.meta.url).toString()

export const BalanceMenuWidget = () => {
    const { data: viewer } = useViewer()
    const { data: settings } = useSettings()
    const { data: balance, isLoading } = useBalance()
    const { setModal } = useModal()
    const modalId = useMemo(() => Math.random().toString(36), [])

    const showModal = () => {
        settings.WITHDRAWAL_ENABLED && viewer?.email_confirmed && setModal(<DepositWithdrawModal action={'deposit'} />)
    }

    return (
        <div className='flex flex-col md:flex-row-reverse  gap-y-6 gap-x-4 md:gap-y-1 font-bold'>
            <button
                id={modalId}
                title='Deposit/Withdraw'
                onClick={showModal}
                className='group flex flex-row-reverse items-center gap-2 p-2 md:p-0 bg-black rounded-3xl'
            >
                <div className='flex-1 text-center md:text-right pr-12 md:pr-0 group-hover:underline underline-offset-2'>
                    {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : balance?.totalInGame || 0}
                </div>
                <div className='w-10 h-8'>
                    <img src={coinIcon} alt='coin' className='mx-auto h-full' />
                </div>
            </button>
            <div title='Points' className='flex flex-row-reverse items-center gap-2 p-2 md:p-0 bg-black rounded-3xl'>
                <div className='flex-1 text-center md:text-right pr-12 md:pr-0'>
                    {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : balance?.point?.toFixed(2) || 0}
                </div>
                <div className='w-10 h-10 flex flex-row items-center'>
                    <Icons.SkullPoint className='mx-auto' />
                </div>
            </div>
        </div>
    )
}
