import { twMerge } from 'tailwind-merge'
import { useCurrency } from '../hooks'
import { useDepositWithdraw } from '../hooks/useDepositWithdraw'
import { Currency } from '../types'

const lockImg = new URL('../assets/lock.png', import.meta.url).toString()

export const currencies: Currency[] = [
    {
        name: 'usdc-spl',
        img: new URL('../assets/usdc.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #2775CA -42.57%, rgba(39, 117, 202, 0) 296.63%)',
        decimals: 2,
        blockchain: 'solana',
        deposit: true,
        deposit_type: 1,
    },
    {
        name: 'solana',
        img: new URL('../assets/solana.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #4E4E4E -42.57%, rgba(109, 110, 221, 0) 296.63%)',
        disabled: true,
        deposit: true,
        deposit_type: 2,
        decimals: 4,
    },
    {
        name: 'ethereum',
        img: new URL('../assets/ethereum.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #1E5CFF -42.57%, rgba(39, 96, 244, 0) 296.63%)',
        disabled: true,
    },
    {
        name: 'usdc-erc',
        img: new URL('../assets/usdc.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #2775CA -42.57%, rgba(39, 117, 202, 0) 296.63%)',
        disabled: true,
    },
    {
        name: 'bitcoin',
        img: new URL('../assets/bitcoin.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #F7931A -42.57%, rgba(247, 147, 26, 0) 296.63%)',
        disabled: true,
    },
    {
        name: 'matic',
        img: new URL('../assets/matic.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #8347E6 -42.57%, rgba(131, 71, 230, 0) 296.63%)',
        disabled: true,
    },
    {
        name: 'aptos',
        img: new URL('../assets/aptos.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #65FEEA -42.57%, rgba(23, 100, 60, 0) 296.63%)',
        disabled: true,
    },
    {
        name: 'binance',
        img: new URL('../assets/binance.png', import.meta.url).toString(),
        gradient: 'linear-gradient(83deg, #F7C61A -42.57%, rgba(247, 212, 26, 0) 296.63%)',
        disabled: true,
    },
]

const CurrencyButton = ({ children, icon, gradient, disabled, onClick }) => {
    return (
        <button disabled={disabled} className='relative flex flex-col gap-y-2 items-center group' onClick={onClick}>
            <div
                className={twMerge('relative py-5 px-6', !disabled && 'group-hover:outline')}
                style={{ backgroundImage: gradient }}
            >
                <div className='h-16 w-16 flex flex-col items-center justify-center'>{icon}</div>
                {disabled && (
                    <img
                        src={lockImg}
                        className='absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[15%]'
                    />
                )}
            </div>
            {children}
            {disabled && <div className='absolute top-0 left-0 w-full h-full bg-black/75'></div>}
        </button>
    )
}

export const SelectCurrency = () => {
    const { setCurrency } = useCurrency()
    const { action } = useDepositWithdraw()
    return (
        <>
            {currencies.map(({ name, img, decimals, gradient, disabled, deposit, deposit_type }) => (
                <CurrencyButton
                    icon={<img src={img} className='' />}
                    disabled={(action === 'deposit' && deposit !== true) || (action === 'withdraw' && disabled)}
                    key={name}
                    gradient={gradient}
                    onClick={() => setCurrency({ name, img, decimals, gradient, disabled, deposit, deposit_type })}
                >
                    <div className='uppercase text-xs font-bold'>{name}</div>
                </CurrencyButton>
            ))}
        </>
    )
}
