import { faSpinner, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as token from '@solana/spl-token'
import { useWallet } from '@solana/wallet-adapter-react'
import { Connection, PublicKey } from '@solana/web3.js'
import { useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Copyable } from '~/components/ui/Copyable'
import { formatWalletAddress } from '~/features/bank/utils'
import { useDepositOptions } from '../api/useDepositOptions'
const usdcIcon = new URL('~/features/bank/assets/usdc.png', import.meta.url).toString()
const solIcon = new URL('~/features/bank/assets/solana.png', import.meta.url).toString()

// Fractal wallet not support transaction signing in same way as other wallet, so it excluded in this selector.

export const WalletSelector = () => {
    const {
        data: { cluster_url, token_mint },
    } = useDepositOptions()
    const { wallet, wallets, select, publicKey } = useWallet()
    const [selected, setSelected] = useState()
    const [walletUsdcAmount, setWalletUsdcAmount] = useState<number>(undefined)
    const [walletSolAmount, setWalletSolAmount] = useState<number>(undefined)
    const connection = useMemo(() => {
        if (cluster_url) return new Connection(cluster_url, 'confirmed')
    }, [cluster_url])

    const selectWallet = (name) => {
        setSelected(name)
        select(name)
        setWalletUsdcAmount(undefined)
    }

    useEffect(() => {
        if (wallet && wallet.adapter.name == selected) {
            wallet.adapter.connect().catch((e) => setSelected(undefined))
        }
    }, [wallet, selected])

    useEffect(() => {
        if (publicKey && token_mint) {
            token.getAssociatedTokenAddress(new PublicKey(token_mint), publicKey).then(async (ata) => {
                const accountData = await token.getAccount(connection, ata, 'confirmed')
                const amount = Number(accountData.amount) / 1_000_000
                setWalletUsdcAmount(amount)
            })
            connection.getBalance(publicKey).then((balance) => {
                setWalletSolAmount(balance / 1_000_000_000)
            })
        }
    }, [publicKey, token_mint])

    return (
        <section className='flex flex-col gap-2 items-center font-bold max-w-lg w-full'>
            <div className='flex flex-row items-center gap-2 w-full'>
                <FontAwesomeIcon icon={faWallet} className='h-6 w-6 px-2' />
                <div className='flex-1 flex flex-row items-center justify-center bg-white/5 p-1 pr-10'>
                    {wallets
                        .filter((w) => w.adapter.name !== 'Fractal')
                        .map((w, i) => (
                            <button
                                key={i}
                                className={twMerge(
                                    'relative p-2 border rounded',
                                    w.adapter.name == selected ? 'border-white/20' : ' border-transparent'
                                )}
                                onClick={() => selectWallet(w.adapter.name)}
                            >
                                <img src={w.adapter.icon} className='h-6 w-6' />
                                {w.adapter.name == selected && wallet.adapter.connecting && (
                                    <div className='absolute top-0 left-0 w-full h-full bg-black/50 flex flex-row items-center justify-center'>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    </div>
                                )}
                            </button>
                        ))}
                </div>
            </div>
            <div className='text-xs h-4 text-white/80'>
                {publicKey && (
                    <Copyable value={publicKey.toString()}>{formatWalletAddress(publicKey.toString())}</Copyable>
                )}
            </div>
            <div className=' flex flex-row gap-2 text-xs h-4 text-white/80'>
                {walletUsdcAmount && (
                    <div className='flex flex-row gap-1 items-center'>
                        <span>{walletUsdcAmount.toFixed(2)}</span>
                        <img src={usdcIcon} className='h-4 w-4' />
                    </div>
                )}
                {walletSolAmount && (
                    <div className='flex flex-row gap-1 items-center'>
                        <span>{walletSolAmount.toFixed(4)}</span>
                        <img src={solIcon} className='h-4 w-4' />
                    </div>
                )}
            </div>
        </section>
    )
}
