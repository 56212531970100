import { useQuery } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { JsonRpcError } from '~/types'

const API_URL = '/team-tools/api/player/session_kills'

export const useGameSessionKills = ({ session_id }) => {
    const csrf = useCrsfToken()
    return useQuery<GameSession[], JsonRpcError>({
        queryKey: [API_URL, session_id],
        queryFn: async () => {
            const res = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrf,
                },
                body: JSON.stringify({
                    session_id,
                }),
            })
            const data = await res.json()
            return data
        },
        retry: false,
        initialData: [],
    })
}
