import { getCookie } from './../utils/cookie'

const csrftoken = (): string => {
    return getCookie('csrftoken') ?? ''
}

const createURL = (url: string, options) => {
    url = url + '?'
    if (options?.startFilters) url += `&start_filters=${encodeURIComponent(JSON.stringify(options.startFilters))}`
    if (options?.filters) url += `&filters=${encodeURIComponent(JSON.stringify(options.filters))}`
    if (options?.offset) url += `&offset=${options.offset}`
    if (options?.pageSize) url += `&page_size=${options.pageSize}`
    return url
}

const fetchGet = async ({ url, headers }: { url: string; headers?: any }) => {
    return fetch(url, {
        method: 'GET',
        headers: headers,
    })
}

const fetchPost = async ({ url, headers, body }: { url: string; headers?: any; body?: any }) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'X-CSRFToken': csrftoken(),
            ...headers,
        },
        body: JSON.stringify(body),
    })
    if (response.ok) {
        if (response.headers.get('Content-Type') == 'text/csv') {
            const filename = response.headers.get('Content-Disposition').split('filename=')[1]
            const blob = await response.blob()
            const fileUrl = window.URL.createObjectURL(blob)
            const fileLink = document.createElement('a')
            fileLink.href = fileUrl
            fileLink.download = filename
            fileLink.click()
            return
        }
        return response.json()
    } else throw response.json()
}

const BASE_URL = '/team-tools/api'

// Statistic

export type StatisticType = {
    total: number
    activated: number
    today: number
    today_activated: number
    week: number
    week_activated: number
    month: number
    month_activated: number
    beta_keys: number
    beta_requests: number
    discords: number
    twitters: number
    wallets_with_operative: number
    wallets_without_operative: number
    only_wallets_with_operative: number
    only_wallets_with_operative_and_beta: number
    only_wallets_without_operative: number
    only_wallets_without_operative_and_beta: number
    only_discord: number
    only_discord_and_beta: number
    only_twitter: number
    only_twitter_and_beta: number
    only_wallets_with_operative_and_discord: number
    only_wallets_with_operative_and_discord_and_beta: number
    only_wallets_with_operative_and_twitter: number
    only_wallets_with_operative_and_twitter_and_beta: number
    only_wallets_without_operative_and_discord: number
    only_wallets_without_operative_and_discord_and_beta: number
    only_wallets_without_operative_and_twitter: number
    only_wallets_without_operative_and_twitter_and_beta: number
    only_discord_and_twitter: number
    only_discord_and_twitter_and_beta: number
    only_wallets_with_operative_and_discord_and_twitter: number
    only_wallets_with_operative_and_discord_and_twitter_and_beta: number
    only_wallets_without_operative_and_discord_and_twitter: number
    only_wallets_without_operative_and_discord_and_twitter_and_beta: number
    nft: number
    nft_linked: number
    holders: number
    holders_linked: number
    referral_visits: number
    referral_signups: number
    guilds: {
        diamond: { total: number; registered: number }
        platinum: { total: number; registered: number }
        gold: { total: number; registered: number }
    }
}

class Statistic {
    async statistic(): Promise<StatisticType> {
        const response = await fetch(`${BASE_URL}/statistic`)
        return await response.json()
    }
}

// Team

export type TeamUser = {
    id: number
    username: string
    email: string
    last_login: string
    is_staff: boolean
    is_developer: boolean
    is_marketing: boolean
    is_executive: boolean
}

class Team {
    async members(): Promise<Array<TeamUser>> {
        const response = await fetch(`${BASE_URL}/team/members`)
        return await response.json()
    }
}

// Api inspector

export type AccessLogEntry = {
    id: number
    path: string
    request: object
    ip: string
    status: number
    response: object
    timestamp: string
}

class ApiInspector {
    async accessLog(options): Promise<{ total: number; filtered: number; entries: Array<AccessLogEntry> }> {
        const response = await fetch(createURL(`${BASE_URL}/access-log`, options))
        return await response.json()
    }

    // async login(last_id?): Promise<Array<GameAccessEntry>> {
    //     const response = await fetch(`${BASE_URL}/api-inspector/login${last_id ? '?last_id=' + last_id : ''}`)
    //     return await response.json()
    // }

    // async signup(last_id?): Promise<Array<GameAccessEntry>> {
    //     const response = await fetch(`${BASE_URL}/api-inspector/signup${last_id ? '?last_id=' + last_id : ''}`)
    //     return await response.json()
    // }

    // async join(last_id?): Promise<Array<GameAccessEntry>> {
    //     const response = await fetch(`${BASE_URL}/api-inspector/join${last_id ? '?last_id=' + last_id : ''}`)
    //     return await response.json()
    // }

    // async match(last_id?): Promise<Array<GameAccessEntry>> {
    //     const response = await fetch(`${BASE_URL}/api-inspector/match${last_id ? '?last_id=' + last_id : ''}`)
    //     return await response.json()
    // }
}

// Closed BETA

export type ClosedBetaKey = {
    id: number
    key: string
    type: number
    created_at: string
    created_by_id: number
    created_by_username: string
    comment: string
    max_usage: number
    usage: number
    auto_accept: boolean
}

export type ClosedBetaRequest = {
    id: number
    user_id: number
    user_username: string
    type: number
    created_at: string
    status: number
    status_changed_by_id: number
    status_changed_at: string
    comment: string
    discord: string
    twitter: string
    wallets_count: number
    apes_count: number
    droids_count: number
    key: string
}

export type ClosedBetaUser = {
    id: number
    user_id: number
    user_username: string
    key_id: number
    key_value: string
    source: number
    accepted_by_id: number
    accepted_by_username: string
    created_at: string
    discord: string
    twitter: string
    wallets_count: number
    apes_count: number
    droids_count: number
}

class ClosedBeta {
    async keys(offset = 0, filters = {}): Promise<{ total: number; filtered: number; keys: Array<ClosedBetaKey> }> {
        const response = await fetch(
            `${BASE_URL}/closed_beta/keys?offset=${offset}&filters=${encodeURIComponent(JSON.stringify(filters))}`
        )
        return await response.json()
    }

    async generateKey(key, max_usage = null, auto_accept = false, comment = null): Promise<void> {
        const response = await fetch(`${BASE_URL}/closed_beta/keys`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ key, max_usage, auto_accept, comment }),
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }

    async deleteKey(id): Promise<void> {
        const response = await fetch(`${BASE_URL}/closed_beta/keys`, {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ id }),
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }

    // async requests(offset = 0, filters = {}): Promise<{total: number, filtered: number, requests: Array<ClosedBetaRequest>}> {
    //     const response = await fetch(`${BASE_URL}/closed_beta/requests?offset=${offset}&filters=${encodeURIComponent(JSON.stringify(filters))}`)
    //     return await response.json()
    // }

    async requests(query?, headers?): Promise<{ total: number; filtered: number; list: Array<User> }> {
        return fetchPost({
            url: `${BASE_URL}/closed_beta/requests`,
            headers: {
                ...headers,
            },
            body: { query },
        })
    }

    async acceptRequest(id, settings = {}, with_airdrop = false): Promise<{ id: number; key: string }> {
        const response = await fetch(`${BASE_URL}/closed_beta/requests`, {
            method: 'PUT',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ id, ...settings, with_airdrop }),
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }

    async rejectRequest(id): Promise<{ id: number; key: string }> {
        const response = await fetch(`${BASE_URL}/closed_beta/requests`, {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ id }),
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }

    // async users(offset = 0, filters = {}): Promise<{total: number, filtered: number, users: Array<ClosedBetaUser>}> {
    //     const response = await fetch(`${BASE_URL}/closed_beta/users?offset=${offset}&filters=${encodeURIComponent(JSON.stringify(filters))}`)
    //     return await response.json()
    // }

    async users(query?, headers?): Promise<{ total: number; filtered: number; list: Array<User> }> {
        return fetchPost({
            url: `${BASE_URL}/closed_beta/users`,
            headers: {
                ...headers,
            },
            body: { query },
        })
    }

    async rejectUser(id): Promise<{ id: number; key: string }> {
        const response = await fetch(`${BASE_URL}/closed_beta/users`, {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ id }),
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }
}

// Bank

export type Balance = {
    symbol: string
    amount: string
}

export type Transaction = {
    id: number
    operation: string
    amount: number
    currency: string
    created_at: string
    created_by_id: number
    created_by_username: string
}

export type Account = {
    id: number
    username: string
    currencies: Array<Balance>
}

export type Airdrop = {
    id: number
    target: string
    target_user: string
    currency: string
    amount: string
    created_at: string
    created_by_id: number
    confirmed_at: string
    confirmed_by_id: number
    canceled_at: string
    canceled_by_id: number
    created_by_username: string
    confirmed_by_username: string
    canceled_by_username: string
    deadline: string
}

class Bank {
    async transactions(options): Promise<{ total: number; filtered: number; transactions: Array<Transaction> }> {
        const response = await fetch(createURL(`${BASE_URL}/bank/transactions`, options))
        return await response.json()
    }

    async depositBonus(amount, comment = null): Promise<{ transaction: number }> {
        const response = await fetch(`${BASE_URL}/bank/deposit/bonus`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ amount, comment }),
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }

    async accounts(options): Promise<{ total: number; filtered: number; accounts: Array<Account> }> {
        const response = await fetch(createURL(`${BASE_URL}/bank/accounts`, options))
        return await response.json()
    }

    async airdrops(options): Promise<{ total: number; filtered: number; airdrops: Array<Airdrop> }> {
        const response = await fetch(createURL(`${BASE_URL}/bank/airdrop/list`, options))
        return await response.json()
    }

    async airdropCreate(target_id: number, amount: string, username: string, comment: string): Promise<Airdrop> {
        const response = await fetch(`${BASE_URL}/bank/airdrop/create`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({ target_id, amount, username, comment }),
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }

    async airdropConfirm(id: number): Promise<Airdrop> {
        const response = await fetch(`${BASE_URL}/bank/airdrop/${id}`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }

    async airdropCancel(id: number): Promise<Airdrop> {
        const response = await fetch(`${BASE_URL}/bank/airdrop/${id}`, {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
        })
        if (response.ok) return response.json()
        else throw await response.json()
    }
}

// Ban tool

export type BanLogEntry = {
    id: number
    is_active: boolean
    type: number
    reason: number
    banned_at: string
    banned_by: string
    banned_until: string
    is_permanent: boolean
    ban_comment: string
}

export type BannedUser = {
    id: number
    username: string
    is_banned: boolean
    ban: Array<BanLogEntry>
}

export type BanRequest = {
    user_id: number
    reason: number
    is_permanent: boolean
    banned_until: string
    comment: string
}

class BanTool {
    async user(username: string): Promise<BannedUser> {
        const response = await fetch(`${BASE_URL}/ban-tool/user?username=${username}`)
        if (response.ok) return await response.json()
        else throw new Error(response.status === 404 ? 'User not found' : 'Api server error')
    }

    async ban(request: BanRequest): Promise<boolean> {
        const response = await fetch(`${BASE_URL}/ban-tool/user`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify(request),
        })

        return response.ok
    }
}

// Accounts

export type User = {
    ape_holder: boolean
    apes: number
    applied_for_beta: boolean
    beta_key: string
    beta_tester: boolean
    date_joined: string
    discord: string
    discord_connected: boolean
    droid_holder: boolean
    droids: number
    email: string
    id: number
    is_active: boolean
    referral_signups: number
    referral_visits: number
    twitter: string
    twitter_connected: boolean
    usdc: string
    username: string
    wallet_connected: boolean
    wallets: number
    referrals?: Array<any>
    wallet_list: Array<{ pubkey: string; name: string }>
}

export type UserRoleAction = {
    user_id: number
    role_name: string
    result: string
}

class Accounts {
    async users(query?, headers?): Promise<{ total: number; filtered: number; list: Array<User> }> {
        return fetchPost({
            url: `${BASE_URL}/accounts/users`,
            headers: {
                ...headers,
            },
            body: { query },
        })
    }

    async user(query, headers?): Promise<User> {
        return fetchPost({
            url: `${BASE_URL}/accounts/user`,
            headers: {
                ...headers,
            },
            body: { query },
        })
    }

    async setRole({ user_id, role_name, action }): Promise<UserRoleAction> {
        return fetchPost({
            url: `/api/account/role`,
            body: { user_id, role_name, action },
        })
    }
}

class RaceGame {
    async queueAdd({ query, queue = 'default' }): Promise<boolean> {
        const response = await fetch('/api/race/queue', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({queue, query}),
        })

        return response.ok
    }

    async queueDelete({ query, queue = 'default' }): Promise<boolean> {
        const response = await fetch('/api/race/queue', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': csrftoken(),
            },
            body: JSON.stringify({queue, query}),
        })

        return response.ok
    }
}

export default {
    statistic: new Statistic(),
    team: new Team(),
    api_inspector: new ApiInspector(),
    closed_beta: new ClosedBeta(),
    bank: new Bank(),
    ban_tool: new BanTool(),
    accounts: new Accounts(),
    racegame: new RaceGame(),
}
