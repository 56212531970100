import { IsMarketingOrDeveloper } from '~/components/Auth'
import { Box } from '~/shared/ui'
import { Servers } from './servers/servers'

export const ServerControl = () => {
    return (
        <IsMarketingOrDeveloper>
            <Box>
                <header className='uppercase font-bold text-center py-2 text-2xl'>Server Control</header>
                <Box className='border border-white/10 rounded-lg overflow-hidden py-8'>
                    <div className=' bg-custom-gradient-1 h-1 absolute top-0 left-0 w-full'></div>
                    <div className='p-4 pl-12'>
                        <Servers />
                    </div>
                </Box>
            </Box>
        </IsMarketingOrDeveloper>
    )
}
