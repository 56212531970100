import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { LogoutButton, LogoutButtonMobile, UserMenuItem, UserMenuItemMobile } from '~/features/auth'
import { BalanceMenuWidget } from '~/features/bank'
import { Button } from '~/shared/ui'
import assets from '../assets'
import { IsAuth, IsStaff, useAuth } from './Auth'
import { usePage } from './Page'

export default () => {
    const { auth, logout } = useAuth()
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
    const { header } = usePage()
    const { t } = useTranslation()
    const mobileMenu = useRef<HTMLDivElement>()

    const links: Array<[string, string, any]> = [
        ['BR1', '/', Fragment],
        ['Litepaper', 'https://litepaper.br1game.com/', Fragment],
        ['Collection', '/collection', Fragment],
        // [t('menu.Rentals'), 'https://rent.br1game.com/', Fragment],
        ['Download', '/download', Fragment],
        ['Dashboard', '/dashboard', IsAuth],
        ['Tournaments', '/kill-race', IsAuth],
        ['Loot boxes', '/loot-boxes', IsAuth],
        ['Renting', '/renting', IsAuth],
        // ['Data Tools', '/data-tools', IsDroidHolder],
        ['Team Tools', '/team-tools', IsStaff],
    ]

    const toggleMobileMenu = () => {
        setMobileMenuVisible((prev) => !prev)
    }

    useEffect(() => {
        if (mobileMenuVisible) {
            if (mobileMenu.current) mobileMenu.current.scrollTo(0, 0)
        }
    }, [mobileMenuVisible])

    return (
        <div id='menu' className='fixed md:block h-16 md:h-auto top-0 md:relative z-50 mx-auto w-full'>
            <div className='absolute w-full h-full bg-[linear-gradient(91.26deg,#C91FFF_22.57%,#03CEFF_99.93%)] blur-[32px] md:blur-[16px]'></div>
            <div className='relative  bg-black'>
                <div className='relative flex flex-row items-center gap-x-4 lg:gap-x-6 py-2 md:py-4 pl-2 pr-10 md:pr-2 lg:px-10 max-w-[1920px] mx-auto '>
                    <NavLink to='/'>
                        <img src={assets.br1_skull_small} className='w-14 h-14 aspect-square object-contain' />
                    </NavLink>
                    <div className='hidden md:flex flex-row flex-wrap gap-x-4 gap-y-2 lg:gap-x-5'>
                        {links.map(([title, url, CheckPermission], i) => (
                            <CheckPermission key={i}>
                                {url.startsWith('http') ? (
                                    <a
                                        href={url}
                                        target='blank'
                                        className='text-base md:text-sm lg:text-base font-bold whitespace-nowrap uppercase hover:text-[#C91FFF]'
                                    >
                                        {title}
                                    </a>
                                ) : (
                                    <NavLink
                                        to={url}
                                        // end
                                        className={({ isActive }) =>
                                            `text-base md:text-sm lg:text-base font-bold whitespace-nowrap uppercase ${
                                                isActive ? 'border-b-4 block' : 'hover:text-[#C91FFF]'
                                            }`
                                        }
                                    >
                                        {title}
                                    </NavLink>
                                )}
                            </CheckPermission>
                        ))}
                    </div>
                    <div className='md:hidden uppercase font-bold text-center flex-1 md:flex-auto'>{header}</div>

                    {/* <Search className='hidden md:flex text-xs' /> */}

                    <div className='hidden md:block flex-1'></div>
                    {auth?.is_auth && (
                        <div className='hidden md:block'>
                            <BalanceMenuWidget />
                        </div>
                    )}

                    <div className='hidden md:block'>
                        {!auth?.is_auth === true ? (
                            <NavLink to='/login' className='hidden md:block text-base uppercase'>
                                <Button.Gradient>
                                    {t('auth.signup')} / {t('auth.login')}
                                </Button.Gradient>
                            </NavLink>
                        ) : (
                            <div className='hidden lg:flex flex-row gap-x-4'>
                                <UserMenuItem />
                                <LogoutButton />
                            </div>
                        )}
                    </div>

                    <button className='md:hidden cursor-default' onClick={toggleMobileMenu}>
                        <FontAwesomeIcon
                            icon={mobileMenuVisible ? faPlus : faBars}
                            className={`text-3xl ${mobileMenuVisible ? 'rotate-45' : ''}`}
                        />
                    </button>
                </div>
            </div>

            <div
                ref={mobileMenu}
                className={`overscroll-contain md:hidden fixed top-16 overflow-x-hidden overflow-y-auto transition-all duration-500 w-full ${
                    mobileMenuVisible ? 'h-full' : 'h-0'
                } bg-[linear-gradient(149.79deg,#C91FFF_13.94%,#03CEFF_91.37%)]`}
            >
                <div className='flex flex-col gap-6 w-3/4 mx-auto mt-16 mb-32 min-h-[calc(100%-11rem)]'>
                    {links.map(([title, url, CheckPermission], i) => (
                        <CheckPermission key={i}>
                            {url.startsWith('http') ? (
                                <a
                                    href={url}
                                    className='text-lg font-bold text-center uppercase border rounded-3xl py-2'
                                >
                                    {title}
                                </a>
                            ) : (
                                <NavLink
                                    onClick={() => setMobileMenuVisible(false)}
                                    to={url}
                                    end
                                    className={({ isActive }) =>
                                        `text-lg font-bold text-center uppercase border rounded-3xl py-2 ${
                                            isActive ? 'bg-[rgba(255,255,255,0.25)]' : ''
                                        }`
                                    }
                                >
                                    {title}
                                </NavLink>
                            )}
                        </CheckPermission>
                    ))}
                    {/* <Search className='mt-2 border-0 h-12 text-sm' compact={false} /> */}
                    {!auth?.is_auth === true ? (
                        <NavLink
                            onClick={() => setMobileMenuVisible(false)}
                            to='/login'
                            className='text-base bg-black p-3 rounded-3xl border border-slate-500 font-bold text-center'
                        >
                            LOGIN
                        </NavLink>
                    ) : (
                        <>
                            <div onClick={() => setMobileMenuVisible(false)}>
                                <UserMenuItemMobile />
                            </div>
                            <div onClick={() => setMobileMenuVisible(false)}>
                                <BalanceMenuWidget />
                            </div>
                            <LogoutButtonMobile />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
