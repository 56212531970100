type ButtonProps = {
    children: React.ReactNode
    active?: boolean
}

export const Button = ({ children, active }: ButtonProps) => {
    return (
        <div
            className='cursor-pointer select-none'
            style={{
                backgroundImage:
                    'linear-gradient(109deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.44) 50%, rgba(255, 255, 255, 1) 89%)',
            }}
        >
            <div className='bg-black/50 p-[1px] hover:bg-black/20'>
                <div
                    className='bg-black'
                    style={{
                        backgroundImage:
                            !!active &&
                            `linear-gradient(93deg, rgba(3, 206, 255, 0.65) -81.42%, rgba(201, 31, 255, 0.65) 120.74%)`,
                    }}
                >
                    <div className='bg-black/60'>{children}</div>
                </div>
            </div>
        </div>
    )
}
