import { useCallback, useEffect, useState } from 'react'
import { Icons } from '../assets/icons'
import { usePoints } from '../hooks/usePoints'
import { useStackableNFTs } from '../hooks/useStakableNFTs'
import { useStakeEpoch } from '../hooks/useStakeEpoch'
import { BorderGradient } from './BorderGradient'

export const RewardInfo = () => {
    return (
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8'>
            <CurrentEpoch />
            <FutureEpoch />
            <Countdown />
        </div>
    )
}

const CurrentEpoch = () => {
    const { is_started, end, begin, epoch_id } = useStakeEpoch()
    return (
        <div className='flex flex-col gap-4'>
            <header className='text-lg md:text-xl font-black'>
                {is_started ? `CURRENT EPOCH ${epoch_id} REWARDS` : 'NEXT EPOCH REWARDS'}:{' '}
            </header>
            <BorderGradient>
                <div className='flex flex-col gap-8 p-4 '>
                    <StakePoints />
                    <div className='flex flex-row flex-wrap lg:flex-nowrap gap-8 items-center'>
                        <div className='flex-1'>
                            <LootBox />
                        </div>
                        {/* <div className='w-full lg:w-auto'>
                            <ClaimButton />
                        </div> */}
                    </div>
                </div>
            </BorderGradient>
        </div>
    )
}

const StakePoints = () => {
    const { epochPoints } = usePoints()
    const { staked } = useStackableNFTs()

    const stakeRate = staked.reduce((acc, nft) => acc + nft.stake_rate, 0)
    const displayPoints = Math.floor(epochPoints * 10 ** 2) / 10 ** 2

    return (
        <div className='flex flex-row items-center gap-4'>
            <Icons.SkullPoint className='h-14 w-16' />
            <div className='flex flex-col flex-1'>
                <small className='uppercase text-white/30'>Earned in current epoch</small>

                <div className='flex flex-row items-center gap-2'>
                    <span className='text-2xl font-black'>{displayPoints.toLocaleString('en-US')}</span>
                    <small>POINTS</small>
                </div>
                <small className='text-white/50 font-bold'>{stakeRate} TOTAL PTS / DAY</small>
            </div>
        </div>
    )
}

const LootBox = () => {
    const count = 0
    return (
        <div className='flex flex-row items-center gap-4'>
            <Icons.SkullPoint className='h-14 w-16' />

            <div className='flex flex-col flex-1'>
                <small className='uppercase text-white/30'>Reward in the end of epoch</small>

                <div className='flex flex-row items-center gap-2'>
                    <span className='text-2xl font-black'>{count}</span>
                    <small className='whitespace-nowrap'>POINTS</small>
                </div>
            </div>
        </div>
    )
}

const ClaimButton = () => {
    return (
        <div
            className='flex flex-col items-center justify-center text-xs py-2 px-6 select-none whitespace-nowrap'
            style={{ background: 'linear-gradient(89deg, #363636 -15.29%, #191919 109.16%)' }}
        >
            <div className='text-white/60 font-bold'>AIRDROP</div>
        </div>
    )
}

const FutureEpoch = () => {
    return (
        <div className='flex flex-col gap-4'>
            <header className='text-lg md:text-xl font-black'>FUTURE EPOCH REWARDS:</header>
            <BorderGradient className='flex-1'>
                <div className='flex flex-col gap-8 p-4'>
                    <div className='flex flex-row items-center gap-4'>
                        <Icons.UnknownReward className='h-14 w-16' />
                        <div className='text-xl font-black flex-1'>???</div>
                        <small className='text-white/50 font-bold'>REVEALS 10/2024</small>
                    </div>
                    <div className='flex flex-row items-center gap-4'>
                        <Icons.UnknownReward className='h-14 w-16' />

                        <div className='text-xl font-black flex-1'>???</div>
                        <small className='text-white/50 font-bold'>REVEALS 12/2024</small>
                    </div>
                </div>
            </BorderGradient>
        </div>
    )
}

const Countdown = () => {
    const { begin, end } = useStakeEpoch()
    const is_started = begin < new Date()
    return (
        <div className='flex flex-col gap-4'>
            <header className='text-lg md:text-lg font-black md:mx-auto'>
                {is_started ? (
                    <>
                        TIME UNTIL ITEM <span className='text-[#E38CFF]'>CLAIM DAY</span>
                    </>
                ) : (
                    <>TIME UNTIL NEXT REWARD IS REVEALED</>
                )}
            </header>
            <div className='w-full text-sm md:text-xl'>
                <CountdownTimer to={is_started ? end : begin} />
            </div>
        </div>
    )
}

const CountdownTimer = ({ to }) => {
    const [parts, setParts] = useState([0, 0, 0, 0])
    const [days, hours, minutes, seconds] = parts

    const timer = useCallback(() => {
        const diff = new Date(to).getTime() - new Date().getTime()
        if (diff > 0) {
            setParts([
                Math.floor(diff / (1000 * 60 * 60 * 24)),
                Math.floor(diff / (1000 * 60 * 60)) % 24,
                Math.floor(diff / (1000 * 60)) % 60,
                Math.floor(diff / 1000) % 60,
            ])
        } else setParts([0, 0, 0, 0])
    }, [])

    useEffect(() => {
        const interval = setInterval(timer, 1000)
        return () => clearInterval(interval)
    }, [])

    return (
        <BorderGradient>
            <div className='flex flex-row gap-4 md:gap-8 px-8 py-4 text-center font-bold'>
                <DatePart label={'DAYS'}>{days.toString().padStart(2, '0')}</DatePart>
                <DatePart label={'HOURS'}>{hours.toString().padStart(2, '0')}</DatePart>

                <DatePart label={'MINUTES'}>{minutes.toString().padStart(2, '0')}</DatePart>
                <DatePart label={'SECONDS'}>{seconds.toString().padStart(2, '0')}</DatePart>
            </div>
        </BorderGradient>
    )
}

const DatePart = ({ children, label }) => {
    return (
        <div className='flex flex-col gap-2 '>
            <div className='font-black'>{children}</div>
            <small className='text-white/50'>{label}</small>
        </div>
    )
}
