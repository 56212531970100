import { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { Page } from '~/components/Page'
import { useViewer } from '~/features/auth'

const iconDiscord = new URL('../assets/icon_discord.png', import.meta.url).href
const iconX = new URL('../assets/icon_x.png', import.meta.url).href
const iconEmail = new URL('../assets/icon_email.png', import.meta.url).href

const DownloadButtons = () => {
    const { data: viewer } = useViewer()
    const clientURL = `${window.location.origin}/api/game/download`

    const isBr1Player = viewer.roles.includes('BR1 Player')

    const analytics = (e) => {
        try {
            if (viewer?.is_auth) {
                // @ts-ignore
                window.gtag('event', 'download', {
                    event_category: 'account',
                    event_label: viewer?.username,
                    user_id: viewer?.username,
                })
            }
        } catch {}
    }

    return isBr1Player ? (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8 self-center w-4/5 max-w-max text-xl font-extrabold uppercase'>
            <a
                href={clientURL}
                onClick={analytics}
                className='flex flex-row items-center p-4 gap-8 bg-[linear-gradient(142deg,#03CEFF_0%,#C91FFF_100%)] hover:outline'
            >
                <img src='/media/assets/icons/windows.webp' className='h-10 w-10 object-contain' />
                <div>windows download</div>
            </a>
            <div className='flex flex-row items-center p-4 gap-8'>
                <img src='/media/assets/icons/apple.webp' className='h-10 w-10 object-contain' />
                <div className='text-white/30'>mac os coming soon</div>
            </div>
        </div>
    ) : (
        <div className='text-center'>{/* Please contact us to get your <b>BR1 Player</b> role.{' '} */}</div>
    )
}

const Marquee = ({ className }) => {
    return (
        <div className={twMerge('relative h-10 text-sm font-extrabold bg-black', className)}>
            <div className='absolute inset-0 bg-gradient-to-r from-emerald-600 to-emerald-950 blur-lg'></div>
            <div className='absolute inset-0  bg-gradient-to-r from-emerald-600 to-emerald-950 overflow-hidden'>
                {['animate-marquee', 'animate-marquee2'].map((cn) => (
                    <div
                        key={cn}
                        className={twMerge('absolute top-0 bottom-0 flex flex-row items-center whitespace-nowrap ', cn)}
                    >
                        {Array(3)
                            .fill(['play for free', 'kill to earn', 'spawn for free'])
                            .flat()
                            .map((value, i) => (
                                <Fragment key={i}>
                                    <span className='uppercase mx-4'>{value}</span>
                                    <span>-</span>
                                </Fragment>
                            ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

const ClientSpecs = () => {
    const specs = {
        gpu: ['4 GB', '6 GB'],
        cpu: ['i5/AMD RYZEN 5', 'i7/AMD RYZEN 7'],
        ram: ['4 GB', '8 GB'],
        os: ['WINDOWS 10', 'WINDOWS 11'],
        hdd: ['20 GB', '100 GB'],
    }
    return (
        <div className='grid grid-cols-[1fr,4fr] text-[0.6rem] font-bold text-center whitespace-nowrap mx-auto w-max md:text-xl bg-black'>
            <div className='grid grid-cols-1'>
                {['specs', ...Object.keys(specs)].map((item) => (
                    <div
                        key={item}
                        className='
                        border-[0.5rem] border-black relative uppercase py-4 px-2 md:px-8 md:py-8
                        before:absolute before:inset-0 before:border-[0.5px]
                        '
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div>
                <div className='grid grid-cols-2 bg-[linear-gradient(120deg,rgba(3,206,255,0.25)_0%,rgba(201,31,255,0.25)_100%)]'>
                    {['minimum', 'recommended'].map((item) => (
                        <div
                            key={item}
                            className='relative p-4 border-[0.5rem] border-black overflow-hidden uppercase md:p-8 bg-black/50 before:absolute before:inset-0 before:border-[0.5px]'
                        >
                            {item}
                        </div>
                    ))}
                </div>
                <div className='grid grid-cols-2 bg-[linear-gradient(160deg,#03CEFF_0%,#C91FFF_100%)]'>
                    {Object.values(specs).map((item, index) => (
                        <Fragment key={index}>
                            <div className='relative p-4 border-[0.5rem] border-black overflow-hidden uppercase md:p-8 bg-black/70'>
                                {item[0]}
                            </div>
                            <div className='relative p-4 border-[0.5rem] border-black overflow-hidden uppercase md:p-8 bg-black/30'>
                                {item[1]}
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const DownloadPage = () => {
    const { t } = useTranslation()

    const BR1_CINEMATIC_VIDEO = '/media/video/BR1_cinematic_gameplay'
    const [muted, setMuted] = useState(true)
    const vref = useRef(null)
    const { data: viewer } = useViewer()
    const isBr1Player = viewer.roles.includes('BR1 Player')

    return (
        <Page title='BR1 GAME DOWNLOAD' header='Download'>
            <div className='flex flex-col py-16 gap-y-16 lg:py-24  w-full animate-fade'>
                <div className='self-center w-4/5 uppercase font-black text-center'>
                    <h1 className='text-4xl lg:text-5xl'>
                        {isBr1Player ? t('download.header') : t('download.beta.header')}
                    </h1>
                    <h2 className='text-5xl lg:text-6xl text-green-400'>
                        {isBr1Player ? t('download.header2') : t('download.beta.header2')}
                    </h2>
                </div>
                {isBr1Player ? <DownloadButtons /> : <RequestAccess />}
                <section className='relative flex flex-col'>
                    <Marquee className='absolute top-0 w-full z-10' />
                    <div className='w-full h-[min(1080px,100vw/16*9)] bg-black' ref={vref}>
                        <video
                            className='w-full'
                            autoPlay
                            loop
                            muted={muted}
                            controls={false}
                            playsInline
                            onClick={() => {
                                setMuted((prev) => !prev)
                            }}
                        >
                            <source src={`${BR1_CINEMATIC_VIDEO}.webm`} type='video/webm; codecs="vp9"' />
                            <source src={`${BR1_CINEMATIC_VIDEO}.mp4`} type='video/mp4' />
                        </video>
                    </div>
                    <div className='absolute top-0 bg-gradient-to-b from-black to-black/0 h-[15vw] w-full pointer-events-none'></div>
                    <div className='absolute bottom-0 bg-gradient-to-t from-black to-black/0 h-[15vw] w-full pointer-events-none'></div>
                    <div className='absolute left-0 bg-gradient-to-r from-black to-black/0 w-[15vw] h-full pointer-events-none'></div>
                    <div className='absolute right-0 bg-gradient-to-l from-black to-black/0 w-[15vw] h-full pointer-events-none'></div>
                </section>
                <ClientSpecs />
            </div>
        </Page>
    )
}

const RequestButton = ({ icon, children }) => {
    return (
        <div className='flex flex-row items-center gap-x-4 border p-6 hover:bg-[linear-gradient(141deg,rgba(201,31,255,0.50)_-11.37%,rgba(3,206,255,0.50)_133.98%)]'>
            <img src={icon} className='h-8 w-10 object-contain' />
            <div className='flex-1 text-lg lg:text-xl font-bold whitespace-nowrap'>{children}</div>
        </div>
    )
}

const RequestAccess = () => {
    const { t } = useTranslation()
    return (
        <div className='grid grid-cols-1 lg:grid-cols-3 text-center lg:text-left gap-4 p-4 mx-auto uppercase'>
            <a href='https://discord.gg/ready' target='_blank'>
                <RequestButton icon={iconDiscord}>{t('download.beta.discord_button')}</RequestButton>
            </a>
            <a href={`https://x.com/intent/tweet?text=${requestText()}`} target='_blank'>
                <RequestButton icon={iconX}>{t('download.beta.tweet_button')}</RequestButton>
            </a>
            <a href={`mailto:accounts@br1game.com?subject=${requestSubject()}&body=${requestText()}`} target='_blank'>
                <RequestButton icon={iconEmail}>{t('download.beta.email_button')}</RequestButton>
            </a>
        </div>
    )
}

const requestSubject = () => {
    return encodeURIComponent('Beta Access request')
}

const requestText = () => {
    return encodeURIComponent(
        [
            'Hey @BR1INFINITE 👋',
            "I'm eagerly looking to play BR1: INFINITE and join the game as a beta tester 🔥",
            'Give me a player role so I can start killing to earn for free 💸',
        ].join('\n\n')
    )
}
