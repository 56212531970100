import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { useUser } from '~/features/auth/api/useUser'
import { useKillRace, useLeaderboard } from '../api'

export const RaceLeaderboard = ({ type = undefined, begin = undefined, mode = undefined, pageSize = 100 }) => {
    const { data: user } = useUser()
    const { data: race_info } = useKillRace({ type })
    const { data } = useLeaderboard({
        begin: race_info.begin,
        end: race_info.end,
        mode: mode,
        kill_required: true,
        page_size: pageSize,
        order_by: 'rank',
        filters: {},
    })
    const { data: user_data } = useLeaderboard({
        begin: race_info.begin,
        end: race_info.end,
        mode: mode,
        kill_required: true,
        page_size: 1,
        order_by: 'rank',
        filters: { user_id: user?.id },
    })

    const career = useMemo(() => user_data?.pages[0]?.data[0], [user_data])

    return (
        <table className='font-bold border-separate border-spacing-y-2 w-full'>
            <thead className='sticky top-0 z-10 hidden lg:table-header-group'>
                <tr className='bg-black h-12 text-xs font-bold'>
                    <th className='w-12'></th>
                    <th className='text-white/50 text-left pr-4 border-l-8 border-black'>PLAYER</th>
                    <th className='text-white/50 text-left pr-4'>KILLS</th>
                    <th className='text-white/50 text-left pr-4'>SESSIONS</th>
                    <th className='text-white/50 text-left pr-4'>K/D</th>
                    <th className='text-white/50 text-center'>EARNINGS</th>
                    {race_info?.prize > 0 && <th className='text-white/50 text-center'>POTENTIAL REWARD</th>}
                </tr>
                {career && (
                    <>
                        <Row
                            data={career}
                            race_info={race_info}
                            className='bg-black lg:h-12'
                            style={{
                                backgroundImage:
                                    'linear-gradient(99deg, rgba(201, 31, 255, 0.50) -2.44%, rgba(3, 206, 255, 0.50) 100%)',
                            }}
                        />

                        <tr>
                            <td className=' h-5'></td>
                        </tr>
                    </>
                )}
            </thead>
            <tbody className='text-sm'>
                {!data ? (
                    <LoadingRows />
                ) : (
                    data?.pages.map((page, i) => {
                        if (page.data.length == 0)
                            return (
                                <tr key={i}>
                                    <td colSpan={100} className='text-center text-white/50 py-4 bg-white/5'>
                                        NO SESSIONS DATA
                                    </td>
                                </tr>
                            )
                        else
                            return page.data.map((user, j) => (
                                <Row
                                    key={j}
                                    pos={j}
                                    data={user}
                                    race_info={race_info}
                                    className='lg:h-10 bg-white/5'
                                    style={{
                                        backgroundImage: [
                                            'linear-gradient(100deg, rgba(50, 42, 0, 0.50) -8.17%, rgba(255, 214, 0, 0.00) 112.69%)',
                                            'linear-gradient(100deg, rgba(183, 183, 183, 0.50) -8.17%, rgba(135, 135, 135, 0.00) 112.69%)',
                                            'linear-gradient(100deg, rgba(41, 22, 0, 0.50) -8.17%, rgba(125, 68, 0, 0.00) 112.69%)',
                                        ][j],
                                    }}
                                />
                            ))
                    })
                )}
            </tbody>
        </table>
    )
}

const Row = ({ data, race_info, className = undefined, style = undefined, pos = undefined }) => {
    const potential_reward = race_info?.prize > 0 ? race_info?.pay_strategy?.[data?.rank - 1] : undefined
    return (
        <tr
            className={twMerge(
                'flex flex-col p-1 gap-2 my-4 border border-white/20',
                'lg:table-row lg:h-10 lg:p-0 lg:gap lg:border-0',
                className
            )}
            style={style}
        >
            <td className=''>
                <div
                    className={twMerge(
                        'lg:h-10 lg:w-12 flex flex-row lg:items-center lg:justify-center',
                        [0, 1, 2].includes(pos) && 'border border-white/20'
                    )}
                >
                    {data?.rank}
                </div>
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left lg:border-l-8 border-black lg:px-2 before:content-["PLAYER:_"] lg:before:content-none'>
                {data?.username}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left before:content-["KILLS:_"] lg:before:content-none text-white/50 select-none'>
                {data?.kills}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left before:content-["SESSIONS:_"] lg:before:content-none text-white/50 select-none'>
                {data?.sessions}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left before:content-["K/D:_"] lg:before:content-none text-white/50 select-none'>
                {data?.kill_death_ratio}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell lg:text-center before:content-["EARNINGS:_"] lg:before:content-none text-white/50 select-none'>
                {data?.earnings}
            </td>
            {potential_reward && (
                <td className='grid grid-cols-2 gap-2 lg:table-cell lg:text-center before:content-["POTENTIAL_REWARD:_"] lg:before:content-none'>
                    {potential_reward && `$${potential_reward}`}
                </td>
            )}
        </tr>
    )
}

const LoadingRows = () => {
    return Array.from({ length: 100 }).map((_, i) => (
        <Row key={i} data={undefined} race_info={undefined} className='lg:h-10 bg-white/5 animate-pulse' />
    ))
}
