import { useTranslation } from 'react-i18next'
import { Text } from '~/shared/ui'

export const AuthContainerSuccess = ({
    children,
    header = 'auth:signup_success_header',
    className = undefined,
    ...rest
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={[
                'flex flex-col gap-4 text-center p-8',
                'bg-neutral-900 bg-[url(/src/js/shared/assets/img/bg.signup_success.png?as=webp)] bg-cover bg-no-repeat bg-[center_right]',
                'rounded-xl border',
                'w-4/5 md:w-3/4 lg:w-3/5 mx-auto overflow-hidden',
                className,
            ]
                .filter(Boolean)
                .join(' ')}
            {...rest}
        >
            <h1 className='text-3xl lg:text-5xl font-bold'>
                <Text.Gradient>{t(header)}</Text.Gradient>
            </h1>
            <h2 className='text-xl font-semibold'>{children}</h2>
        </div>
    )
}
