import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '~/components/Auth'
import { Auth } from '~/entities'
import { Button, InputText } from '~/shared/ui'
import { PasswordResetContext, PasswordResetProvider } from './context'

export const PasswordResetForm = ({ className = undefined, onReset = undefined }) => {
    const { auth } = useAuth()

    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        if (auth?.is_auth) navigate('/account')
    }, [auth])

    return (
        <PasswordResetProvider>
            <PasswordResetContext.Consumer>
                {({ email, setEmail, emailError, commonError, reset }) => (
                    <Auth.Container className={className}>
                        <InputText
                            name='email'
                            type='email'
                            value={email ?? ''}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t('auth:email').toUpperCase()}
                        />
                        <span className='uppercase text-xs text-red-500 -mt-1'>
                            {emailError || commonError || <>&nbsp;</>}
                        </span>

                        <Button.Gradient onClick={() => reset(onReset)} className='uppercase text-xl font-semibold'>
                            {t('auth:login')}
                        </Button.Gradient>
                        <div className='flex flex-col gap-2 pt-4 uppercase text-[0.65rem] font-semibold'>
                            <div>
                                <span>{t('auth:remember_password')} </span>
                                <NavLink to='/login' className='underline hover:no-underline'>
                                    {t('auth:login')}
                                </NavLink>
                            </div>
                        </div>
                    </Auth.Container>
                )}
            </PasswordResetContext.Consumer>
        </PasswordResetProvider>
    )
}
