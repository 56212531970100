import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { StakeListContext } from '../context'
import { useStackableNFTs } from '../hooks/useStakableNFTs'
import { Button } from './Button'

export const StakeAllButton = () => {
    const { filter } = useContext(StakeListContext)
    const { useStake, useUnstake, staked, unstaked } = useStackableNFTs()
    const { stake, isStaking } = useStake()
    const { unstake, isUnstaking } = useUnstake()
    const isLoading = isStaking || isUnstaking

    const label = filter.staked ? 'remove all' : 'add all'
    const count = filter.staked ? staked.length : unstaked.length

    const stakeAll = () => {
        if (filter.staked === false) {
            stake(unstaked)
        }
        if (filter.staked === true) {
            unstake(staked)
        }
    }

    if (count)
        return (
            <div>
                <Button>
                    <button
                        disabled={isLoading}
                        onClick={stakeAll}
                        className='py-4 px-8 w-full text-center uppercase font-black flex flex-row justify-center items-center gap-x-2'
                    >
                        <span className=' whitespace-nowrap'>{label}</span>
                        {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                    </button>
                </Button>
            </div>
        )
    return null
}
