import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useLogout = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async () => {
            return await fetch('/api/account/auth', {
                method: 'DELETE',
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['viewer'] })
        },
    })
}
