import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { SetStateAction, useCallback, useState } from 'react'

const ModalContext = React.createContext<{
    modal: any
    setModal: React.Dispatch<SetStateAction<any>>
    unSetModal: () => void
}>({
    modal: undefined,
    setModal: undefined,
    unSetModal: undefined,
})

const Modal = ({ modal, unSetModal }) => {
    return (
        <div className='fixed z-[9999] top-0 left-0 w-full h-full bg-black/75 pointer-events-auto flex flex-row items-start p-6 justify-center overflow-auto'>
            <div className='relative w-full flex flex-row justify-center ml-6'>
                {modal}
                <button
                    className='-translate-x-1/2 -translate-y-1/2 rounded-full h-6 w-6 border bg-black'
                    onClick={(e) => unSetModal()}
                >
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </div>
        </div>
    )
}

export const ModalProvider = (props) => {
    const [modal, setModal] = useState()
    const unSetModal = useCallback(() => {
        setModal(undefined)
    }, [setModal])

    return (
        <ModalContext.Provider value={{ unSetModal, setModal }} {...props}>
            {props.children}
            {modal && <Modal modal={modal} unSetModal={unSetModal} />}
        </ModalContext.Provider>
    )
}

export const useModal = () => {
    return React.useContext(ModalContext)
}
