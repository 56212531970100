import { useQuery } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'

export const useDepositOptions = () => {
    const { data: user } = useUser()
    return useQuery<DepositOptions>({
        queryKey: ['deposit.solana_usdc.options'],
        queryFn: async () => {
            const res = await fetch('/api/bank/deposit/solana_usdc/options')
            const data = await res.json()
            return data
        },
        enabled: user?.is_auth,
        initialData: {
            cluster_url: undefined,
            token_mint: undefined,
        },
    })
}
