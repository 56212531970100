import { Base, BaseProps } from './base'
import { twMerge } from 'tailwind-merge'

export const Box = ({ children, className, ...rest }: BaseProps) => {
    return (
        <Base className={twMerge('flex flex-col  gap-1 p-1 relative flex-1 text-center', className)} {...rest}>
            {children}
        </Base>
    )
}

Box.Gray = ({ children, className, ...rest }: BaseProps) => {
    return (
        <Box className={twMerge('bg-white/10 border border-white/10 rounded-lg', className)} {...rest}>
            {children}
        </Box>
    )
}
