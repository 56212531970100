export const jsonrpc = (method, params = {}) => {
    return fetch('/rpc', {
        method: 'POST',
        body: JSON.stringify({
            jsonrpc: '2.0',
            method,
            params,
        }),
    }).then(async (response) => {
        const data = await response.json()
        if ('error' in data) {
            throw data
        }
        return data
    })
}
