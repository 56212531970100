import { useState } from 'react'

interface ToggleProps {
    defaultChecked?: boolean
    onChange?: (value: boolean) => void
}

export const Toggle = ({ defaultChecked = false, onChange }: ToggleProps) => {
    const [state, setState] = useState(defaultChecked)

    const onToggleChange = (e) => {
        setState(e.target.checked)
        onChange?.(e.target.checked)
    }

    return (
        <label className='toggle-checkbox flex flex-row'>
            <input type='checkbox' checked={state} onChange={onToggleChange} />
            <span></span>
        </label>
    )
}
