import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '~/components/Auth'
import { Auth } from '~/entities'
import { Button, InputText } from '~/shared/ui'
import { LoginContext, LoginProvider } from './context'

export const LoginForm = ({ className = undefined }) => {
    const { auth } = useAuth()

    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        if (auth?.is_auth) navigate('/account')
    }, [auth])

    return (
        <LoginProvider>
            <LoginContext.Consumer>
                {({
                    username,
                    setUsername,
                    password,
                    setPassword,
                    usernameError,
                    passwordError,
                    commonError,
                    login,
                }) => (
                    <Auth.Container className={className}>
                        <InputText
                            name='username'
                            value={username ?? ''}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder={t('auth:username').toUpperCase()}
                        />
                        <span className='uppercase text-xs text-red-500 -mt-1'>{usernameError || <>&nbsp;</>}</span>
                        <InputText
                            type='password'
                            name='password'
                            value={password ?? ''}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={t('auth:password').toUpperCase()}
                        />
                        <span className='uppercase text-xs text-red-500 -mt-1'>
                            {commonError || passwordError || <>&nbsp;</>}
                        </span>
                        <Button.Gradient onClick={login} className='uppercase text-xl font-semibold'>
                            {t('auth:login')}
                        </Button.Gradient>
                        <div className='flex flex-col gap-2 pt-4 uppercase text-[0.65rem] font-semibold'>
                            <div>
                                <span>{t('auth:dont_have_account')} </span>
                                <NavLink to='/signup' className='underline hover:no-underline'>
                                    {t('auth:sign_up')}
                                </NavLink>
                            </div>
                            <div>
                                <span>{t('auth:forgot_password')} </span>
                                <NavLink to='/password-reset' className='underline hover:no-underline'>
                                    {t('auth:reset')}
                                </NavLink>
                            </div>
                        </div>
                    </Auth.Container>
                )}
            </LoginContext.Consumer>
        </LoginProvider>
    )
}
