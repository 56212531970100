const types = [
    'BR-9',
    'AK-47',
    'SHOTGUN',
    'SNIPER',
    'RPG',
    'BOW',
    'PITBULL',
    'SCAR',
    'SCORPION',
    'MARKSMAN',
    'JUGGERNAUT',
    'CROSSBOW',
    'SWORD',
    'BAT',
]

const weapons = {
    'BR-47': {
        category: 'Assault Rifle',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'Juliet 1.6': {
        category: 'Assault Rifle',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    Pitbull: {
        category: 'Pistol',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'BR-9': {
        category: 'Pistol',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'Marksmen 5.0': {
        category: 'Long Range',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'Gazda 4-2': {
        category: 'Long Range',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'MidNight m350': {
        category: 'Shotgun',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'Scorpion A-T-12': {
        category: 'Shotgun',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'R-x44': {
        category: 'RPG',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'Juggernaut 8-35': {
        category: 'RPG',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'BR-Bow': {
        category: 'Archery',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    'Hunter 512': {
        category: 'Archery',
        common: 5,
        legendary: 1.15,
        ultra: 0.1,
    },
    Katana: {
        category: 'One-Handed Melee',
        common: 10,
        legendary: 2.3,
        ultra: 0.2,
    },
    'Bravo Slugger': {
        category: 'Two-Handed Melee',
        common: 10,
        legendary: 2.3,
        ultra: 0.2,
    },
}

const rates = types.map((t) => [t, 0.5, 0.3, 0.2])

export const LootboxDropRates = () => {
    return (
        <div className='p-4 flex flex-col gap-4 bg-black/50 border font-black'>
            <header className='text-xl 2xl:text-2xl text-center'>DROP RATES & RARITIES</header>
            <table className='bg-black/50 border-spacing-2 border-separate text-[0.5rem] lg:text-xs'>
                <thead>
                    <tr>
                        <th className='text-left'>NAME</th>
                        <th className='text-right hidden md:table-cell'>CATEGORY</th>
                        <th className='text-center'>COMMON</th>
                        <th className='text-center'>LEGENDARY</th>
                        <th className='text-center'>ULTRA</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(weapons).map(([name, { category, common, legendary, ultra }]) => (
                        <tr key={name}>
                            <td>{name}</td>
                            <td className='text-right  hidden md:table-cell'>{category}</td>
                            <td className='border border-black text-center'>{common}%</td>
                            <td className='border border-black text-center'>{legendary}%</td>
                            <td className='border border-black text-center'>{ultra}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
