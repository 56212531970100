import { createContext } from 'react'

type StakeContextType = {
    data: NFT[]
    points: UserPoints
    isFetched: boolean
    useUpdateCache: () => (nfts: NFT[], partial: Partial<NFT> | ((nft: NFT) => NFT)) => void
    useStake: () => { stake: (nft: NFT[]) => void; isStaking: boolean }
    useUnstake: () => { unstake: (nft: NFT[]) => void; isUnstaking: boolean }
}

export const StakeContext = createContext<StakeContextType>(undefined)
