import { Route } from 'react-router-dom'

import { RequireAuth } from '~/components/Auth'
import { StakeRoute } from './StakeRoute'

export const StakeRoutes = [
    <Route
        key={'staking'}
        index
        element={
            <RequireAuth>
                <StakeRoute />
            </RequireAuth>
        }
    />,
]
