import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'

export const DepositWithdrawContext = createContext<{
    action: 'deposit' | 'withdraw'
    setAction: Dispatch<SetStateAction<'deposit' | 'withdraw'>>
}>({
    action: 'deposit',
    setAction: () => {},
})

export const DepositWithdrawProvider = ({ children, initAction }) => {
    const [action, setAction] = useState<'deposit' | 'withdraw'>(initAction)
    return <DepositWithdrawContext.Provider value={{ action, setAction }}>{children}</DepositWithdrawContext.Provider>
}

export const useDepositWithdraw = () => {
    return useContext(DepositWithdrawContext)
}
