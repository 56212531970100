import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Header, Stat, StatLabel, StatNumber } from '~/shared/ui'

export const ConnectionsTotalCount = () => {
    const [connections, setConnections] = useState<Record<string, number>>({})

    const fields = {
        discord: <NavLink to='/team-tools/accounts/users?discord_connected__in=true' className='hover:outline' />,
        twitter: <NavLink to='/team-tools/accounts/users?twitter_connected__in=true' className='hover:outline' />,
        wallets_without_operatives: (
            <NavLink
                to='/team-tools/accounts/users?is_holder__in=false&wallet_connected__in=true'
                className='hover:outline'
            />
        ),
        wallets_with_operatives: (
            <NavLink
                to='/team-tools/accounts/users?is_holder__in=true&wallet_connected__in=true'
                className='hover:outline'
            />
        ),
    }

    const getData = () => {
        jsonrpc('statistic.connections.total_count').then((res) => {
            setConnections(res.result || {})
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Box className='w-full mx-auto'>
            <Header>Total connections</Header>
            <section className='grid grid-cols-2 w-full'>
                {Object.entries(fields).map(([name, as]) => (
                    <Box key={name} className='items-stretch text-center'>
                        <Stat.Gray as={as} loading={connections[name] === undefined}>
                            <StatLabel className='capitalize text-amber-500'>{name.replaceAll('_', ' ')}</StatLabel>
                            <StatNumber>{connections[name]}</StatNumber>
                        </Stat.Gray>
                    </Box>
                ))}
            </section>
        </Box>
    )
}
