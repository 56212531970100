import { useContext, useLayoutEffect, useRef, useState } from 'react'
import { StakeListContext } from '../context'
import { NFTTypeFilter } from './NFTTypeFilter'
import { StakeAllButton } from './StakeAllButton'
import { StakeItem } from './StakeItem'
import { StakeListFilter } from './StakeListFilter'

const dummyNFT = {
    id: -1,
    name: 'dummy',
    operative_id: -1,
    operative_type: 'ape',
    staked: false,
    is_legendary: false,
    stake_rate: 0,
}

const itemKey = (nft) => `${nft.operative_type}#${nft.operative_id}`

export const StakeList = () => {
    const { data } = useContext(StakeListContext)
    const measureLayer = useRef<HTMLDivElement>(null)
    const container = useRef<HTMLDivElement>(null)
    const [containerHeight, setContainerHeight] = useState(0)
    const [cols, setCols] = useState(0)
    const hasData = data.length > 0

    useLayoutEffect(() => {
        const { height, width } = measureLayer.current.getBoundingClientRect()
        const { width: containerWidth } = container.current.getBoundingClientRect()

        setContainerHeight(height * 2)
        setCols(Math.floor(containerWidth / width))
    }, [])

    return (
        <>
            <div className='flex flex-col gap-8'>
                <div className='grid grid-cols-1 lg:grid-cols-[2fr,3fr,1fr] items-center gap-8'>
                    <StakeListFilter />
                    <NFTTypeFilter />
                    <StakeAllButton />
                </div>
                <div
                    ref={container}
                    className='w-full mx-auto grid h-96 overflow-y-scroll flat-scrollbar'
                    style={{
                        height: containerHeight,
                        gridTemplateColumns: `repeat(${cols}, 1fr)`,
                    }}
                >
                    {hasData ? data.map((nft, i) => <StakeItem key={itemKey(nft)} nft={nft} />) : <NoData />}
                </div>
            </div>

            <div className='fixed -top-full -left-full invisible' ref={measureLayer}>
                <StakeItem nft={dummyNFT} />
            </div>
        </>
    )
}

const NoData = () => {
    return (
        <div className='col-span-full flex items-center justify-center text-white/50 font-black text-3xl select-none'>
            NO DATA
        </div>
    )
}
