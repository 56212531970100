import { createPortal } from 'react-dom'
import { Outlet } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

const Background = (props) => {
    const BR1_CINEMATIC_VIDEO = '/media/video/BR1_cinematic_gameplay'
    const { className } = props
    const page = document.querySelector<HTMLElement>('#page-background')

    return (
        page &&
        createPortal(
            <div className={twMerge('', className)}>
                <video autoPlay muted loop playsInline className='object-cover w-full h-screen'>
                    <source src={`${BR1_CINEMATIC_VIDEO}.webm`} type='video/webm; codecs="vp9"' />
                    <source src={`${BR1_CINEMATIC_VIDEO}.mp4`} type='video/mp4' />
                </video>

                <div className='absolute top-0 w-full h-[55%] bg-gradient-to-b from-black to-transparent'></div>
                <div className='absolute bottom-0 w-full h-[35%] bg-gradient-to-t from-black to-transparent'></div>
            </div>,
            page
        )
    )
}
export const LoginLayout = () => {
    return (
        <>
            <Background />
            <Outlet />
        </>
    )
}
