import { faChevronDown, faChevronRight, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Wallet } from '~/features/auth/types'
import { useModal } from '~/hooks/useModal'
import { useUserWallet } from '../api'
import { formatWalletAddress } from '../utils'

const Item = ({ wallet }) => {
    return (
        <div className='relative grid grid-cols-[max-content,1fr] gap-2 border border-white/10 p-2 cursor-pointer hover:bg-white/10'>
            <div className='w-6 h-6'></div>
            <div className='hidden md:flex flex-row items-center'>{wallet}</div>
            <div className='flex md:hidden flex-row items-center justify-center pr-6'>
                {formatWalletAddress(wallet)}
            </div>
        </div>
    )
}

export const UserWalletSelect = ({ onSelect }) => {
    const { wallets } = useUserWallet()
    const [wallet, setWallet] = useState<Wallet>()
    const [open, setOpen] = useState<boolean>(false)
    const { unSetModal } = useModal()

    const toggle = () => setOpen(!open)
    const selectWallet = (wallet: Wallet) => {
        setWallet(wallet)
        onSelect && onSelect(wallet)
        toggle()
    }

    return (
        <div className='grid grid-cols-1 gap-2 text-xs'>
            <div
                className='grid grid-cols-[max-content,1fr,max-content] gap-2 border border-white/50 p-2 cursor-pointer items-center'
                onClick={toggle}
            >
                <FontAwesomeIcon icon={faWallet} className='h-6 w-6' />

                <div className='hidden md:flex flex-row items-center overflow-hidden'>{wallet?.pubkey}</div>
                <div className='flex md:hidden flex-row items-center justify-center overflow-hidden'>
                    {formatWalletAddress(wallet?.pubkey)}
                </div>

                <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} className='w-6' />
            </div>
            {wallets.map((wallet) => (
                <button
                    key={wallet.pubkey}
                    className={twMerge('col-start-1 col-end-[-1]', open ? 'block' : 'hidden')}
                    onClick={() => selectWallet(wallet)}
                >
                    <Item wallet={wallet.pubkey} />
                </button>
            ))}
            <NavLink
                to='/account#wallets'
                onClick={() => unSetModal()}
                className={twMerge(
                    'col-start-1 col-end-[-1] text-center text-white/50 hover:text-white mb-4',
                    open ? 'block' : 'hidden'
                )}
            >
                connect new wallet
            </NavLink>
        </div>
    )
}
