import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheckCircle,
    faExternalLink,
    faEye,
    faSpinner,
    faStopwatch,
    faWallet,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Copyable } from '~/components/ui/Copyable'
import { formatWalletAddress } from '~/features/bank/utils'
import { useDepositList } from '../api/useDepositList'
import { formatTxURL } from '../utils/solscan'

const usdcIcon = new URL('~/features/bank/assets/usdc.png', import.meta.url).toString()
const solIcon = new URL('~/features/bank/assets/solana.png', import.meta.url).toString()

const statuses = {
    0: { name: 'Waiting payment', icon: faEye, color: 'text-white' },
    1: { name: 'Checking', icon: faStopwatch, color: 'text-yellow-500' },
    2: { name: 'Confirmed', icon: faCheckCircle, color: 'text-green-500' },
}

export const DepositList = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const { data, isLoading } = useDepositList({ page: currentPage })
    return (
        <div className='flex flex-col gap-2'>
            {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            {data?.count > 0 && (
                <>
                    <div className=' self-end'>
                        <Page data={data} setCurrentPage={setCurrentPage} />
                    </div>
                    <div className='flex flex-col gap-2'>
                        {data?.data.map((d, i) => (
                            <Fragment key={d.uuid}>
                                <Row deposit={d} />
                            </Fragment>
                        ))}
                    </div>
                    <div className=' self-end'>
                        <Page data={data} setCurrentPage={setCurrentPage} />
                    </div>
                </>
            )}
        </div>
    )
}

const Page = ({ data, setCurrentPage }) => {
    return (
        <div className='flex flex-row gap-2 items-center text-xs select-none'>
            <button
                disabled={!data?.prev_page}
                className='border rounded p-1 border-white/10 enabled:hover:border-white/50 disabled:opacity-30'
                onClick={() => setCurrentPage((prev) => data?.prev_page || prev)}
            >
                <small>
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </small>
            </button>
            <div>{data?.page}</div>
            <div>/</div>
            <div>{data?.pages}</div>
            <button
                disabled={!data?.next_page}
                className='border rounded p-1 border-white/10 enabled:hover:border-white/50 disabled:opacity-30'
                onClick={() => setCurrentPage((prev) => data?.next_page || prev)}
            >
                <small>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                </small>
            </button>
        </div>
    )
}

const Row = ({ deposit }: { deposit: Deposit }) => {
    const [showDetails, setShowDetails] = useState(false)
    return (
        <div className='flex flex-col text-xs font-bold'>
            <small className='text-white/30'>{new Date(deposit.created_at).toLocaleString()}</small>
            <small className='border border-white/10'>
                <button
                    className={twMerge(
                        'bg-white/5 hover:bg-white/10 p-1 uppercase flex flex-row gap-1 w-full',
                        statuses[deposit.status].color
                    )}
                    onClick={() => setShowDetails((prev) => !prev)}
                >
                    <div className='flex-1 flex flex-row gap-1 items-center'>
                        <span>{statuses[deposit.status].name}</span>
                        <FontAwesomeIcon icon={statuses[deposit.status].icon} />
                    </div>
                    <div>{deposit.amount}</div>
                    <img src={deposit.type === 1 ? usdcIcon : solIcon} className='h-4' />
                </button>
                {showDetails && (
                    <div className='grid grid-cols-[max-content,1fr] p-2 gap-2'>
                        <div className='text-white/50'>id:</div>
                        <Copyable value={deposit.uuid}>
                            <span>{deposit.uuid}</span>
                        </Copyable>
                        <div className='text-white/50'>
                            <FontAwesomeIcon icon={faWallet} />
                        </div>
                        <Copyable value={deposit.wallet}>
                            <div title={deposit.wallet}>{formatWalletAddress(deposit.wallet)}</div>
                        </Copyable>
                        {deposit.tx_signature && (
                            <>
                                <div className='text-white/50'>tx:</div>
                                <div className='flex flex-row items-center gap-1'>
                                    <a
                                        href={formatTxURL(deposit.tx_signature, deposit.cluster)}
                                        target='_blank'
                                        title={deposit.tx_signature}
                                        className='flex flex-row items-center gap-1 hover:underline underline-offset-2 text-green-500'
                                    >
                                        <span>{formatWalletAddress(deposit.tx_signature)}</span>
                                        <FontAwesomeIcon icon={faExternalLink} />
                                    </a>
                                    <Copyable value={deposit.tx_signature} />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </small>
        </div>
    )
}
