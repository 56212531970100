import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { useUserPayoutWallet } from '~/features/wallet/hooks/useUserPayoutWallet'
import { useUser } from '../api/useUser'

const EmptyAvatar = () => {
    const { wallet, hasWalletConnected, isFetched } = useUserPayoutWallet()
    return (
        <div className='relative flex flex-col items-center justify-center h-8 w-8 rounded-full bg-[#e47ba6]'>
            <FontAwesomeIcon icon={faUser} color='#b04572' className='w-5 h-5' />
            {isFetched && hasWalletConnected && wallet === undefined && (
                <div className='absolute left-full top-full -translate-x-1/2 -translate-y-1/2 bg-yellow-500/80 flex flex-row items-center justify-center px-2 py-0 text-xs rounded font-bold border'>
                    1
                </div>
            )}
        </div>
    )
}

export const UserMenuItem = () => {
    const { data, isFetched } = useUser()
    return (
        isFetched && (
            <NavLink to='/account' className='group grid grid-cols-[repeat(3,max-content)] items-center gap-x-2'>
                <EmptyAvatar />
                <div className='text-sm uppercase font-bold group-hover:underline underline-offset-2'>
                    {data.username}
                </div>
            </NavLink>
        )
    )
}

export const UserMenuItemMobile = () => {
    const { data, isFetched } = useUser()
    return (
        isFetched && (
            <NavLink to='/account' className='grid grid-cols-[max-content,1fr] items-center bg-black rounded-3xl p-2'>
                <EmptyAvatar />
                <div className='text-sm uppercase font-bold text-center pr-10'>{data.username}</div>
            </NavLink>
        )
    )
}
