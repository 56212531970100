import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const Copyable = (props) => {
    const { children, value, ...rest } = props
    const [transition, setTransition] = useState<Boolean>(false)
    const copyValue = (e) => {
        if (value) {
            const selection = document.getSelection()
            if (selection.type === 'Range') return
            setTransition(true)
            navigator.clipboard.writeText(value).finally(() => {
                setTimeout(() => setTransition(false), 500)
            })
        }
    }
    return (
        <div {...rest}>
            <div
                className={twMerge(
                    'flex flex-row items-center gap-1 cursor-pointer transition-opacity',
                    transition && 'opacity-50'
                )}
                onClick={copyValue}
            >
                {children}
                <FontAwesomeIcon icon={faCopy} />
            </div>
        </div>
    )
}
