import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const SILUETE_BASE_URL = '/media/assets/features/lootbox/weapon-siluete/'
const siluetes = {
    Archery: SILUETE_BASE_URL + 'archery.png',
    Pistol: SILUETE_BASE_URL + 'pistol.png',
    RPG: SILUETE_BASE_URL + 'rpg.png',
    Shotgun: SILUETE_BASE_URL + 'shotgun.png',
    'Assault Rifle': SILUETE_BASE_URL + 'assault-rifle.png',
    'Long Range': SILUETE_BASE_URL + 'long-range.png',
    'One-Handed Melee': SILUETE_BASE_URL + 'melee.png',
    'Two-Handed Melee': SILUETE_BASE_URL + 'melee.png',
}

async function getWeaponData(id) {
    const response = await fetch(`https://assets.bravoready.com/br1-infinite/weapons/${id}.json`)
    const data = await response.json()
    const name = data.name
    const image = data.properties.files.find((file) => file.type === 'image/gif').uri
    const category = data.attributes.find((attr) => attr.trait_type === 'Category').value
    const bonus = data.attributes.find((attr) => attr.trait_type === 'Earnings Retention Bonus').value
    const rarity = data.attributes.find((attr) => attr.trait_type === 'Type').value
    return { name, image, category, bonus, rarity }
}

function rarityGradient(rarity) {
    switch (rarity) {
        case 'Legendary':
        case 'Ultra':
            return 'linear-gradient(45deg,rgba(255, 230, 100, 0.5),rgba(255, 230, 0, 1),rgba(255, 230, 100, 0.5))'
        default:
            return 'linear-gradient(90deg,rgba(3,206,255,0.5),rgba(201,31,255,0.5))'
    }
}

export const WeaponCard = ({ weapon, revealed, onOpen }) => {
    const [mint, id] = weapon
    const [{ name, image, category, bonus, rarity }, setData] = useState({
        name: undefined,
        image: undefined,
        category: undefined,
        bonus: undefined,
        rarity: undefined,
    })
    const [isFetched, setIsFetched] = useState(false)
    useEffect(() => {
        getWeaponData(id).then((data) => {
            setData(data)
            setIsFetched(true)
        })
    }, [])

    const [opened, setOpened] = useState(revealed)

    useEffect(() => {
        setOpened(revealed)
    }, [revealed])

    const open = () => {
        setOpened(true)
        setTimeout(() => onOpen(), 1000)
    }
    return (
        <div className='flex flex-col items-center gap-4 snap-center w-60'>
            <header className={twMerge('font-black uppercase transition-opacity duration-1000')}>{category}</header>
            <div
                className='group h-60 w-60 [perspective:1000px] bg-black  hover:scale-105 transition-transform'
                onClick={open}
            >
                <div
                    className={twMerge(
                        'relative h-full w-full transition-all duration-1000',
                        '[transform-style:preserve-3d]',
                        opened && '[transform:rotateY(180deg)]'
                    )}
                >
                    <button className='absolute inset-0 h-full w-full flex flex-col items-center justify-center [backface-visibility:hidden] bg-black'>
                        <div className='p-1 w-full h-full' style={{ background: rarityGradient(rarity) }}>
                            <div className='bg-black w-full h-full flex flex-col items-center justify-center'>
                                <img src={siluetes[category]} alt='automate' className='h-1/2 w-1/2 object-contain' />
                            </div>
                        </div>
                        <small className='absolute bottom-4 uppercase font-bold text-white/50'>click to reveal</small>
                    </button>

                    <div className='absolute inset-0 h-full w-full flex flex-col items-center justify-center [backface-visibility:hidden] [transform:rotateY(180deg)] bg-black'>
                        <div className='p-1' style={{ background: rarityGradient(rarity) }}>
                            <div className='bg-black'>
                                <img src={image} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a
                href={`https://explorer.solana.com/address/${mint}`}
                target='_blank'
                title={mint}
                className={twMerge(
                    'font-bold transition-opacity duration-1000 delay-1000',
                    opened ? 'opacity-50' : 'opacity-0'
                )}
            >
                <small className='flex flex-row gap-2 items-center'>
                    {mint.slice(0, 6)}...{mint.slice(-6)}
                    <FontAwesomeIcon icon={faExternalLink} />
                </small>
            </a>

            <div
                className={twMerge(
                    'text-green-400 font-black uppercase transition-opacity duration-1000 delay-[1500ms]',
                    opened ? 'opacity-100' : 'opacity-0'
                )}
            >
                +{bonus} earnings bonus
            </div>
            <div
                className={twMerge(
                    'font-black text-center h-10 uppercase transition-opacity duration-1000 delay-1000',
                    opened ? 'opacity-100' : 'opacity-0'
                )}
            >
                {name}
            </div>
        </div>
    )
}
