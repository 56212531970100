import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Stat, StatLabel, StatNumber, Header } from '~/shared/ui'

export const AccountsCount = () => {
    const [accounts, setAccounts] = useState<Record<string, [number, number]>>({})

    const periods = ['total', 'today', 'week', 'month', '24_hours', '7_days', '30_days']

    const getValues = () => {
        jsonrpc('statistic.accounts.count', { periods }).then((resp) => setAccounts(resp.result || {}))
    }

    useEffect(() => getValues(), [])

    return (
        <Box className='w-full text-center'>
            <Header>Accounts</Header>
            <small className='text-white/50'>The number in brackets limits the selection to activated accounts.</small>
            <section className='grid grid-cols-3 gap-2 mt-2 w-full'>
                {periods.map((period) => {
                    const [registered, activated] = accounts[period] || []
                    return (
                        <Stat.Gray
                            key={period}
                            as={period === 'total' && <NavLink to='/team-tools/accounts' className='hover:outline' />}
                            loading={registered === undefined}
                            className={period === 'total' && 'col-span-3'}
                        >
                            <StatLabel className='capitalize text-amber-500'>{period.replace('_', ' ')}</StatLabel>
                            <StatNumber>
                                {registered !== undefined ? (
                                    <>
                                        {registered} ({activated})
                                    </>
                                ) : undefined}
                            </StatNumber>
                        </Stat.Gray>
                    )
                })}
            </section>
        </Box>
    )
}
