import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from '~/components/Spinner'

type ButtonProps = {
    icon?: JSX.Element
    spinner?: JSX.Element
    is_loading?: boolean
    [p: string]: any
}

export const Button = (props: ButtonProps) => {
    const { icon, children, className, is_loading = false, spinner = <Spinner className='h-6 w-6'/>, ...rest } = props

    return (
        <button
            className={[
                'relative overflow-hidden',
                'bg-[rgba(21,18,25,0.52)]',
                'flex flex-row items-center justify-center gap-x-1 p-2 font-semibold uppercase rounded-lg transition-all',
                !is_loading && 'hover:outline',
                'disabled:transition-none disabled:outline-none disabled:opacity-20 disabled:cursor-not-allowed',
                className,
            ]
                .filter(Boolean)
                .join(' ')}
            {...rest}
        >
            {icon !== undefined && <div>{icon}</div>}
            <div>{children}</div>
            {is_loading && <div className='absolute flex flex-row items-center justify-center w-full h-full bg-neutral-900/50'>{spinner}</div>}
        </button>
    )
}

const GradientButton = (props: ButtonProps) => {
    const { className, children, ...rest } = props
    return (
        <Button
            className={['bg-[linear-gradient(270deg,#C91FFF_-15%,#03CEFF_170%)]', className].filter(Boolean).join(' ')}
            {...rest}
        >
            {children}
        </Button>
    )
}

const WarningButton = (props: ButtonProps) => {
    const { className, children, ...rest } = props
    const icon = <FontAwesomeIcon icon={faTriangleExclamation}/>
    return (
        <Button
            className={['text-yellow-500', className].filter(Boolean).join(' ')}
            icon={icon}
            {...rest}
        >
            {children}
        </Button>
    )
}

Button.Warning = WarningButton
Button.Gradient = GradientButton