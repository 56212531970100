const skull = new URL('skull.svg', import.meta.url).href
const ape = new URL('ape.png', import.meta.url).href
const droid = new URL('droid.png', import.meta.url).href
const lootbox = new URL('lootbox.png', import.meta.url).href
const weapon = new URL('weapon.png', import.meta.url).href

const Hexagon = ({ children, fill, stroke, className }: HexagonProps & IconProps) => {
    return (
        <svg viewBox='0 0 62 52' fill={fill} stroke={stroke} className={className}>
            <defs>
                <linearGradient
                    id='svg_br1gradient'
                    x1='12.5509'
                    y1='-3.05291'
                    x2='49.525'
                    y2='53.5955'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#03CEFF' />
                    <stop offset='1' stopColor='#C91FFF' />
                </linearGradient>
                <linearGradient id='svg_legendarygradient' x2='150%' y2='150%' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FFE500' />
                    <stop offset='1' stopOpacity='0' />
                </linearGradient>
                <linearGradient id='svg_weapon_legendary_gradient' x2='150%' y2='150%' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#C91FFF' />
                    <stop offset='1' stopOpacity='0' />
                </linearGradient>
                <linearGradient id='svg_commongradient' x2='150%' y2='150%' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#B9B9B9' />
                    <stop offset='1' stopOpacity='0' />
                </linearGradient>
            </defs>
            <symbol viewBox='0 0 72 72' id='polygon'>
                <path
                    fill='white'
                    d='M7.577,37 C7.22,36.381 7.22,35.619 7.577,35 L20.923,11.885 C21.28,11.266 21.94,10.885 22.655,10.885 L49.345,10.885 C50.06,10.885 50.72,11.266 51.077,11.885 L64.423,35 C64.78,35.619 64.78,36.381 64.423,37 L51.077,60.115 C50.72,60.734 50.06,61.115 49.345,61.115 L22.655,61.115 C21.94,61.115 21.28,60.734 20.923,60.115 L7.577,37 z'
                />
            </symbol>
            <mask id='mask'>
                <use href='#polygon' x='5%' y='5%' width={'90%'} height={'90%'} />
            </mask>
            <path
                d='M2.13622 25.4961L1.8423 26L2.13622 26.5039L16.1362 50.5039L16.4256 51H17H45H45.5744L45.8638 50.5039L59.8638 26.5039L60.1577 26L59.8638 25.4961L45.8638 1.49613L45.5744 1H45H17H16.4256L16.1362 1.49613L2.13622 25.4961Z'
                strokeWidth='2'
                fill='none'
            />
            <path
                d='M41.495 8.0147L51.7877 26.1839L41.4226 44.1009H20.4587L10.2082 26.196L20.5099 8.0147H41.4935M44.13 3.48486H17.8749L5 26.208L17.8372 48.6307H44.0305L57.002 26.208L44.13 3.48486Z'
                strokeWidth='0'
            />
            {children}
        </svg>
    )
}

const SkullPoint = (props: IconProps) => {
    return (
        <Hexagon fill={props.fill ?? 'url(#svg_br1gradient)'} stroke={props.stroke} className={props.className}>
            <image href={skull} mask='url(#mask)' width={'80%'} height={'80%'} x={'10%'} y={'10%'} />
        </Hexagon>
    )
}

const Ape = (props: IconProps) => {
    return (
        <Hexagon fill={props.fill ?? '#C91FFF'} stroke={props.stroke} className={props.className}>
            <image href={ape} mask='url(#mask)' width={'60%'} height={'60%'} x={'20%'} y={'20%'} />
        </Hexagon>
    )
}

const Droid = (props: IconProps) => {
    return (
        <Hexagon fill={props.fill ?? '#0088D5'} stroke={props.stroke} className={props.className}>
            <image href={droid} mask='url(#mask)' width={'60%'} height={'60%'} x={'20%'} y={'20%'} />
        </Hexagon>
    )
}

const Lootbox = (props: IconProps) => {
    return (
        <Hexagon fill={props.fill ?? '#00FF90'} stroke={props.stroke} className={props.className}>
            <image href={lootbox} mask='url(#mask)' width={'30%'} height={'30%'} x={'35%'} y={'35%'} />
        </Hexagon>
    )
}

const Weapon = (props: IconProps) => {
    return (
        <Hexagon fill={props.fill ?? '#FFE500'} stroke={props.stroke} className={props.className}>
            <image href={weapon} mask='url(#mask)' width={'40%'} height={'40%'} x={'30%'} y={'30%'} />
        </Hexagon>
    )
}

const UnknownReward = (props: IconProps) => {
    return (
        <Hexagon fill={props.fill ?? '#FF2A43'} stroke={props.stroke} className={props.className}>
            <foreignObject
                width={'40%'}
                height={'40%'}
                x={'30%'}
                y={'25%'}
                className='text-center font-bold text-white'
            >
                ?
            </foreignObject>
        </Hexagon>
    )
}

export const Icons = {
    Ape,
    Droid,
    Lootbox,
    SkullPoint,
    Weapon,
    UnknownReward,
}
