import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import GradientButton from '~/components/GradientButton'
import { Page } from '~/components/Page'

export const LandingPage = () => {
    // Play videos only if in viewport
    useEffect(() => {
        const videos = document.querySelectorAll('video')
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const target = entry.target as HTMLVideoElement
                    if (entry.isIntersecting) {
                        target.play()
                    } else {
                        target.pause()
                    }
                })
            },
            { root: null, rootMargin: '0px -49% 0px -49%' }
        )
        videos.forEach((video) => {
            video.pause()
            observer.observe(video)
        })
    }, [])
    return (
        <Page header='BR1' className='flex flex-col gap-y-16 pb-16'>
            <Logo />
            <Trailers />
            <DownloadButton />
            <GameModes />
            <Tabs />
            <Operatives />
            <PartnersAndInvestors />
            <AsSeenOn />
            <News />
        </Page>
    )
}

const Logo = () => {
    const { t } = useTranslation()
    return (
        <section className='pt-12 w-5/6 mx-auto flex flex-col gap-y-4 lg:flex-row items-center lg:justify-center'>
            <img src='/media/assets/br1_logo.webp' alt='BR1 Logo' className='w-3/4 max-w-[508px] max-h-[142px]' />
            <div className='flex flex-col items-center lg:items-start whitespace-nowrap'>
                <h2 className='text-xs font-bold md:text-2xl'>{t('landing.logo.header')}</h2>
                <h1 className='text-xl font-black md:text-5xl'>{t('landing.logo.subheader')}</h1>
            </div>
        </section>
    )
}

const Trailers = () => {
    const { t } = useTranslation()
    const trailers = ['/media/video/ape', '/media/video/droid']
    const previews = [
        {
            poster: '/media/assets/landing/trailer_ape_preview.webp',
            title: t('landing.trailers.ape'),
        },
        {
            poster: '/media/assets/landing/trailer_droid_preview.webp',
            title: t('landing.trailers.droid'),
        },
    ]
    const [showSelector, setShowSelector] = useState(false)
    const [current, setCurrent] = useState(0)
    const [muted, setMuted] = useState(true)
    return (
        <section id='trailers' className='relative h-[min(calc(9/16*100vw),1080px)]'>
            <div className='relative'>
                <div className='w-full aspect-w-16 aspect-h-9'>
                    <video
                        key={trailers[current]}
                        className='object-cover'
                        playsInline={true}
                        muted={muted}
                        onEnded={() => setCurrent((prev) => (prev === 1 ? 0 : 1))}
                        onClick={() => setMuted((prev) => !prev)}
                    >
                        <source src={`${trailers[current]}.webm`} type='video/webm; codecs="vp9"' />
                        <source src={`${trailers[current]}.mp4`} type='video/mp4' />
                    </video>
                </div>
            </div>
            <div className='absolute z-10 top-0 w-full h-max flex flex-col items-center bg-black/50 backdrop-blur-lg'>
                <div
                    className={twMerge(
                        'overflow-hidden transition-all ease-in-out duration-500 flex flex-row items-center justify-center gap-x-4',
                        'font-bold text-xs lg:text-lg',
                        showSelector ? 'max-h-96' : 'max-h-0'
                    )}
                >
                    {previews.map(({ poster, title }, i) => (
                        <button
                            key={i}
                            onClick={() => {
                                setCurrent(i)
                                setShowSelector(false)
                            }}
                            className='flex flex-col gap-y-2 pt-4 w-1/3 items-center group pb-4'
                        >
                            <div className='relative'>
                                <img
                                    src={poster}
                                    alt={title}
                                    className='outline-1 outline-white/50 group-hover:outline'
                                />
                                {current === i && (
                                    <div className='absolute inset-0 flex flex-col items-center justify-center bg-black/60'>
                                        {t('landing.trailers.playing')}
                                    </div>
                                )}
                            </div>
                            <div>{title}</div>
                        </button>
                    ))}
                </div>
                <button
                    onClick={() => setShowSelector((prev) => !prev)}
                    className='w-full h-max top-0 flex flex-row justify-center items-center gap-x-2 text-xs lg:text-lg py-2 font-bold bg-black/80 backdrop-blur-lg '
                >
                    <FontAwesomeIcon icon={showSelector ? faCaretUp : faCaretDown} />
                    <div>{showSelector ? t('landing.trailers.hide') : t('landing.trailers.show')} TRAILERS</div>
                    <FontAwesomeIcon icon={showSelector ? faCaretUp : faCaretDown} />
                </button>
            </div>
        </section>
    )
}

const DownloadButton = () => {
    const { t } = useTranslation()
    return (
        <section className='flex flex-col items-center lg:text-3xl'>
            <NavLink to='/download'>
                <GradientButton animate className='mx-auto'>
                    {t('landing.download_now')}
                </GradientButton>
            </NavLink>
        </section>
    )
}

const GameModes = () => {
    const { t } = useTranslation()
    const container = useRef(null)
    const modes = [
        {
            video: '/media/video/shot1_720',
            title: 'INFINITE',
            text: t('landing.game_modes.infinite'),
        },
        {
            video: '/media/video/shot2_720',
            title: 'FREE-FOR-ALL',
            text: t('landing.game_modes.free_for_all'),
        },
    ]

    const [slide, setSLide] = useState(0)
    const handleScroll = (e) => {
        const position = container.current.scrollLeft / (container.current.scrollWidth - container.current.clientWidth)
        setSLide(position > 0.5 ? 1 : 0)
    }
    return (
        <section
            id='game_modes'
            ref={container}
            className='flex flex-row items-start gap-x-20 overflow-x-auto snap-x no-scrollbar px-10 lg:px-20 scroll-smooth snap-mandatory'
            onScroll={handleScroll}
        >
            {modes.map(({ video, title, text }, i) => (
                <div
                    key={i}
                    className='relative min-w-[75%] w-3/4 snap-center flex flex-col items-center'
                    onClick={() =>
                        (container?.current as HTMLDivElement).scrollTo({ left: i * 1000, behavior: 'smooth' })
                    }
                >
                    <div className='w-full aspect-w-16 aspect-h-9'>
                        <video className='object-cover' playsInline={true} muted={true} loop>
                            <source src={`${video}.webm`} type='video/webm; codecs="vp9"' />
                            <source src={`${video}.mp4`} type='video/mp4' />
                        </video>
                    </div>
                    <div
                        className={twMerge(
                            'absolute z-10 inset-0 transition-opacity ease-in-out delay-250 duration-500 bg-black',
                            i == slide ? 'opacity-0' : 'opacity-80'
                        )}
                    ></div>
                    <div className='text-xs lg:text-base text-justify p-2 lg:p-4'>{text}</div>
                    <div
                        className='absolute top-0 left-0 bg-black p-2 lg:p-6 pr-10 lg:pr-20 text-sm lg:text-5xl'
                        style={{ clipPath: 'polygon(-1px -1px,100% -1px,85% 100%,-1px 100%)' }}
                    >
                        <div className='bg-silver clip-text font-bold'>{title}</div>
                    </div>
                </div>
            ))}
        </section>
    )
}

const Tabs = () => {
    const menu = ['EARLY ACCESS', 'KILL TO EARN', 'INFINITE ROYALE']
    const tabs = [<EarlyAccessTab />, <KillToEarnTab />, <InfiniteRoyalTab />]
    const [selected, setSelected] = useState(0)
    const container = useRef(null)

    const handleScroll = (e) => {
        const position = e.target.scrollLeft / (e.target.scrollWidth - e.target.clientWidth)
        setSelected(Math.round(position * (menu.length - 1)))
    }

    const setScroll = (i) => {
        const scroll = (i * (container.current.scrollWidth - container.current.clientWidth)) / (menu.length - 1)
        container.current.scrollTo({ left: scroll, behavior: 'smooth' })
    }

    return (
        <section id='game_mechanics' className='flex flex-col'>
            <div className='flex flex-row gap-x-4 md:gap-x-8 lg:gap-x-16 justify-center items-center p-4 bg-gradient-to-r from-neutral-800 to-neutral-900 font-bold text-xs md:text-lg lg:text-xl'>
                {menu.map((tab, i) => (
                    <button key={i} onClick={() => setScroll(i)} className='flex flex-col items-center'>
                        <span className='whitespace-nowrap'>{tab}</span>
                        <span
                            className={twMerge(
                                'border w-full transition-opacity duration-500 delay-200',
                                i == selected ? 'opacity-100' : 'opacity-0'
                            )}
                        ></span>
                    </button>
                ))}
            </div>
            <div
                ref={container}
                onScroll={handleScroll}
                className='flex flex-row snap-x snap-mandatory scroll-smooth overflow-x-auto no-scrollbar'
            >
                {tabs.map((tab, i) => (
                    <div key={i} className='min-w-full w-full snap-center'>
                        {tab}
                    </div>
                ))}
            </div>
            <div className='flex flex-row gap-x-2 items-center justify-center py-2 lg:py-8 h-3 md:h-5'>
                {menu.map((_, i) => (
                    <button
                        key={i}
                        onClick={() => setScroll(i)}
                        className={twMerge(
                            'h-2 w-2 bg-white/20 transition-all duration-500',
                            i == selected && 'h-3 w-3 bg-white/50'
                        )}
                    ></button>
                ))}
            </div>
        </section>
    )
}

const BackgroundVideo = ({ src, children }) => {
    const video = useRef<HTMLVideoElement>(null)
    return (
        <div className='relative w-full mx-auto'>
            <div className='w-full aspect-w-16 aspect-h-9'>
                <video ref={video} className='object-cover' src={src} muted playsInline loop preload='none' />
            </div>
            {children}
        </div>
    )
}

const EarlyAccessTab = () => {
    const { t } = useTranslation()
    return (
        <BackgroundVideo src={'/media/video/early_access_gameplay_1080.mp4'}>
            <div className='absolute top-0 bg-gradient-to-b from-black to-black/0 h-[40%] w-full'></div>
            <div className='absolute bottom-0 bg-gradient-to-t from-black to-black/0 h-[25%] w-full'></div>
            <div className='absolute inset-0 flex flex-col gap-y-2 md:gap-y-10 items-center text-center pt-6 md:pt-10'>
                <h1 className='text-sm md:text-5xl font-black uppercase'>{t('landing.tabs.early_access.header')}</h1>
                <h2 className='text-xs md:text-xl font-bold flex flex-col max-w-3xl'>
                    {t('landing.tabs.early_access.text')}
                </h2>
            </div>
        </BackgroundVideo>
    )
}

const KillToEarnTab = () => {
    const { t } = useTranslation()
    return (
        <BackgroundVideo src={'/media/video/kill_to_earn_short_1080.mp4'}>
            <div className='absolute top-0 bg-gradient-to-b from-black to-black/0 h-[40%] w-full'></div>
            <div className='absolute bottom-0 bg-gradient-to-t from-black to-black/0 h-[25%] w-full'></div>
            <div className='absolute inset-0 flex flex-col gap-y-2 md:gap-y-10 items-center text-center pt-6 md:pt-10'>
                <h1 className='text-sm md:text-5xl font-black'>{t('landing.tabs.kill_to_earn.header')}</h1>
                <h2 className='text-xs md:text-xl font-bold flex flex-col max-w-3xl'>
                    {t('landing.tabs.kill_to_earn.text')}
                </h2>
            </div>
        </BackgroundVideo>
    )
}

const InfiniteRoyalTab = () => {
    const { t } = useTranslation()
    return (
        <BackgroundVideo src={'/media/video/infinite_royal_short_1080.mp4'}>
            <div className='absolute top-0 bg-gradient-to-b from-black to-black/0 h-[40%] w-full'></div>
            <div className='absolute bottom-0 bg-gradient-to-t from-black to-black/0 h-[25%] w-full'></div>
            <div className='absolute inset-0 flex flex-col gap-y-2 md:gap-y-10 items-center text-center pt-6 md:pt-10'>
                <h1 className='text-sm md:text-5xl font-black'>{t('landing.tabs.infinite_royal.header')}</h1>
                <h2 className='text-xs md:text-xl font-bold flex flex-col gap-y-2 w-3/4'>
                    {t('landing.tabs.infinite_royal.text_1')}
                    <br />
                    {t('landing.tabs.infinite_royal.text_2')}
                    <br />
                    {t('landing.tabs.infinite_royal.text_3')}
                </h2>
            </div>
        </BackgroundVideo>
    )
}

const Operatives = () => {
    const { t } = useTranslation()
    const operatives = [
        {
            image: '/media/assets/landing/operatives_ape.webp',
            title: t('landing.operatives.apes'),
            text: t('landing.operatives.apes_description'),
        },
        {
            image: '/media/assets/landing/operatives_droid.webp',
            title: t('landing.operatives.droids'),
            text: t('landing.operatives.droids_description'),
        },
    ]
    const [selected, setSelected] = useState(0)
    return (
        <section className='flex flex-col gap-y-10 py-10 items-center w-full font-black'>
            <h1 className='text-xl md:text-5xl uppercase'>{t('landing.operatives.header')}</h1>
            <div
                className='md:w-3/4 mx-auto grid grid-cols-2 lg:grid-cols-3 bg-no-repeat bg-contain bg-top'
                style={{ backgroundImage: `url('/media/assets/landing/operatives_bg.webp')` }}
            >
                <div className='flex flex-col items-center justify-center'>
                    <div className='flex flex-col gap-y-4 text-xs md:text-lg w-4/6 text-center'>
                        {operatives.map(({ title }, i) => (
                            <button
                                key={i}
                                onClick={() => setSelected(i)}
                                className={twMerge(
                                    'uppercase rounded-3xl p-2 md:p-4',
                                    i == selected ? 'bg-br1-gradient' : 'bg-black-gradient'
                                )}
                            >
                                {title}
                            </button>
                        ))}

                        <div className='uppercase rounded-3xl p-2 md:p-4 text-white/20 bg-gradient-to-r from-zinc-800 to-black'>
                            {t('landing.operatives.humans')}
                        </div>
                    </div>
                </div>

                <img className='object-contain' src={operatives[selected].image} alt={operatives[selected].title} />
                <div className='w-3/4 mx-auto flex flex-col justify-center gap-y-4 col-span-2 lg:col-span-1 text-left uppercase'>
                    <h2 className='text-sm md:text-2xl text-center lg:text-left'>{operatives[selected].title}</h2>
                    <p className='text-white/75 text-xs md:text-base'>{operatives[selected].text}</p>
                </div>
            </div>
        </section>
    )
}

const PartnersAndInvestors = () => {
    const { t } = useTranslation()
    const logos = [
        {
            image: '/media/assets/landing/partners/6th_man.webp',
            url: 'https://www.6thman.ventures/',
            scale: '1',
        },
        {
            image: '/media/assets/landing/partners/shima.webp',
            url: 'https://shima.capital/',
            scale: '1.4',
        },
        {
            image: '/media/assets/landing/partners/solana_ventures.webp',
            url: 'https://solana.ventures/',
            scale: '0.8',
        },
        {
            image: '/media/assets/landing/partners/big_brains.webp',
            url: 'https://www.bigbrain.holdings/',
            scale: '0.8',
        },
        {
            image: '/media/assets/landing/partners/sino.webp',
            url: 'https://www.sinoglobalcapital.com/',
            scale: '0.7',
        },
        {
            image: '/media/assets/landing/partners/infinity_venture.webp',
            url: '',
            scale: '0.8',
        },
        {
            image: '/media/assets/landing/partners/ygg.webp',
            url: '',
            scale: '1',
        },
        {
            image: '/media/assets/landing/partners/goat.webp',
            url: '',
            scale: '0.6',
        },
        {
            image: '/media/assets/landing/partners/justin_kan.webp',
            url: '',
            scale: '0.7',
        },
        {
            image: '/media/assets/landing/partners/krafton.webp',
            url: 'https://krafton.com/',
            scale: '0.7',
        },
        {
            image: '/media/assets/landing/partners/3dgg.webp',
            url: '',
            scale: '0.6',
        },
        {
            image: '/media/assets/landing/partners/cgg.webp',
            url: '',
            scale: '0.7',
        },
        {
            image: '/media/assets/landing/partners/fractal.svg',
            url: 'https://www.fractal.is/br1',
            scale: '0.7',
        },
        {
            image: '/media/assets/landing/partners/magiceden.webp',
            url: 'https://magiceden.io/creators/br1_metaverse',
            scale: '0.5',
        },
        {
            image: '/media/assets/landing/partners/aptos.webp',
            url: 'https://aptoslabs.com/',
            scale: '0.7',
        },
    ]
    return (
        <section id='partners' className='flex flex-col items-center gap-y-16 pb-16'>
            <h1 className='text-xl md:text-5xl font-black uppercase'>{t('landing.partners_and_investors')}</h1>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-16 w-3/4 mx-auto'>
                {logos.map(({ image, url, scale }, i) => (
                    <a
                        key={i}
                        className={twMerge('h-20 select-none', !url && 'pointer-events-none')}
                        href={url}
                        target='_blank'
                    >
                        <img
                            className='h-full w-full mx-auto object-contain'
                            src={image}
                            style={{ scale: scale }}
                            alt={url}
                        />
                    </a>
                ))}
            </div>
        </section>
    )
}

const AsSeenOn = () => {
    const { t } = useTranslation()
    const logos = [
        {
            image: '/media/assets/landing/seen_on/forbes.webp',
        },
        {
            image: '/media/assets/landing/seen_on/yahoo.webp',
        },
        {
            image: '/media/assets/landing/seen_on/betakit.webp',
        },
        {
            image: '/media/assets/landing/seen_on/bu.webp',
        },
        {
            image: '/media/assets/landing/seen_on/venture_beat.webp',
        },
    ]
    return (
        <section className='flex flex-col text-center gap-y-10'>
            <h1 className='text-xl md:text-5xl font-black uppercase'>{t('landing.as_seen_on')}</h1>
            <div className='relative'>
                <div className='absolute inset-0 opacity-50'>
                    <div
                        className='absolute inset-0'
                        style={{
                            backgroundImage: 'linear-gradient(269.43deg, #661181 -9.61%, #006882 142.03%)',
                            transform: 'rotate(-1.02deg)',
                        }}
                    ></div>
                    <div
                        className='absolute inset-0'
                        style={{
                            backgroundImage: 'linear-gradient(91.48deg, #03CEFF -44.69%, #C91FFF 107.75%)',
                            filter: 'blur(25px)',
                        }}
                    ></div>

                    <div
                        className='absolute inset-0'
                        style={{ backgroundImage: 'linear-gradient(91.48deg, #03CEFF -44.69%, #C91FFF 107.75%)' }}
                    ></div>
                </div>
                <div className='relative z-10 flex flex-row gap-x-4 items-center justify-evenly p-10'>
                    {logos.map(({ image }, i) => (
                        <div key={i}>
                            <img src={image} className='scale-75' />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

const News = () => {
    const { t } = useTranslation()
    const backgrounds = [
        '/media/assets/landing/news/sino.webp',
        '/media/assets/landing/news/justin_kan.webp',
        '/media/assets/landing/news/6th_man.webp',
        '/media/assets/landing/news/big_brain.webp',
    ]

    const feed = [
        {
            date: new Date(2022, 6, 11),
            title: 'Gaming Pioneer BRAVO READY Announces $3M Seed Round and Development Partnerships with Solana Ventures, 6th Man Ventures, Justin Kan, Sino Global Capital, and Shima Capital',
            url: 'https://www.businesswire.com/news/home/20220711005877/en/Gaming-Pioneer-BRAVO-READY-Announces-3M-Seed-Round-and-Development-Partnerships-with-Solana-Ventures-6th-Man-Ventures-Justin-Kan-Sino-Global-Capital-and-Shima-Capital',
        },
        {
            date: new Date(2022, 6, 26),
            title: 'Gaming Pioneer BRAVO READY Announces Strategic Investment by Big Brain Holdings',
            url: 'https://www.businesswire.com/news/home/20220726006121/en/Gaming-Pioneer-BRAVO-READY-Announces-Strategic-Investment-by-Big-Brain-Holdings',
        },
        {
            date: new Date(2022, 6, 27),
            title: 'Gaming Pioneer BRAVO READY Announces Funding Agreement and Development Partnership with 6th Man Ventures on Revolutionary Competitive Shooter BR1: Infinite Royale',
            url: 'https://www.businesswire.com/news/home/20220727005229/en/Gaming-Pioneer-BRAVO-READY-Announces-Funding-Agreement-and-Development-Partnership-with-6th-Man-Ventures-on-Revolutionary-Competitive-Shooter-BR1-Infinite-Royale',
        },
        {
            date: new Date(2022, 7, 9),
            title: 'Fractal Cofounder Justin Kan Joins Investor Group Backing BRAVO READY',
            url: 'https://www.businesswire.com/news/home/20220808005863/en/Fractal-Cofounder-Justin-Kan-Joins-Investor-Group-Backing-BRAVO-READY',
        },
        {
            date: new Date(2022, 7, 30),
            title: 'Shima Capital Becomes Newest Member of BRAVO READY Funding Consortium',
            url: 'https://www.businesswire.com/news/home/20220830006018/en/Shima-Capital-Becomes-Newest-Member-of-BRAVO-READY-Funding-Consortium',
        },
        {
            date: new Date(2022, 8, 13),
            title: 'BRAVO READY Announces Strategic Investment from Sino Global Capital',
            url: 'https://www.businesswire.com/news/home/20220913005914/en/BRAVO-READY-Announces-Strategic-Investment-from-Sino-Global-Capital',
        },
        {
            date: new Date(2023, 2, 16),
            title: 'BRAVO READY Announces Strategic Investment From Aptos',
            url: 'https://www.businesswire.com/news/home/20230316005725/en/BRAVO-READY-Announces-Strategic-Investment-From-Aptos',
        },
        {
            date: new Date(2023, 2, 30),
            title: 'BRAVO READY Announces Strategic Investment From Magic Eden',
            url: 'https://www.businesswire.com/news/home/20230330005710/en/BRAVO-READY-Announces-Strategic-Investment-From-Magic-Eden',
        },
    ]

    return (
        <section id='news' className='flex flex-col items-center gap-y-16'>
            <h1 className='text-xl md:text-5xl font-black uppercase'>{t('landing.news')}</h1>
            <div className='grid grid-cols-1 md:grid-cols-3 auto-rows-fr gap-4 font-bold w-3/4 mx-auto py-4'>
                {feed
                    .sort((a, b) => b.date.getTime() - a.date.getTime())
                    .map(({ date, title, url }, i) => (
                        <a
                            key={i}
                            href={url}
                            target='_blank'
                            className={twMerge(
                                'bg-gradient-to-r from-neutral-800 to-black bg-no-repeat bg-cover p-4 flex flex-col gap-4 justify-center',
                                'hover:outline outline-white/30',
                                i == 0 && 'md:row-span-3 justify-end',
                                i > 0 && i < 4 && 'md:col-span-2',
                                i > 3 && 'md:col-span-3'
                            )}
                            style={backgrounds[i] && { backgroundImage: `url(${backgrounds[i]})` }}
                        >
                            {/* <div className='text-white/30 text-xs md:text-sm'>{date.toLocaleDateString()}</div> */}
                            <div className='text-xs md:text-base'>{title}</div>
                        </a>
                    ))}
            </div>
        </section>
    )
}
