import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'
import { useStakeEpoch } from '../hooks/useStakeEpoch'

const API_URL = '/api/nft/staking/user-nfts'

export const useUserNFTs = () => {
    const { data: user } = useUser()
    const { begin_timestamp, end_timestamp } = useStakeEpoch()

    const query = useQuery<Array<NFT>>({
        queryKey: [API_URL],
        queryFn: async () => {
            const res = await fetch(`${API_URL}?epoch_begin=${begin_timestamp}&epoch_end=${end_timestamp}`)
            const data = await res.json()
            return data
        },
        retry: false,
        initialData: [],
        enabled: !!user,
    })

    return query
}

export const useUpdateCache = () => {
    const queryClient = useQueryClient()

    return (nfts: NFT[], partial: Partial<NFT>) => {
        queryClient.setQueryData([API_URL], (oldData: any) => {
            return oldData.map((item: NFT) => {
                const i = nfts.findIndex((n) => n.operative_id === item.operative_id)
                if (i !== -1) {
                    if (partial instanceof Function) {
                        return { ...item, ...partial(item) }
                    }
                    return { ...item, ...partial }
                }
                return item
            })
        })
    }
}
