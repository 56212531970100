import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { RaceGameApi } from '../api/racegame'
import { useNotification } from '../components/NotificationProvider'
import { StatisticEntry } from '../dashboard/statistic'

export const QueueConfig = () => {
    const requirementsList = ['wallet', 'twitter', 'discord', 'droid_holder', 'ape_holder']
    const dict = {
        wallet: 'Wallet connected',
        twitter: 'Twitter connected',
        discord: 'Discord connected',
        droid_holder: 'Droid holder',
        ape_holder: 'Ape holder',
    }

    const [requirements, setRequirements] = useState<Array<string>>([])
    const [password, setPassword] = useState<string>('')
    const [participants, setParticipants] = useState<number>()
    const [isOpen, setIsOpen] = useState<boolean>()
    const [changes, setChanges] = useState(false)

    const { addNotification } = useNotification()

    const updateRequirements = (name, value) => {
        if (value == false) {
            setRequirements(requirements.filter((r) => r != name))
        } else {
            setRequirements((prev) => [...prev, name])
        }
        setChanges(true)
    }

    const reload = async () => {
        RaceGameApi.queue('default', 'detailed_info').then((queue) => {
            setRequirements(queue.requirements)
            setPassword(queue.password)
            setParticipants(queue.participants)
            setIsOpen(queue.is_open)
        })
    }

    useEffect(() => {
        reload()
    }, [])

    const apply = () => {
        RaceGameApi.queue('default', 'configure', { password, requirements, is_open: isOpen })
            .then(() => {
                reload().then(() => addNotification('Queue settings updated', 'success'))
                setChanges(false)
            })
            .catch((error) => {
                addNotification(error.message, 'error')
            })
    }

    const changeState = () => {
        RaceGameApi.queue('default', isOpen ? 'close' : 'open')
            .then((queue) => {
                setIsOpen(queue.is_open)
                addNotification(
                    'Public registration ' + (queue.is_open ? 'open' : 'closed'),
                    queue.is_open ? 'success' : 'error'
                )
            })
            .catch((error) => {
                addNotification(error.message, 'error')
            })
    }

    return (
        <div className='w-max'>
            <NavLink to='/team-tools/accounts/users?racegame_in_queue__in=true' className='group'>
                <StatisticEntry name='Participants' value={participants} />
            </NavLink>
            {isOpen !== undefined && (
                <div className='flex flex-row justify-center my-8'>
                    <label className='toggle-checkbox flex flex-row w-full'>
                        <div
                            className={[
                                'text-sm flex-1 text-center pl-8',
                                isOpen ? 'text-green-500' : 'text-red-500',
                            ].join(' ')}
                        >
                            {isOpen ? 'REGISTRATION OPEN' : 'REGISTRATION CLOSED'}
                        </div>
                        <input type='checkbox' checked={isOpen} onChange={changeState} />
                        <span></span>
                    </label>
                </div>
            )}
            <header className='text-sm text-white/80 tracking-wide mb-1 text-center'>Settings</header>

            <div className='bg-[rgba(200,200,200,0.1)] rounded p-4 flex flex-col gap-4 w-max'>
                <div>
                    <span className='text-xs text-white/60 mb-2'>Requirements</span>

                    {requirementsList.map((r) => (
                        <label
                            key={r}
                            className='flex flex-row items-center gap-2 cursor-pointer hover:underline underline-offset-4 text-sm pt-1'
                        >
                            <input
                                type='checkbox'
                                checked={requirements.includes(r)}
                                onChange={(e) => updateRequirements(r, e.target.checked)}
                                className='accent-violet-500'
                            />
                            {dict[r]}
                        </label>
                    ))}
                </div>
                <div>
                    <label className='flex flex-col w-max'>
                        <span className='text-xs text-white/60 mb-2'>Password</span>
                        <input
                            type='text'
                            maxLength={16}
                            className='input-team-tools tracking-wide'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setChanges(true)
                            }}
                            placeholder='No password'
                        />
                    </label>
                </div>
                <button
                    onClick={apply}
                    disabled={!changes}
                    className={[
                        'rounded p-1 hover:outline disabled:hover:outline-none',
                        changes ? 'bg-violet-500' : 'text-white/20',
                    ]
                        .filter(Boolean)
                        .join(' ')}
                >
                    Save Settings
                </button>
            </div>
        </div>
    )
}
