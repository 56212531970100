import { useMutation, useQueryClient } from '@tanstack/react-query'
import { JsonRpcError } from '~/types'
import { WithdrawRequest } from '../types'

export const useWithdrawCreate = () => {
    const queryClient = useQueryClient()

    return useMutation<WithdrawRequest, JsonRpcError, unknown>({
        mutationFn: async ({ wallet, target_currency, amount }) => {
            const resp = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        jsonrpc: '2.0',
                        id: 1,
                        method: 'bank.withdraw_request.create',
                        params: {
                            wallet,
                            target_currency,
                            amount,
                        },
                    },
                    (key, value) => (isNaN(value) ? value : +value)
                ),
            })
            const data = await resp.json()

            if (data.error) {
                throw data
            } else {
                return data.result
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['balance'])
            queryClient.invalidateQueries(['withdraw_user_requests'])
            queryClient.invalidateQueries(['withdraw_requests'])
            queryClient.invalidateQueries(['withdraw_request.user_summary'])
        },
        retry: false,
    })
}
