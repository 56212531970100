import { useQuery } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'

export const useDepositList = ({ page = 1 }) => {
    const { data: user } = useUser()
    return useQuery<DeposiListResponse>({
        queryKey: ['deposit.solana_usdc.list', page],
        queryFn: async () => {
            const params = new URLSearchParams({ page: page.toString() })
            const res = await fetch('/api/bank/deposit/solana_usdc/list?' + params, {})
            const data = await res.json()
            return data
        },
        enabled: user.is_auth,
        keepPreviousData: true,
    })
}
