import { twMerge } from 'tailwind-merge'

export const BorderGradient = ({ children, className = undefined }) => {
    return (
        <div
            className={twMerge('p-[1px] flex flex-col', className)}
            style={{
                backgroundImage:
                    'linear-gradient(109deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.44) 50%, rgba(255, 255, 255, 1) 89%)',
            }}
        >
            <div className='bg-black flex-1'>{children}</div>
        </div>
    )
}
