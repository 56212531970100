import { useEffect, useState } from 'react'
import { jsonrpc } from '~/shared/api/jsonrpc'
import { Box, Stat, StatLabel, StatNumber } from '~/shared/ui'

export const NftHoldersCount = () => {
    const [total, setTotal] = useState<number>()
    const [linked, setLinked] = useState<number>()

    const getData = () => {
        jsonrpc('statistic.nft.holders_count').then((res) => {
            const { total, linked } = res.result || {}
            setTotal(total)
            setLinked(linked)
        })
    }

    useEffect(() => getData(), [])

    return (
        <Box className='w-full text-center'>
            <Stat.Gray className='w-full'>
                <StatLabel className='text-amber-500'>Holders (linked)</StatLabel>
                <StatNumber>
                    {total} ({linked})
                </StatNumber>
            </Stat.Gray>
            <small className='text-white/50'>Number of holders (Number of holders linked to the website)</small>
        </Box>
    )
}
