import { createContext, useContext, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

type PageContextType = {
    title: string
    setTitle
    header: string
    setHeader
}

const PageContext = createContext<PageContextType>({
    title: '',
    setTitle: () => {},
    header: '',
    setHeader: () => {},
})

export const usePage = () => {
    return useContext(PageContext)
}

export const PageContextProvider = ({ children }) => {
    const [title, setTitle] = useState('')
    const [header, setHeader] = useState('')

    useEffect(() => {
        document.title = title ? `${title?.toUpperCase()} | BR1 INFINITE` : 'BR1 INFINITE'
    }, [title])

    return <PageContext.Provider value={{ title, setTitle, header, setHeader }}>{children}</PageContext.Provider>
}

export const Page = (props) => {
    const { setTitle, setHeader } = usePage()
    const { title, header, children, className, ...rest } = props

    useEffect(() => {
        if (title) setTitle(title)
        if (header || title) setHeader(header || title)
    }, [title, header])

    return <div className={twMerge('page w-full', className)}>{children}</div>
}
