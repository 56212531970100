import { twMerge } from 'tailwind-merge'
import { Page } from '~/components/Page'
import { KillRaceBanner } from '../components/KillRaceBanner'
import { KillRaceHistory } from '../components/KillRaceHistory'
import { useKillRaceList } from '../hooks/useKillRaceList'

const br1_sticker = new URL('../assets/br1_sticker.png', import.meta.url).href
const bg = new URL('../assets/br1_arena_bg.png', import.meta.url).href

export const KillRace = () => {
    const { all } = useKillRaceList()
    return (
        <Page title='Kill Race' header='Kill Race'>
            <div className='flex flex-col items-center gap-y-10 px-4 mb-24 mx-auto'>
                <div
                    className='flex flex-col items-center gap-y-4 w-full pt-24'
                    style={{
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <img src={new URL('../assets/killrace_title_2.svg', import.meta.url).href} />
                    <img src={br1_sticker} className='my-8' />
                </div>
                <h2 className='text-2xl font-black text-center'>CURRENT LIVE TOURNAMENTS</h2>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 w-full place-content-center'>
                    {all.map(({ type, banner, title, enabled }, i) => (
                        <div
                            key={type}
                            className={twMerge(type == 'monthly' && 'lg:row-span-2 lg:row-start-1 lg:col-start-2')}
                        >
                            <KillRaceBanner type={type} banner={banner} title={title} enabled={enabled} />
                        </div>
                    ))}
                </div>
                <div className='grid grid-cols-1 gap-4 font-semibold text-sm'>
                    <div className='flex flex-col gap-2 px-8'>
                        <div className='text-xl py-4'>ABOUT THE PRESEASON GAMES</div>
                        <div>
                            The Preseason Games consist of several game events occurring on Wednesday & lasting for 48
                            hours. Play for free as our staff funds your accounts with free spawns as you run out. Play
                            for free, take home what you earn!
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 px-8'>
                        <div className='text-xl py-4'>ABOUT KILL RACES</div>
                        <div>
                            The BR1: INFINITE Kill Races are Daily, Weekly, and Monthly tournaments that players can
                            compete in for a chance to win even more rewards for being the best. Opt in to a kill race
                            automatically upon getting a kill in-game.
                        </div>
                    </div>
                </div>

                <div className='py-12 w-full'>
                    <KillRaceHistory />
                </div>
            </div>
        </Page>
    )
}
