import { faCheckSquare, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect, useState } from 'react'

export const WalletSelector = () => {
    const { wallets, connecting, select, publicKey, wallet, connect } = useWallet()
    const [selected, setSelected] = useState(wallet?.adapter.name)

    const availableWallets = wallets.filter((w) => w.readyState === 'Installed' || w.readyState === 'Loadable')

    useEffect(() => {
        connect().catch(() => undefined)
    }, [wallet, selected, connect])

    return (
        <div className='flex flex-row gap-4'>
            {availableWallets.map((w, i) => {
                const isSelected = selected === w.adapter.name
                return (
                    <div key={i}>
                        <button
                            onClick={() => {
                                setSelected(w.adapter.name)
                                select(w.adapter.name)
                            }}
                            disabled={connecting}
                            className='relative disabled:opacity-30 p-1'
                        >
                            <img src={w.adapter.icon} className='h-10 w-10' />
                            {connecting && isSelected && (
                                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </div>
                            )}
                            {isSelected && publicKey && (
                                <FontAwesomeIcon
                                    icon={faCheckSquare}
                                    className='absolute top-0 left-0 text-green-500 bg-black'
                                />
                            )}
                        </button>
                    </div>
                )
            })}
        </div>
    )
}
