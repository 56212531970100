import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { PasswordSetBadRequest } from '../types'

export const usePasswordSet = () => {
    const queryClient = useQueryClient()
    const csrfToken = useCrsfToken()

    return useMutation<unknown, PasswordSetBadRequest, unknown>({
        mutationFn: async ({ uid, token, new_password1, new_password2 }) => {
            const form = new FormData()
            form.append('uid', uid)
            form.append('token', token)
            form.append('new_password1', new_password1)
            form.append('new_password2', new_password2)

            const resp = await fetch('/api/account/password/set', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrfToken,
                },
                body: form,
            })
            if (resp.status >= 500) {
                try {
                    return await resp.json()
                } catch (e) {
                    throw { __all__: [{ message: 'Internal server error', code: 'server_error' }] }
                }
            }
            if (resp.status === 400) {
                throw await resp.json()
            }
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(['viewer'])
        },
    })
}
