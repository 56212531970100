import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import en from '../i18n/en/default.json'
import pt from '../i18n/pt/default.json'

i18n.use(LanguageDetector)
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'pt'],
        resources: {
            en: {
                default: en,
            },
            pt: {
                default: pt,
            },
        },
        detection: {
            order: ['querystring', 'navigator'],
        },
        debug: false,
        load: 'languageOnly',
        fallbackLng: 'en',
        defaultNS: 'default',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
