import { useQuery } from '@tanstack/react-query'
import { JsonRpcError } from '~/types'
import { KillRace } from '../types'

export const useKillRace = ({ type }) => {
    return useQuery<KillRace, JsonRpcError>({
        queryKey: ['kill_race', type],
        queryFn: async () => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'gameapi.kill_race',
                    params: {
                        type,
                    },
                }),
            })
            if (!res.ok) {
                return null
            }
            const data = await res.json()
            if (data.error) {
                throw data.error
            }
            return data.result
        },
        retry: false,
    })
}
