import { useInfiniteQuery } from '@tanstack/react-query'
import { useCrsfToken } from '~/hooks/useCrsfToken'
import { JsonRpcError } from '~/types'

const API_URL = '/team-tools/api/player/sessions_history'

export const useGameSessionHistory = ({ user_id }) => {
    const csrf = useCrsfToken()
    const limit = 20
    const query = useInfiniteQuery<GameSessionHistory, JsonRpcError>({
        queryKey: [API_URL, user_id],
        queryFn: async ({ pageParam = 0 }) => {
            const res = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrf,
                },
                body: JSON.stringify({
                    user_id,
                    page: pageParam,
                    limit,
                }),
            })
            const data = await res.json()
            return data
        },
        getNextPageParam: (lastPage) => {
            const current = lastPage.page
            const length = lastPage.data.length
            return length == limit ? current + 1 : undefined
        },
        retry: false,
    })
    const sessions = query.data?.pages.flatMap((page) => page.data)
    const count = query.data?.pages[0].count
    return { ...query, sessions, count }
}
