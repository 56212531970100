import { useContext, useEffect, useState } from 'react'
import { StakeContext } from '../context'
import { useStackableNFTs } from './useStakableNFTs'
import { useStakeEpoch } from './useStakeEpoch'

const UPDATE_INTERVAL = 1000

export const usePoints = () => {
    const { points: serverPoints } = useContext(StakeContext)
    const { staked } = useStackableNFTs()
    const [totalPoints, setTotalPoints] = useState(serverPoints?.total_points ?? 0)
    const [epochPoints, setEpochPoints] = useState(serverPoints?.epoch_points ?? 0)
    const { end, active } = useStakeEpoch()

    useEffect(() => {
        setTotalPoints(serverPoints?.total_points ?? 0)
        setEpochPoints(serverPoints?.epoch_points ?? 0)
    }, [serverPoints])

    const rate = staked.reduce((acc, nft) => acc + nft.stake_rate, 0) / 60 / 60 / 24

    useEffect(() => {
        if (!active) return
        const timer = setInterval(() => {
            setTotalPoints((prev) => prev + rate)
            const now = new Date()
            if (now < end) setEpochPoints((prev) => prev + rate)
        }, UPDATE_INTERVAL)
        return () => clearInterval(timer)
    }, [staked])

    return {
        totalPoints,
        epochPoints,
    }
}
