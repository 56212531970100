import { twMerge } from 'tailwind-merge'
import { Page } from '~/components/Page'
import { useKillRace } from '../api'
import { KillRaceInfo } from '../components/KillRaceInfo'
import { RaceLeaderboard } from '../components/RaceLeaderboard'

const bgDefault = new URL('../assets/killrace_daily_header_bg.jpeg', import.meta.url).href

export const RacePage = ({ type, mode = 'paid', begin = undefined }) => {
    const { data: race_info, isFetched } = useKillRace({ type })

    if (!isFetched) return <div className='h-screen bg-white/5 animate-pulse'></div>

    const bg = race_info?.logo ? `/media/assets/features/career/${race_info.logo}` : bgDefault

    return (
        <Page title='Kill Race' header='Kill Race' className='py-24'>
            <div className='flex flex-col lg:flex-row justify-center items-center lg:items-start gap-10 p-4'>
                <KillRaceInfo type={type} />
                <div className='flex flex-col max-w-[867px] w-full gap-y-6'>
                    <header
                        className='p-12 flex flex-col items-center justify-center gap-y-2 font-bold bg-no-repeat bg-cover'
                        style={{
                            backgroundImage: `url(${bg})`,
                        }}
                    >
                        <h1 className={twMerge('uppercase text-4xl font-black', race_info?.logo && 'invisible')}>
                            {type}
                        </h1>
                        <img
                            src={new URL('../assets/killrace_header.svg', import.meta.url).href}
                            className={twMerge(race_info?.logo && 'invisible')}
                        />
                    </header>
                    {race_info.period_active && <RaceLeaderboard type={type} mode={mode} begin={begin} />}
                </div>
            </div>
        </Page>
    )
}
