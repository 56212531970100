import { useQuery } from '@tanstack/react-query'

const API_URL = '/api/nft/lootboxes'

export const useUserLootboxes = () => {
    return useQuery<LootboxType[]>({
        queryKey: ['lootboxes'],
        queryFn: async () => {
            const res = await fetch(API_URL)
            return await res.json()
        },
        refetchOnWindowFocus: false,
    })
}
