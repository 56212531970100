import { faSpinner, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWallet } from '@solana/wallet-adapter-react'
import { VersionedTransaction } from '@solana/web3.js'
import base58 from 'bs58'
import { useEffect, useReducer, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useOpenLootbox } from '../api/useOpenLootbox'
import { useSendAndConfirm } from '../api/useSendAndConfirm'
import { useLootBoxes } from '../hooks/useLootboxes'
import { LootboxContent } from './LootboxContent'
import { LootboxImage } from './LootboxImage'
import { LootboxOpenVideo } from './LootboxOpenVideo'
import { WalletSelectorModal } from './WalletSelectorModal'

const lootboxReducer = (lootbox: LootboxType, action) => {
    switch (action.type) {
        case 'LOCK':
            return { ...lootbox, state: 'LOCKED' }
        case 'UNLOCK':
            return { ...lootbox, state: 'UNLOCKING' }
        case 'CAN_BE_OPENED':
            return { ...lootbox, state: 'UNLOCKED' }
        case 'OPEN':
            return { ...lootbox, state: 'OPENING' }
        case 'PROCESS':
            return { ...lootbox, state: 'PROCESSING' }
        case 'START_REMOVE':
            return { ...lootbox, state: 'REMOVING' }
        case 'REMOVE':
            return { ...lootbox, state: 'REMOVED' }
        default:
            return lootbox
    }
}

export const Lootbox = ({ lootbox }: { lootbox: LootboxType }) => {
    const { publicKey, sendTransaction, signTransaction } = useWallet()
    const { setLootboxes } = useLootBoxes()
    const [_lootbox, dispatch] = useReducer(lootboxReducer, lootbox)
    const { mutate: openLootbox } = useOpenLootbox()
    const { mutate: sendAndConfirm } = useSendAndConfirm()
    const [content, setContent] = useState<WeaponType[]>([])
    const [walletSelecting, setWalletSelecting] = useState(false)
    const [stateMessage, setStateMessage] = useState('')

    const rightWallet = publicKey?.toBase58() === lootbox.owner

    useEffect(() => {
        setStateMessage('')
        if (_lootbox.state === 'REMOVED') setLootboxes((prev) => prev.filter((l) => l.mint !== lootbox.mint))
        if (_lootbox.state === 'UNLOCKING') {
            setStateMessage('signing transaction')
            openLootbox(
                { mint: lootbox.mint },
                {
                    async onSuccess(data, variables, context) {
                        const { transaction, weapons } = data
                        const bytes = base58.decode(transaction)
                        const tx = VersionedTransaction.deserialize(bytes)
                        try {
                            const signed = await signTransaction(tx)
                            setStateMessage('sending transaction')
                            sendAndConfirm(
                                { transaction: base58.encode(signed.serialize()) },
                                {
                                    onSuccess: () => {
                                        setContent(weapons)
                                        setTimeout(() => dispatch({ type: 'OPEN' }), 1000)
                                    },
                                    onError: () => dispatch({ type: 'LOCK' }),
                                }
                            )
                        } catch (e) {
                            dispatch({ type: 'LOCK' })
                        }
                    },
                    onError(e) {
                        dispatch({ type: 'LOCK' })
                    },
                }
            )
        }
        if (_lootbox.state === 'UNLOCKED') {
            dispatch({ type: 'OPEN' })
        }
    }, [_lootbox.state])

    const buttonText = (state) => {
        if (!rightWallet) return 'SELECT WALLET'
        switch (state) {
            case 'LOCKED':
                return 'OPEN'
            case 'UNLOCKING':
                return 'OPENING'
            case 'UNLOCKED':
                return 'OPEN'
            case 'OPENING':
                return 'OPENING'
            case 'PROCESSING':
                return 'OPENING'
            case 'REMOVING':
                return 'OPENED'
        }
    }

    const buttonHandler = () => {
        rightWallet ? dispatch({ type: 'UNLOCK' }) : setWalletSelecting(true)
    }

    return (
        <div
            className='flex flex-col w-60 transition-opacity duration-1000'
            style={{
                opacity: _lootbox.state === 'REMOVING' ? 0 : 1,
            }}
            onTransitionEnd={(e) => {
                if (e.target === e.currentTarget && _lootbox.state === 'REMOVING') dispatch({ type: 'REMOVE' })
            }}
        >
            {walletSelecting && (
                <WalletSelectorModal lootbox={_lootbox} dispatch={dispatch} setWalletSelecting={setWalletSelecting} />
            )}

            <div className='relative'>
                <LootboxImage />
                <small
                    title={lootbox.owner}
                    className='absolute bottom-0 left-1/2 -translate-x-1/2 ml-2 w-full flex flex-row items-center gap-2 opacity-50'
                >
                    <FontAwesomeIcon icon={faWallet} className='' />
                    <div className='flex-1 text-center pr-6'>
                        {lootbox.owner.slice(0, 6)}...{lootbox.owner.slice(-6)}
                    </div>
                </small>
            </div>
            <div
                className='pt-3 pb-2 text-center font-black uppercase'
                style={{ backgroundImage: 'linear-gradient(277deg, #000 -24%, #262626 116.26%)' }}
            >
                {lootbox.name}
            </div>

            <button
                disabled={!['LOCKED', 'UNLOCKED'].includes(_lootbox.state) || walletSelecting}
                onClick={buttonHandler}
                className={twMerge(
                    'relative flex flex-row items-center justify-center gap-2  font-black p-2 hover:enabled:outline mt-2 uppercase',
                    ['UNLOCKING', 'OPENING', 'PROCESSING'].includes(_lootbox.state)
                        ? 'bg-[linear-gradient(269deg,#004F2D_-10.99%,#00FF90_130.96%)]'
                        : 'bg-br1-gradient'
                )}
            >
                {buttonText(_lootbox.state)}
                {(['UNLOCKING', 'OPENING', 'PROCESSING'].includes(_lootbox.state) ||
                    (walletSelecting && !rightWallet)) && <FontAwesomeIcon icon={faSpinner} spin className='' />}
                <small className='absolute bottom-0 text-[0.6rem] text-white/50'>{stateMessage}</small>
            </button>

            <div
                className={twMerge(
                    'h-screen w-screen inset-0 z-50 bg-black/50',
                    ['OPENING', 'PROCESSING'].includes(_lootbox.state) ? 'fixed' : 'hidden'
                )}
            >
                <div
                    style={{
                        opacity: _lootbox.state === 'PROCESSING' ? 1 : 0,
                    }}
                >
                    <LootboxContent
                        lootbox={_lootbox}
                        content={content}
                        onClose={() => dispatch({ type: 'START_REMOVE' })}
                    />
                </div>
                {_lootbox.state === 'OPENING' && <LootboxOpenVideo onEnd={() => dispatch({ type: 'PROCESS' })} />}
            </div>
        </div>
    )
}
