export const useStakeEpoch = () => {
    const epoch_id = 3
    const begin = new Date('2024-08-01T00:00:00Z')
    const end = new Date('2024-09-30T00:00:00Z')
    const begin_timestamp = Math.floor(begin.getTime() / 1000)
    const end_timestamp = Math.floor(end.getTime() / 1000)
    const epoch_duration = end_timestamp - begin_timestamp
    const active = Date.now() >= begin.getTime() && Date.now() < end.getTime()
    const is_started = Date.now() >= begin.getTime()
    const is_ended = Date.now() >= end.getTime()

    return {
        active,
        epoch_id,
        begin,
        end,
        begin_timestamp,
        end_timestamp,
        epoch_duration,
        is_started,
        is_ended,
    }
}
