import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useInput } from '~/hooks/useInput'
import { MinLengthValidator, RequiredValidator, SameValueValidator } from '~/utils/validators'
import { useSignup } from '../api'

export const SignupForm = () => {
    const { t } = useTranslation()
    const { mutate, error, isLoading, reset } = useSignup()

    const username = useInput('', { validators: [RequiredValidator(), MinLengthValidator(3)] })
    const email = useInput('', { validators: [RequiredValidator()] })
    const password1 = useInput('', { validators: [RequiredValidator(), MinLengthValidator(8)] })
    const password2 = useInput('', {
        validators: [
            RequiredValidator(),
            MinLengthValidator(8),
            SameValueValidator(password1.value, 'Passwords do not match'),
        ],
    })

    const submit = (e) => {
        e.preventDefault()
        reset()
        const valid = [username, email, password1, password2].map((f) => f.validate()).every((v) => v)
        if (!valid) return
        mutate(
            { username: username.value, email: email.value, password1: password1.value, password2: password2.value },
            {
                onError: (e) => {
                    username.setErrors(e?.username?.map((v) => v?.message))
                    email.setErrors(e?.email?.map((v) => v?.message))
                    password1.setErrors(e?.password1?.map((v) => v?.message))
                    password2.setErrors(e?.password2?.map((v) => v?.message))
                },
            }
        )
    }

    return (
        <form noValidate className='flex flex-col gap-1 w-full' onSubmit={submit}>
            <input
                value={username.value}
                onChange={username.onChange}
                type='text'
                name='username'
                placeholder={t('auth.username')}
                className='login-form'
                maxLength={16}
            />
            <label className='login-form error'>{username.error}</label>

            <input
                value={email.value}
                onChange={email.onChange}
                type='text'
                name='email'
                placeholder='EMAIL'
                className='login-form'
            />
            <label className='login-form error'>{email.error}</label>

            <input
                value={password1.value}
                onChange={password1.onChange}
                type='password'
                name='password'
                placeholder={t('auth.password')}
                className='login-form'
                maxLength={64}
            />
            <label className='login-form error'>{password1.error}</label>

            <input
                value={password2.value}
                onChange={password2.onChange}
                type='password'
                name='password'
                placeholder={t('auth.confirm_password')}
                className='login-form'
                maxLength={64}
            />
            <label className='login-form error'>{password2.error}</label>

            <label className='login-form error flex flex-col items-center'>
                {error?.__all__?.map((v) => (
                    <span>{v?.message}</span>
                ))}
            </label>

            <button
                disabled={isLoading}
                className='flex flex-row gap-2 items-center justify-center p-4 mt-8 mx-auto hover:outline w-full md:w-1/2'
                style={{ background: 'linear-gradient(142deg,#03CEFF 0%,#C91FFF 100%)' }}
            >
                <span>{t('common.enter')}</span>
                {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
        </form>
    )
}
